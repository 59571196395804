.dropDownContainer {
	padding: 8px 0;
	display: flex;
	flex-direction: column;
}
.dropDownContainer__optionsExtender {
	height: 48px !important;
	font-size: var(--font-size-md) !important;
	text-transform: none;
	font-weight: normal;
	text-align: left;
	padding: 0px !important;
}
.option__text {
	padding-left: 16px;
	display: inline-block;
	width: 160px;
}
.option__control {
	display: inline-block;
	text-align: center;
	margin-right: 6px;
	width: 60px;
}
