.helperText {
	display: block;
	padding: 8px 16px;
}
.dialogContent {
	padding: 0px;
}
.searchContainer__textfield {
	width: 300px;
	margin: 16px var(--spacing-s);
}
.mainContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-height: calc(70vh - 200px);
	overflow: auto;
}
.expandableCategoryRow {
	width: 100%;
}
.emptyState {
	composes: font-lg-regular from global;
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.emptyIcon {
	margin-right: 4px;
}
