.dialogHeader {
	display: flex;
	padding: 16px;
}
.dialogHeader__title {
	composes: font-md-medium from global;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}
.dialogHeader__icon {
	font-size: var(--font-size-xl);
	margin: 4px 10px 0 0;
}
.dialogHeader__closeBtn--larger {
	font-size: var(--font-size-xl);
}
