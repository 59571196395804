.minDrawerOpen {
	z-index: 2;
	height: 100%;
	width: 50px;
	transition: width 2.25s cubic-bezier(0.4, 0, 0.6, 1);
}
.minDrawerClose {
	z-index: 2;
	height: 100%;
	transition: width 1.95s cubic-bezier(0.4, 0, 0.6, 1);
	overflow-y: auto;
	overflow-x: hidden;
	width: 25px !important;
}
@media screen and (width >= 600px) {
	.minDrawerClose {
		width: 81px;
	}
}
.minDrawer {
	width: 50px;
	flex-shrink: 0;
	white-space: nowrap;
	z-index: 2;
	height: 100%;
	float: left;
}
