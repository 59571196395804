.rightPanel__contentContainer {
	height: 100%;
	overflow: auto;
}
.rightPanel__contentContainer [data-has-floating-panel="true"] {
	width: calc(100% - 66px) !important;
}
.rightPanel__contentContainer[data-has-floating-panel="true"] > div {
	width: calc(100% - 46px);
}
.rightPanel__panels {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 49px);
	height: 100%;
	z-index: 1;
}
