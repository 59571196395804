.StyledAccordionDetails__root {
	padding: 0 !important;
}
.container {
	display: flex;
	flex-direction: column;
}
.empty {
	display: flex;
}
.empty > span {
	margin: 50% auto;
	text-transform: uppercase;
}
.details {
	display: flex;
	flex-direction: column;
	margin: 16px 0;
	align-items: center;
	justify-items: center;
}
.details > span:first-of-type {
	font-weight: bold;
}
.details > span:nth-of-type(2) {
	font-style: italic;
}
.emptyDetails {
	font-style: italic;
	padding-left: var(--spacing-s);
}
