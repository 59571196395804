.relatedNorms__headerCellBtn {
	width: 80px;
}
.norms__name {
	display: block;
	max-width: 276px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
