.searchContainer__textfield {
	width: 300px;
	margin: 16px 0;
}
.userRow {
	width: 100%;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid var(--shades-grey-300);
	padding: 16px 0;
}
.loaderContainer {
	width: 100%;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}
