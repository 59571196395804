.container {
	background-color: var(--shades-grey-100);
	height: 100%;
}
.filters__container {
	width: calc(100% - 8px);
	margin: 4px 4px 0 4px;
}
.filters__container > div:first-of-type {
	flex-wrap: wrap;
}
.filters__container > div:first-of-type > div:first-of-type {
	flex-basis: 100%;
}
.filters__container > div > :nth-child(2) {
	flex: 0 0 auto;
	margin: 4px 4px 0 4px;
}
.filters__search {
	flex: auto;
}
.filters__search > div {
	width: 100%;
}
.filters__search > label {
	width: calc(100% - 48px);
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
}
.comment__container {
	height: calc(100% - 166px);
	overflow: auto;
}
.emptyStateContainer {
	width: 100%;
	height: 100%;
	gap: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.loaderContainer {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.commentPagination {
	display: flex;
	border-top: 1px solid var(--color-light-grey-4);
	width: 100%;
	background-color: var(--color-white);
}
.multiSelect {
	max-height: 60vh;
	overflow-y: auto;
}
.tabs {
	top: -1px;
	padding: 0px 8px;
	width: calc(100% - 8px);
	box-shadow: none;
	background-color: var(--shades-grey-100);
	margin: -8px 0px -8px 4px;
}
.tabs > div {
	padding: 0;
}
.tabs span {
	position: absolute;
	top: 35px;
}
.tabs > * {
	background-color: var(--shades-grey-100);
	padding-left: 8px;
}
.tabs button {
	font-size: 12px;
	font-weight: 500;
}
