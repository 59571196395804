.circle {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: var(--color-black);
}
.circle[data-color="red"] {
	background-color: var(--color-red) !important;
}
.circle[data-color="orange"] {
	background-color: var(--color-orange) !important;
}
.circle[data-color="green"] {
	background-color: var(--color-green) !important;
}
