.header {
	composes: font-sm-medium from global !important;
	display: block;
	margin: var(--spacing-m) 0;
}
.commentSection {
	composes: font-sm-regular from global !important;
}
.commentSection > div {
	margin-bottom: var(--spacing-xs);
}
.commentSection > div > div {
	flex: 1;
}
