.container {
	margin: var(--spacing-2xs) var(--spacing-xs);
	padding: var(--spacing-xs);
}
.container__line {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
}
.container__line > * {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.container__line:first-child {
	margin-bottom: var(--spacing-2xs);
}
.container__line > *:first-child {
	width: 100%;
}
.line__leftItems {
	display: flex;
	justify-content: flex-start;
}
.username {
	composes: font-xs-medium from global;
	margin-right: var(--spacing-2xs);
}
.text {
	composes: font-xs-regular from global;
	text-transform: lowercase;
}
.grayed {
	display: contents;
	color: var(--color-light-grey-1);
}
.date {
	white-space: nowrap;
	composes: font-xs-regular from global;
}
.questionLine {
	overflow-wrap: break-word;
	margin-bottom: var(--spacing-xs);
	align-self: center;
	composes: font-xs-regular from global;
}
.line__rightItems {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	white-space: nowrap;
	color: var(--color-light-grey-1);
}
