.container {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xs);
}
.filter,
.filter > div {
	margin: 0;
}
.btnContainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
