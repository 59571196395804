.row {
	height: 32px;
	padding: 6px 16px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.row[data-first="true"] {
	height: 40px;
	padding: 10px 16px;
}
.row[data-disabled="true"] {
	color: var(--color-light-grey-3);
}
.dialog > * > *,
.dialog > * > * > * {
	overflow-y: visible;
}
.dialog__form {
	width: 100%;
}
.dialog__form > *:first-child {
	margin-top: 6px;
}
.dialog__form > *:not(:first-child) {
	margin-top: 16px;
}
.dialog__nameTextfield {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.dialog__select {
	width: 100%;
}
.dialog__warning {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.dialog__warning > * {
	color: var(--color-red);
}
.dialog__warning > *:not(:last-child) {
	margin-right: 16px;
}
.dialog__warning--small {
	font-size: var(--font-size-sm);
}
.dialog__warning--large {
	font-size: var(--font-size-4xl) !important;
}
.dialog__categorySelectionInput {
	cursor: pointer !important;
}
.automationForm__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: var(--spacing-s) 0;
}
.automationForm__header > span {
	font-weight: bold;
	font-size: var(--font-size-sm);
}

.dialog__nameTextfield > div > label[data-shrink="true"] {
	top: 0px;
}
.nameContainer[data-is-focused="true"] > span > div > label {
	top: -11px !important;
	transition: none;
}
.dialog__descTextfield {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.dialog__descTextfield > div > label[data-shrink="true"] {
	top: -10px;
}
