.multiInput_textField > p {
	margin-bottom: var(--spacing-2xs);
}
.multiInput__input {
	height: 48px;
	background-color: var(--color-white);
}
.multiInput__inputLabel {
	transform: translate(14px, 18px) scale(1);
}
.multiInput__container {
	display: flex;
	width: 100%;
}
.multiInput__controlContainer {
	display: flex;
	flex-direction: column;
}
.control__btn {
	height: 24px !important;
	width: 24px !important;
}
.control__icon {
	height: 24px !important;
	width: 24px !important;
}
