.leftPanel {
	width: 25%;
	border-right: 1px solid var(--color-light-grey-3);
	overflow-y: auto;
	overflow-x: hidden;
}

.rightPanel {
	width: 75%;
	border-right: 1px solid var(--color-light-grey-3);
	border-left: 1px solid var(--color-light-grey-3);
	overflow-y: auto;
	overflow-x: hidden;
}
.createContainer {
	height: var(--spacing-2xl);
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: var(--spacing-xs);
}
.list {
	height: calc(100% - 128px);
	overflow: auto;
}
