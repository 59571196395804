.container {
	cursor: pointer;
	margin: 8px 0;
	padding: 16px;
	display: flex;
	flex-direction: column;
	height: 120px;
	justify-content: space-around;
	background-color: var(--color-white);
	border: 1px solid var(--color-light-grey-4);
	border-radius: 4px;
}
.container:hover {
	border: 1px solid var(--color-light-grey-3);
}
.not_read {
	border: 1px solid var(--color-light-grey-4);
	border-right: 4px solid var(--color-blue);
	background-color: var(--tints-white);
}
.not_read:hover {
	border: 1px solid var(--color-light-grey-3);
	border-right: 4px solid var(--color-blue);
	background-color: var(--tints-white);
}
.container__line {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	position: relative;
}
.container__line > * {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.container__line:first-child {
	margin-bottom: 8px;
}
.container__line:first-child > *:first-child {
	display: flex;
	flex-direction: row;
}
.container__line > *:first-child {
	width: 100%;
}
.line__leftItems {
	display: flex;
	justify-content: flex-start;
}
.comment {
	height: 48px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.comment > p {
	margin-bottom: 0;
}
.mention {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	align-self: center;
	composes: font-sm-regular from global;
}
.mention:first-line {
	width: 10px;
}
.name {
	composes: font-sm-bold from global;
	text-transform: capitalize;
	margin-right: 8px;
}
.grayed {
	display: contents;
	color: var(--shades-grey-400);
}
.spaced {
	margin-right: 8px;
}
.date {
	white-space: nowrap;
	composes: font-xs-regular from global;
}
.status_button {
	cursor: default;
	margin-right: 8px;
}
.status_button > button {
	height: 27px !important;
	width: 27px !important;
}
.status_button_YES > button {
	background-color: var(--color-green-20);
}
.status_button_NO > button {
	background-color: var(--color-red-20);
}
.status_button_PENDING > button {
	background-color: var(--color-orange-20);
}
.status_button_NOT_ME > button {
	background-color: var(--shadow-1);
}
.status_button_icon {
	font-size: 20px !important;
}
.read_button:hover {
	background-color: var(--color-light-grey-4);
}
.read_button_icon {
	font-size: 20px !important;
}
.documentName {
	composes: font-xs-regular from global;
	margin-top: -8px;
}
.documentName > span > span > *,
.documentName > span > * {
	color: var(--shades-grey-400);
	font-size: var(--font-size-xs);
}
.icon {
	font-size: var(--font-size-sm) !important;
}
.showMoreButton {
	color: var(--color-blue);
	margin: 0 16px;
}
.showMoreButton:hover {
	color: var(--color-dark-blue);
}
.showMoreContainer {
	position: absolute;
	right: 0;
	top: 30px;
	background-color: var(--color-white);
	padding: 0;
	font-size: var(--font-size-sm);
	cursor: pointer;
}
.status_icon button {
	cursor: default !important;
}
