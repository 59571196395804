.mainDisplay {
	width: 100%;
	display: flex;
	height: calc(100% - 90px);
	overflow: hidden;
}
.mainDisplay[data-caution-banner-disabled="false"] {
	height: calc(100% - 49px);
}
.sideDrawer__main {
	width: 25%;
	min-width: 15%;
	max-width: 30%;
	margin: 0 !important;
	padding: 0 var(--spacing-2xs) 0 var(--spacing-xs);
	overflow: auto;
	flex: 1;
}
.resizer {
	border-right: 1px solid var(--color-light-grey-3);
	width: 4px;
}
.siderDrawer__content {
	display: flex;
	flex-direction: column;
	background-color: var(--shades-grey-50);
	margin-left: 0px !important;
	width: 85%;
	min-width: 70%;
}

.betaText {
	color: var(--color-blue);
}
