.viewTab {
	text-transform: none;
	min-height: 48px;
	height: 48px;
	min-width: fit-content;
	font-size: var(--font-size-sm);
	padding-left: 0;
	padding-right: 0;
	margin-right: 12px;
}
.viewTab__wrapper {
	display: block;
}
.viewTab__icon {
	font-size: var(--font-size-sm);
}
