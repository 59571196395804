.dialog__content {
	display: flex;
	flex-direction: column;
	height: auto;
}
.dialog__content > * {
	margin: 8px 0;
}
.link-button {
	margin-left: 16px;
}
