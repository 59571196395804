.gridContainer {
	overflow-x: auto;
	overflow-y: auto;
}
.row__corner {
	width: 140%;
}
.row--first {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 2;
}
.corner__text {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-align: center;
	composes: font-sm-regular from global;
	color: var(--color-dark-grey-1);
}
.row__corner--diagonal {
	transform: rotate(-45deg);
	display: flex;
	align-items: center;
	justify-content: center;
}
.row__xNameContainer {
	width: 100%;
	padding: 8px;
	display: flex;
	align-items: center;
	overflow: hidden;
	justify-content: center;
	background-color: var(--color-white);
}
.row__xNameContainer--text {
	transform: rotate(-90deg);
	font-weight: bold;
	text-transform: capitalize;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-word;
	padding: 8px;
	line-height: 2;
}
.row__yName {
	min-width: 100px;
	font-weight: bold;
	text-transform: capitalize;
	padding: 8px;
	align-items: center;
	display: flex;
	justify-content: flex-end;
	background-color: var(--color-white);
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	z-index: 1;
}
.row__yName--text {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-word;
}
.row__cornerContainer {
	background-color: var(--color-light-grey-4);
	left: 0;
	z-index: 3;
	position: -webkit-sticky;
	position: sticky;
}
.row__cell {
	width: 100%;
	background-color: var(--color-white);
}
.row > * {
	border-bottom: solid 2px var(--color-light-grey-3);
	border-right: solid 2px var(--color-light-grey-3);
}
.row:first-child > *:nth-child(n + 2) {
	border-top: solid 2px var(--color-light-grey-3);
}
.row:last-child > * {
	border-bottom: solid 2px var(--color-light-grey-3);
}
.row > *:last-child {
	border-right: solid 2px var(--color-light-grey-3);
}
.row:nth-child(n + 2) > *:first-child {
	border-left: solid 2px var(--color-light-grey-3);
	text-align: start;
}
.row {
	display: flex;
	width: -moz-fit-content;
	width: fit-content;
}
