.normsCenter {
	height: 100%;
	overflow: auto;
	position: relative;
}

.normsCenter__panel {
	display: flex;
	flex-direction: column;
	flex: 1 1;
}
