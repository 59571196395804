.emptyState {
	width: 100%;
	margin-top: 0px;
}
.sidePanel {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: var(--spacing-xs);
	background-color: var(--shades-grey-100);
	padding: 8px;
	height: 90%;
	overflow: auto;
}
