.title {
	max-width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
	overflow: hidden;
}
.label__text[data-is-deliverable="true"] {
	width: calc(100% - 140px);
}
.label__text {
	width: calc(100% - 40px);
}
.label__chip {
	composes: font-sm-regular from global;
	margin-left: 8px;
	padding: 0 8px;
	background-color: var(--color-blue);
	color: var(--color-white);
}
