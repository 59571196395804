.historyRow__container {
	composes: font-xs-regular from global;
	width: 100%;
	border: 1px solid var(--color-light-grey-3);
	background-color: var(--color-white);
	border-radius: 4px;
	padding: 16px;
	margin-bottom: 8px;
}
.historyRow__header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}
.historyRow__identifierContainer {
	display: flex;
	white-space: nowrap;
}
.historyRow__identifierText--companyName {
	text-transform: none;
	font-weight: bold;
}
.historyRow__identifierIcon {
	margin-right: 8px;
}
.historyRow__identifierText {
	text-transform: lowercase;
	white-space: break-spaces;
}
.historyRow__identifier {
	display: inline-block;
}
.historyRow__timePassed {
	color: var(--color-dark-grey-1);
	white-space: nowrap;
}
.historyRow__iconContent {
	display: flex;
	align-items: center;
	justify-content: center;
}
.historyRow__multiline {
	overflow-wrap: break-word;
	word-break: break-word;
	white-space: normal;
}
.historyRow__statusIconText {
	margin-left: 8px;
}
.historyRow__commentContainer {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 3;
	max-height: 44px;
}
.historyRow__commentLine {
	margin: 0px;
}
.historyRow__showMoreBtn {
	padding: 0px !important;
}
.historyRow__newOldIcon {
	margin: 0 8px;
}
.historyRow__newOldIcon--margin-top {
	margin-top: 2px;
}
.historyRow__contentTypeIcon {
	margin-right: 8px;
}
.historyRow__text--bold {
	composes: font-xs-medium from global;
}
