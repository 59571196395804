.container {
	display: inline-block;
	margin: 5px;
}
.progressCircle__bg {
	fill: none;
}

.progressCircle__progress {
	fill: none;
	transform-origin: center;
	transform: rotate(-90deg);
}

.progessCircle__text {
	font-size: var(--font-size-md);
	text-anchor: middle;
}
.progessCircle__text--grey {
	fill: var(--color-light-grey-1);
}
.progessCircle__text[data-size="small"] {
	font-size: var(--font-size-xs);
}
.progessCircle__text[data-size="medium"] {
	font-size: var(--font-size-sm);
}
.progessCircle__text[data-size="large"] {
	font-size: var(--font-size-xl);
}
.progressCircle__color[data-color="green"] {
	color: var(--color-green);
}
.progressCircle__color[data-color="orange"] {
	color: var(--color-orange);
}
.progressCircle__color[data-color="red"] {
	color: var(--color-red);
}
.progressCircle__color[data-text-color="inside"] {
	color: var(--color-white);
}
.progressCircle__color[data-text-color="outside"] {
	color: var(--color-dark-grey-1);
}
