.textfield-container__input {
	flex: 1;
	min-width: 250px;
}
.textfield-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 8px 0 8px 0;
	border-radius: 4px;
	padding: 1px;
	border: 1px solid rgba(0, 0, 0, 0.23);
}
.textfield-container[data-focus="true"] {
	border: 2px solid var(--color-blue);
	padding: 0;
}
.buttonsInput-container > * {
	margin: 4px;
}
.textfield-root > * > fieldset {
	border: none;
}
.textfield-root > *:first-child {
	padding-left: 8px;
}
.textfield-container__helperText {
	text-align: right;
	margin: -4px 4px 0 0 !important;
}
