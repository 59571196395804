.header {
	display: flex;
	width: 100%;
	margin: var(--spacing-xs);
	padding: var(--spacing-xs);
}
.header > * {
	margin-right: var(--spacing-xs);
}
.header > div:nth-of-type(2) {
	width: 200px;
}
.searchContainer__textfield {
	min-width: 300px;
}
.searchContainer__textfield > div {
	height: 40px !important;
}
.searchContainer__textfield > label {
	line-height: 24px !important;
}
