.dialog {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.dialog > * {
	margin: 8px 0;
}
.select {
	width: max-content;
}
