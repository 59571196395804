.toggleBtn__btn {
	width: 100%;
}
.toggleBtn__btn[aria-pressed="true"] {
	background-color: var(--color-light-grey-4);
}

.toggleBtn__icon {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}
.toggleBtn__icon > svg {
	margin-right: var(--spacing-xs);
}
