.selectionRectangle {
	position: absolute;
	border: 2px solid var(--tints-blue-30);
	background-color: var(--tints-blue-10-30);
	z-index: 20;
}

.actionBar {
	position: absolute;
	border: 1px solid var(--color-light-grey-3);
	background-color: var(--color-white);
	border-radius: 4px;
	width: 90px;
	height: 40px;
	z-index: 21;
	padding: 4px;
	display: flex;
}

.actionBar[data-group="false"] {
	width: 64px !important;
}
