.row {
	height: 45px;
	width: 100%;
	background-color: var(--color-white);
	flex-direction: row;
	justify-content: space-between;
}
.docTitle {
	display: flex;
}
.docIcon {
	padding-right: 10px;
}
.docDetails {
	display: flex;
	flex-direction: column;
}
.docDetails__name {
	composes: font-sm-bold from global;
	max-width: 180px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.docDetails__canceled {
	max-width: 180px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	color: var(--shades-grey-v2-40);
}
.docDetails__info {
	composes: font-xs-regular from global;
	color: var(--shades-grey-v2-40);
}
.docDetails__progress {
	font-size: var(--font-size-xs);
	color: var(--shades-grey-v2-40);
}
.row__loader {
	text-align: right;
}
