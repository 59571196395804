.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: hidden;
}
.container__tab {
	display: flex;
	flex: 1 1;
	overflow: auto;
}
.emptyState {
	height: inherit;
	display: flex;
	text-transform: uppercase;
}
.emptyState__text {
	margin: auto;
}

.root {
	border-bottom: 1px solid var(--color-blue);
}
.indicator {
	background-color: var(--color-blue);
}
