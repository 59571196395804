.container {
	background-color: var(--shades-grey-100);
	padding: 0px 4px;
	height: calc(100% + 16px);
	overflow: hidden;
}
.container[data-disable-diting="true"] {
	overflow: auto !important;
}
.tabs {
	top: -1px;
	padding: 0px 8px;
	width: calc(100% - 8px);
	box-shadow: none;
	background-color: var(--shades-grey-100);
	margin: -8px 0px -8px 4px;
}
.tabs span {
	position: absolute;
	top: 35px;
}
.tabs > * {
	background-color: var(--shades-grey-100);
	padding-left: 8px;
}
.tabs button {
	font-size: 12px;
	font-weight: 500;
}
.infinityListContainer__showAll {
	height: fit-content;
}
.infinityListContainer__shortText {
	max-height: calc(100% - 260px);
	min-height: 180px;
}
.infinityListContainer__largeText {
	max-height: calc(100% - 290px);
	min-height: 180px;
}

.infinityListContainer--noPosition {
	max-height: calc(100% - 1px);
	min-height: 200px;
}
.scrollContainer {
	height: 100% !important;
	margin-top: -8px;
}
.listContainer {
	margin-bottom: 8px;
}
.viewTabPanel {
	margin-top: 8px;
	height: 100%;
}
.viewTabPanel[data-disable-editing="true"] {
	margin-top: 8px;
	height: calc(100% - 264px);
}
.rowContainer {
	height: auto;
}
.positionsCounter {
	margin: 4px 4px 8px 4px;
	width: calc(100% - 8px);
}
.main {
	margin: var(--spacing-2xs) !important;
}
.emptyState {
	margin: var(--spacing-xs) var(--spacing-2xs);
	box-shadow: none;
	border-radius: var(--spacing-2xs);
	padding: var(--spacing-s) var(--spacing-xs);
	background-color: var(--color-white);
	border: 1px solid var(--color-light-grey-4);
	composes: font-xs-regular from global;
}
