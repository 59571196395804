.actionRow__row {
	display: flex;
	align-items: center;
}
.actionRow__content {
	display: flex;
	align-items: flex-start;
}
.actionRow__name {
	composes: font-sm-regular from global;
	height: 32px;
	display: flex;
	align-items: center;
	margin-left: var(--spacing-xs);
}
.actionRow__name--bold {
	font-weight: 500;
}
.actionRow__count {
	margin-left: 48px;
	font-size: var(--font-size-xs);
	color: var(--color-light-grey-2);
}
.actionRow--leftPadding {
	padding-left: 32px;
}
.actionRow__checkContainer {
	display: inline-flex;
	height: 32px;
	justify-content: center;
}
.checkbox {
	padding: 0;
}
