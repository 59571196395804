.searchCardBox {
	display: flex;
	padding: 8px;
}
.searchCardBox > * {
	margin-right: 8px;
}
.searchCardBox__checkboxContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
.searchCardBox__contentContainer {
	text-align: left;
	width: 100%;
}
.contentContainer__header {
	text-align: left;
	margin-bottom: 16px;
}
.header__title {
	composes: font-md-bold from global;
}
.resultsContainer {
	composes: font-sm-regular from global;
	color: var(--shades-grey-400);
}
.descriptionContainer {
	display: flex;
}
.detailsButtonContainer {
	display: flex;
	align-items: center;
	justify-content: center;
}
