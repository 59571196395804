.clear-button__container {
	text-align: left;
	margin: 0 0 -4px 0 !important;
}
.button-group {
	margin: 4px;
	text-transform: none;
}
.button-group > * {
	text-transform: none;
}
.button-group * {
	min-width: 32px;
}
.button-group__delete-button {
	border-radius: 0px 4px 4px 0px !important;
	height: 32px !important;
	width: 32px !important;
	background-color: var(--color-primary-hover) !important;
}
.button-group__btn:hover {
	background-color: var(--color-blue) !important;
}
