.superDetailBlock__block {
	position: absolute;
	width: 100%;
}
.superDetailBlock__block[data-is-requirement="false"] {
	z-index: 100;
}
.superDetailBlock__block[data-is-requirement="true"] {
	z-index: 101;
}
.superDetailBlock__block[data-is-hovered="true"] {
	z-index: 102;
}
