.customDialog {
	display: flex;
	align-items: center;
}
.customDialog__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
}
.customDialog__root {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.divider {
	position: absolute;
	height: 80% !important;
	left: 12.5%;
}
.customDialog__titleForm {
	padding: 16px;
}
.customDialog__titleInfo {
	padding: 16px 16px 8px;
}
.customDialog__dividerForm {
	padding-top: 16px;
}
.customDialog__closeCross {
	position: absolute;
	top: 8px;
	right: 4px;
}
.customDialog__subTitle {
	composes: font-sm-regular from global;
	color: var(--color-black);
	margin-bottom: 8px;
}
.customDialog__container {
	flex: 1;
	padding: 0 16px;
	overflow-y: hidden;
}
.customDialog__content {
	composes: font-sm-regular from global;
}
.checkbox {
	composes: font-sm-regular from global;
	display: flex !important;
	align-items: center;
	position: absolute;
	left: 8px;
}
.customDialog__actions > * {
	flex: 1;
	margin: 0 8px;
}
