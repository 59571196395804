.emptyState {
	margin-top: 0 !important;
}
.emptyState > main {
	font-size: var(--font-size-sm);
}
.emptyState a {
	display: block;
	margin-top: var(--spacing-xs);
}
