.menu-item {
	font-size: var(--font-size-md);
}
.ListItem {
	margin-left: 0.5rem;
	padding-right: 1rem;
	width: 95%;
}

.ListItem.selected {
	color: var(--color-blue);
	border-left: 2px solid var(--color-light-grey-3);
}
.ListItem.selected:hover {
	color: var(--color-blue);
	border-left: 2px solid var(--color-light-grey-3);
}
.ListItem:hover {
	color: var(--color-blue);
	border-left: 2px solid var(--color-light-grey-3);
}
