.toc {
	position: relative;
	left: 20%;
	margin-top: 50px;
	border-left: 1px solid var(--color-light-grey-3);
	margin-bottom: 50px;
	align-items: center;
	max-width: calc(100% - 40%);
}
.container {
	position: relative;
	text-align: left;
	align-content: normal;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow-y: auto;
	height: 100%;
}
.toc__button {
	height: fit-content !important;
	text-align: left;
	border-radius: 0px !important;
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: pre-wrap;
}
.fabLeft {
	position: fixed;
	top: 50%;
	left: 32%;
	z-index: 9999;
}
.fabLeft--maxMode {
	position: fixed;
	top: 50%;
	left: 5%;
	z-index: 9999;
}
.fabRight {
	position: fixed;
	right: 5%;
	top: 50%;
	z-index: 9999;
}
