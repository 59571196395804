.container {
	width: 520px;
	height: 314px;
	overflow: auto;
	margin-top: -16px;
}
.stickyHeader {
	position: -webkit-sticky;
	position: sticky;
	background-color: var(--color-white);
	z-index: 1;
	top: 0;
}
.categoriesPopUp {
	max-width: 515px !important;
	max-height: 302px !important;
}
.innerFilterContainer {
	margin-left: -4px;
}
.noContent {
	composes: font-sm-regular from global;
	padding: 10px 18px;
	border-radius: 4px;
	background-color: var(--shades-blue-50);
	color: var(--shadow-6);
}
.noContent__infoIcon {
	margin-right: 10px;
}
