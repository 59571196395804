.container {
	background-color: var(--color-white);
	border-radius: 4px;
	border: 1px solid var(--color-light-grey-4);
	padding: 16px 8px 0 8px;
	margin-bottom: 8px;
}
.header {
	display: flex;
	align-items: center;
}
.header > div:first-of-type {
	margin-right: auto;
}
.footer {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
	align-items: center;
	align-self: stretch;
	margin-bottom: 8px;
}
.linkIcon {
	margin-right: var(--spacing-xs);
}
.linkType {
	margin-right: var(--spacing-2xs);
	composes: font-sm-medium from global;
}
.contentId {
	composes: font-sm-regular from global;
}
.title {
	composes: font-sm-medium from global !important;
}
.infoIcon {
	margin: 0 0 var(--spacing-2xs) var(--spacing-2xs);
}
.block,
.block > div:first-of-type {
	margin-bottom: var(--spacing-s);
}
.heriarchy {
	color: var(--color-light-grey-1) !important;
	composes: font-xs-regular from global !important;
	padding: var(--spacing-2xs) 0;
}
.text {
	padding: var(--spacing-2xs) 0;
	overflow-x: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	composes: font-xs-regular from global !important;
}
.elapsedTime {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-1);
}
