.main {
	margin-top: var(--spacing-s);
}
.title {
	font-size: var(--font-size-l);
	font-weight: bold;
}
.main > div {
	margin-top: var(--spacing-xs);
}
.row {
	display: flex;
	align-items: center;
	padding: var(--spacing-xs) 0;
}
.row > span:first-of-type {
	margin-right: auto;
}
