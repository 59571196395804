.dialogExportButtons {
	display: flex;
	justify-content: flex-end;
}
.dialogExportButtons > button {
	margin-left: var(--spacing-2xs);
	margin-right: var(--spacing-2xs);
}
.tabsHeader {
	box-shadow: none !important;
	margin-bottom: var(--spacing-s);
	margin-top: -16px;
}
.tabsHeader > * {
	padding: 0 !important;
}
