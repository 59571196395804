.container {
	display: flex;
	flex-direction: column;
	margin: var(--spacing-s);
}
.container > div {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: var(--spacing-s) auto;
	width: 80%;
}
.container > div > div {
	margin: var(--spacing-xs);
}
.container > div > div:last-of-type {
	margin-bottom: var(--spacing-m);
}
