.container {
	border-radius: 4px;
	border: 1px solid var(--color-light-grey-4);
	padding: 8px;
}
.container > div:first-of-type {
	display: flex;
	align-items: center;
	align-self: stretch;
	margin-bottom: 4px;
}
.rowId {
	margin-right: auto;
	composes: font-xs-medium from global;
}
.close {
	width: 20px !important;
	height: 20px !important;
}
.container > div:nth-of-type(2) {
	margin-bottom: 4px;
}
.heriarchy {
	color: var(--color-light-grey-1);
}
