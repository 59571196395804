.box__phase {
	composes: font-xs-bold from global;
	color: var(--color-light-grey-1);
	text-transform: uppercase;
	margin-right: 8px;
}
.box__name {
	composes: font-xs-regular from global;
	margin-right: 8px;
}
.box__category {
	composes: font-md-bold from global;
	margin-right: 8px;
}
.box__docName {
	composes: font-xs-regular from global;
	color: var(--color-dark-grey-2);
	text-transform: uppercase;
}
