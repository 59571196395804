.container {
	background-color: var(--shades-grey-100);
	padding: 0px 4px;
	height: calc(100% + 16px);
	overflow: auto;
}
.versioningTabView {
	padding: var(--spacing-m) var(--spacing-m);
	overflow-y: auto;
}
.subtitle {
	composes: font-sm-medium from global;
}
.infoId {
	composes: font-sm-regular from global;
	color: var(--color-light-grey-1);
	padding: var(--spacing-xs) var(--spacing-m);
}
.content {
	composes: font-md-regular from global;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: var(--spacing-xs) var(--spacing-m);
	margin: var(--spacing-s) 0;
	text-align: left;
	display: flex;
	flex-direction: row;
}
.documentVersion {
	composes: font-md-regular from global;
	color: var(--color-dark-grey-1);
	display: flex;
	flex-grow: 0.4;
	align-items: flex-start;
	padding-right: 16px;
}
.documentName {
	flex: 1;
	flex-direction: row;
}
.uploaded {
	composes: font-sm-regular from global;
	color: var(--color-light-grey-1);
}
.button {
	flex: 1;
	float: right;
	vertical-align: middle;
}
.button[data-has-button="true"] {
	padding-left: 16px;
}
.buttonApply {
	float: right;
	padding-top: 4px;
}
.separator {
	vertical-align: middle;
}
.separator[data-has-button="true"] {
	padding-left: 16px;
}
.separatorInput {
	padding-right: 16px;
	vertical-align: middle;
}
.informationId {
	flex: 1;
}
.informationInput {
	color: var(--color-dark-grey-1);
	width: 42%;
}
.input > input {
	margin-right: -70px;
	padding: 10px 12px;
}
.loading {
	width: fit-content;
	margin: 16px auto;
}
.main {
	margin: var(--spacing-s) var(--spacing-2xs) !important;
}
.emptyState {
	margin: var(--spacing-xs) var(--spacing-2xs);
	box-shadow: none;
	border-radius: var(--spacing-2xs);
	padding: var(--spacing-s) var(--spacing-xs);
	background-color: var(--color-white);
	border: 1px solid var(--color-light-grey-4);
	composes: font-xs-regular from global;
}
.noContent {
	composes: font-sm-regular from global;
	padding: var(--spacing-xs);
	margin: var(--spacing-xs);
	border-radius: 4px;
	background-color: var(--shades-blue-50);
	display: flex;
	margin-bottom: var(--spacing-xs);
}
.noContent__infoIcon {
	margin-right: var(--spacing-xs);
	padding-top: var(--spacing-2xs);
}
.noContent__desc {
	display: flex;
	flex-direction: column;
}
.noContent__btn {
	margin-top: var(--spacing-s);
}
.accordionSummary {
	cursor: default;
}
