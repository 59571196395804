.row__more {
	cursor: pointer;
	display: flex;
	composes: font-xs-regular from global !important;
	color: var(--color-blue);
	align-items: center;
	margin-left: var(--spacing-s);
}
.row__more > span {
	margin-right: var(--spacing-xs);
}
.popover_list {
	padding: var(--spacing-s) var(--spacing-s) 0;
}
.popover_list > div {
	margin-bottom: var(--spacing-xs);
}
