.button {
	font-weight: normal;
}
.button > svg {
	color: var(--color-light-grey-1);
	font-size: var(--font-size-2xs) !important;
}
.dropDown__container {
	padding: 0px;
}
.dropDown__item {
	padding: 0px 8px;
	border-bottom: 1px solid var(--color-light-grey-1);
}
.dropDown__item:last-child {
	border-bottom: none !important;
}
