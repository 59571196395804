.listContainer {
	width: 60%;
	height: 80%;
	background: var(--color-white);
	border: 1px solid var(--tints-blue-10);
	border-radius: 4px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	margin: 10px auto;
}
.filterWrapper {
	width: 100%;
	height: 10%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 0px 16px;
	gap: 16px;
}
.searchContainer {
	width: 30%;
}
.filterContainer {
	position: relative;
	left: 788px;
	top: 117px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px;
	gap: 4px;
	width: 67px;
	height: 24px;
}
.footer {
	width: 100%;
	height: 10%;
	border-top: 1px solid var(--shades-grey-v2-25);
}
.select {
	width: 100px;
}
