.progress {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0;
}
.progress[data-single-document="true"] {
	padding-left: 38px !important;
}
.progress[data-first-level="true"] {
	padding-left: 0px !important;
}
.progress[data-subLevels="true"] {
	padding: 8px 0;
}
.progress__first-line {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.first-line__left {
	font-size: var(--font-size-sm);
	color: var(--shades-grey-900);
	width: 80%;
	overflow-wrap: break-word;
	padding-right: 8px;
}
.first-line__left > span {
	margin-left: 8px;
}
.error {
	color: var(--shades-grey-400);
}
.first-line__right {
	display: flex;
	flex-direction: row;
	color: var(--shades-grey-800);
	align-items: center;
}
.progressbar__front {
	background-color: var(--color-green);
	height: 16px;
}
.progressbar__back {
	background-color: var(--shades-grey-500);
	height: 16px;
	width: 100%;
}
.page-number {
	white-space: nowrap;
	font-size: var(--font-size-xs);
	font-style: italic;
}
.close-icon {
	color: var(--shades-grey-900);
}
