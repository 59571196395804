.details__description {
	padding: 0 8px;
	margin: 24px 0;
	font-size: var(--font-size-sm);
}
.details__description--empty {
	font-style: italic;
}
.details__footer {
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	align-items: center;
	margin-top: 1.5rem;
}
