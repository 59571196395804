.display-table__chip {
	max-width: 300px;
}

.display-table__body {
	word-break: break-word;
}
.table-cell--bold {
	font-weight: bold;
}
.table-row:nth-of-type(2n - 1) {
	background-color: var(--color-light-grey-3);
}
