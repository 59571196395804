.emptyState__wrapper {
	margin-left: auto !important;
	margin-right: auto !important;
	margin-top: 130px;
	background-color: var(--color-white);
	width: 547px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.emptyState__header {
	font-size: var(--font-size-sm);
	font-weight: 500;
	text-align: center;
	margin-bottom: 8px;
}
.emptyState__body {
	font-size: var(--font-size-xs);
	font-weight: 400;
	overflow: auto;
	text-align: center;
}
.emptyState__body > a:hover {
	color: var(--color-blue);
}
.emptyState__footer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 16px;
	text-align: center;
}
.emptyState__footer > *:first-child {
	margin-right: 10px;
}
.emptyState__footer * {
	size: "sm" !important;
}
