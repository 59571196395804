.countersContainer {
	background-color: var(--color-white) !important;
	color: var(--color-blue);
	border: solid 1px var(--color-blue-50);
	padding: var(--spacing-2xs) var(--spacing-xs) var(--spacing-2xs) var(--spacing-xl);
	border-radius: var(--spacing-2xs);
	font-size: var(--font-size-sm);
	width: 100%;
	cursor: pointer;
}
.countersContainer:hover {
	background-color: var(--color-blue-10) !important;
}
.buttonHeightFit {
	height: fit-content !important;
	line-height: 18px;
}
