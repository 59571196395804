.container {
	color: var(--color-dark-grey-2);
	padding: var(--spacing-s) var(--spacing-l);
	background-color: var(--color-orange-20);
	z-index: 99;
}
.container > div:first-of-type {
	margin-top: 8px;
	display: flex;
	align-items: baseline;
}
.container > div > a {
	padding-left: 4px;
	color: var(--color-dark-grey-2);
	text-decoration: underline;
}
