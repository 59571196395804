.paper {
	padding: 20px;
	background-color: var(--color-blue);
	color: var(--color-white);
	text-align: center;
}
.displayName {
	display: inline-block;
}
.buttons {
	text-align: center;
}
.button {
	margin: 16px;
}
