.main-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 calc(3 * var(--view-padding-lateral));
	overflow: auto;
}
.main-container > * {
	margin: 16px 0;
}
.name-cell {
	width: 70%;
}
.cell {
	font-size: var(--font-size-sm);
}
.role-cell {
	font-size: var(--font-size-xl);
	color: var(--shades-grey-800);
}
.row > * {
	font-size: var(--font-size-sm);
	height: 48px;
	padding: 0 8px 0 0;
	border: none;
}
.list {
	margin: 0;
}
.header-row > * {
	height: 0;
	padding: 0;
	border: none;
}
