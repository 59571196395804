.table {
	position: sticky;
}
.stickyTabs {
	position: sticky;
	top: calc(var(--navbar-height));
}
.tabs__myReadfingList {
	height: calc(100% - 133px);
	overflow: hidden;
}
