.main {
	z-index: 110;
	border-radius: 0px;
	height: 250px;
	background-color: var(--color-white);
	position: absolute;
	border: 1px solid var(--color-light-grey-3);
	width: calc(100% - 48px);
	left: 48px;
}

.status__container {
	height: 25%;
	overflow-y: auto;
}

.status__item {
	display: inline-block;
	width: 40px;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 12px;
	padding: 3px;
	font-weight: bold;
	text-align: center;
}

.own-status {
	display: inline-block;
	width: auto;
	border: 2px solid var(--color-light-grey-3);
	border-radius: 2px;
	padding: 3px;
	font-weight: bold;
	text-align: center;
}

.edit-issue {
	float: right;
}

.partnerships {
	height: 85%;
	overflow-y: auto;
}

.companies {
	height: 75%;
	overflow-y: auto;
}

.companies__description {
	height: 15%;
	background-color: var(--shades-grey-600);
	color: var(--color-white);
	display: flex;
}
.description__title {
	width: 100%;
	padding: 0 4px;
	font-size: var(--font-size-sm);
	align-self: center;
}
