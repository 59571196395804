.documents {
	height: 100%;
	width: 65%;
	display: flex;
	flex-direction: column;
}
.documents__header {
	background-color: var(--shades-grey-800);
	color: var(--color-white);
	height: 48px;
	min-height: 48px;
	font-size: var(--font-size-md);
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
}
.documents__body {
	border: dashed 4px var(--shades-grey-500);
	border-top: none;
	overflow-y: auto;
	height: 100%;
	padding: 4px;
}
.documents__body[data-dragover="true"] {
	background-color: var(--tints-blue-10);
	border: 2px solid var(--tints-blue-50);
	border-radius: 4px;
}
.documents__list > * {
	margin: 8px 0;
}
.documents__footer {
	color: var(--color-black);
	height: 56px;
	min-height: 56px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.documents__footer > * {
	margin: 0 4px;
}

.documents__footer > *:first-child {
	margin-top: 8px;
	color: var(--color-light-grey-1);
}
.documents__footer > *:last-child {
	margin-left: auto;
	margin-right: 0;
}
.footer__text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.text__price {
	font-size: var(--font-size-xs);
}
.text__info {
	font-size: var(--font-size-xs);
}
.footer__icon {
	margin: 16px;
}
.body__icon {
	color: var(--color-blue);
	margin: 4px;
	font-size: var(--font-size-8xl) !important;
}
.body__icon[data-dragover="true"] {
	font-size: var(--font-size-10xl) !important;
}
.body__text {
	text-align: center;
	margin: 4px;
}
.documents__dragAndDrop {
	composes: font-md-regular from global;
	color: var(--color-dark-grey-4);
}
.document__helper {
	margin-top: 32px;
	width: 50%;
	composes: font-sm-regular from global;
	color: var(--color-dark-grey-1);
}
.body__button {
	composes: font-sm-medium from global;
	line-height: 1rem;
	background-color: var(--color-blue);
	padding: 8px 16px;
	border-radius: 4px;
	color: var(--color-white);
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
	cursor: pointer;
	white-space: nowrap;
	height: 32px;
	width: fit-content;
}
.file-upload {
	display: none;
}
.footer__left {
	display: flex;
	flex-direction: row;
}
.upload-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.dragDropInfo {
	bottom: 146px;
}
.tranlate_list {
	display: flex;
	justify-content: center;
}
