.secondaryHeaderRow__name {
	display: inline-flex;
	align-items: center;
	background-color: var(--shades-grey-200);
	max-width: calc(100% - 40px);
	border-radius: var(--spacing-2xs);
	padding: var(--spacing-2xs) var(--spacing-xs);
	margin-left: var(--spacing-xs);
	margin-right: auto;
}
.secondaryHeaderRow__documentName {
	max-width: calc(100% - 40px);
	margin-left: var(--spacing-xs);
}
@media screen and (width <= 1150px) {
	.secondaryHeaderRow__name > div {
		display: none !important;
	}
}
