.list__content {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xs);
	height: 100%;
}
.radioGroup__container {
	gap: var(--spacing-xs);
}
.radioContainer {
	display: flex;
	align-items: flex-start;
}
.radio {
	height: 24px;
	width: 24px;
	margin-left: 12px;
	margin-right: 12px;
}
.list__rowHead {
	display: flex;
	align-items: flex-start;
	gap: var(--spacing-xs);
}
.list__checkbox {
	padding: 0;
	margin: 0;
}
.list__helperInformation {
	line-height: 0.5;
}
.list__rowLabel {
	composes: font-sm-regular from global;
	padding-right: var(--spacing-xs);
}
.list__rowLabel[data-icon-placement-left="true"] {
	padding-left: var(--spacing-xs);
}
.list__rowDescription {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-1);
	padding-top: 4px;
}
.list_itemContainer {
	cursor: pointer;
}
