.dialog__title {
	display: flex;
}

.dialog__title .dialog__title-text {
	width: 100%;
	align-self: center;
}

.dialog__content {
	display: flex;
	flex-direction: column;
}

.template__table {
	border: 1px solid var(--color-light-grey-3);
	background: var(--color-light-grey-3);
}

.template__cell {
	border-right: 1px solid;
	text-align: center;
}
