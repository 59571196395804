.sideDrawer__container {
	height: calc(100% - 87px);
	overflow: hidden;
	padding: 0;
}
.sideDrawer__content {
	margin-left: 0px;
}
.main {
	display: flex;
	flex-direction: column;
	flex: 0 1 auto;
	gap: var(--spacing-s);
	width: calc(100% - 32px);
	height: 100%;
	margin-left: 32px;
	padding: 0 var(--spacing-xs);
}
.main__search {
	align-content: space-between;
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-top: var(--spacing-s);
}
.search__autocomplete {
	width: 88%;
	display: flex;
	align-items: center;
	height: 40px;
}
.autoComplete > div > div {
	padding: 2px 6px !important;
}
.autoComplete > div > label {
	line-height: 1;
}
.main__location {
	align-content: space-between;
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.main__loader {
	padding: 32px;
	align-self: center;
}
.main__table {
	height: 67%;
}
