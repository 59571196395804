.expandIcon {
	width: 18px;
	margin-left: -18px;
}
.accordion__root {
	box-shadow: none !important;
	position: inherit;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	border-radius: 0 !important;
}
.row__title {
	display: flex;
	align-items: center;
}
.row__label {
	padding-left: 8px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.accordionSummary__content {
	margin: 0 !important;
	display: flex;
	justify-content: space-between;
	font-size: var(--font-size-sm);
	align-items: center;
	padding: 0;
	min-height: 0;
}
.accordionSummary__root {
	min-height: 32px !important;
	transition-duration: 150ms, 150ms !important;
}
.accordionDetails__root {
	flex-direction: column;
	padding: 0;
}
