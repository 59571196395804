.circularProgress {
	margin: 60px auto;
}

.container {
	position: relative;
	text-align: left;
	align-content: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.fabLeft {
	position: fixed;
	top: 50%;
	left: 25%;
	z-index: 9999;
}
.fabRight {
	position: fixed;
	right: 25%;
	top: 50%;
	z-index: 9999;
}
