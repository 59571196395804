.header {
	display: flex;
	align-items: center;
	color: var(--color-light-grey-1);
	padding: 0 8px;
}

.title {
	max-width: calc(100% - 88px);
	padding-left: 16px;
	padding-right: 12px;
	composes: font-xs-bold from global;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 3;
}
.header > div:last-of-type {
	display: flex;
}
.header > div:last-of-type > div {
	width: 16px;
	align-items: center;
}
.details {
	display: flex;
	justify-content: center;
	padding-right: 4px;
	width: 10%;
	min-width: 36px;
}
