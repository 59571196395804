.dropDown {
	composes: font-sm-regular from global;
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	grid-gap: var(--spacing-2xs);
	gap: var(--spacing-2xs);
	padding: 16px 0;
	background-color: var(--color-white);
}
.dropDown > * {
	width: 100%;
}
.item {
	composes: font-sm-regular from global;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	grid-gap: var(--spacing-2xs);
	gap: var(--spacing-2xs);
	color: var(--color-black);
}
.textCondition_color {
	color: var(--color-light-grey-1);
}
