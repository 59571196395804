.container {
	width: 100%;
	height: calc(100% - 16px);
	position: relative;
	padding: var(--spacing-2xs) 0;
	margin: var(--spacing-xs) 0;
	border-radius: 16px;
	background-color: var(--tints-white);
}
.searchContainer__textfield {
	width: 100%;
}
.superTableContainer {
	height: calc(100% - 36px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.tableContainer {
	width: 100%;
	max-height: calc(100vh - 241px);
	background-color: var(--color-white);
	overflow-y: auto;
	margin-bottom: auto;
}
.newProjectButtonContainer {
	height: 48px;
	display: flex;
	width: 100%;
}
.newProjectButtonContainer > div:first-of-type {
	padding-left: var(--spacing-s);
	margin-right: auto;
	max-width: 500px;
	width: auto;
}
.newProjectButtonContainer > div:last-of-type {
	padding-right: var(--spacing-s);
}
.newProjectButtonContainer__button {
	margin: 4px;
}
