.rowContainer {
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--shades-grey-300);
}
.accordion__root {
	box-shadow: none !important;
	position: inherit;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	border-radius: 0 !important;
}
.expandIcon {
	width: 18px;
	margin-left: -18px;
}
.checkBox {
	padding: 6px;
}
.categoryName {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.categoryName--greyed {
	color: var(--shades-grey-400);
}
.categoryName--bold {
	composes: font-sm-medium from global;
}
.accordionSummary__content {
	width: 100%;
	margin: 0 !important;
	display: flex;
	justify-content: flex-start;
	font-size: var(--font-size-sm);
	align-items: center;
	padding: 0;
	min-height: 0;
}
.accordionSummary__root {
	margin: 0;
	padding: 0;
	min-height: 0 !important;
	transition-duration: 150ms, 150ms !important;
}
.accordionDetails__root {
	flex-direction: column;
	padding: 0;
}
