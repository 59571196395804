.similarDetection__matchedNorm {
	cursor: pointer;
	padding: 8px;
	background-color: var(--color-light-grey-4);
	border-radius: 8px;
	white-space: nowrap;
}
.similarDetection__matchedNorm:hover {
	background-color: var(--shades-blue-50);
}
.matchedIcon {
	margin-right: 8px;
}
