.statusBar {
	display: flex;
	white-space: nowrap;
}
.status--toBeReviewed {
	background-color: var(--color-dark-blue);
}
.status--accepted {
	background-color: var(--status-yes);
}
.status--pending {
	background-color: var(--status-pending);
}
.status--denied {
	background-color: var(--status-no);
}
.status--notMe {
	background-color: var(--status-not-me);
}
.noContent {
	composes: font-sm-regular from global;
	padding: 10px 18px;
	border-radius: 4px;
	background-color: var(--shades-blue-50);
	color: var(--shadow-6);
}
.noContent__infoIcon {
	margin-right: 10px;
}
