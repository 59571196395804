.phase__canvas {
	position: relative;
}

.phase__parallelogram--left {
	width: 20%;
	height: 10%;
	background: var(--color-light-grey-3);
	transform: skew(20deg);
	display: flex;
	position: absolute;
	z-index: 1;
}

.phase__parallelogram--left:hover {
	width: 30%;
}

.phase__parallelogram-text--left {
	transform: skew(-20deg);
	text-align: center;
	margin: auto;
	font-size: var(--font-size-md);
	display: flex;
	width: 100%;
}

.phase__parallelogram--right {
	width: 20%;
	height: 10%;
	background: var(--color-light-grey-3);
	transform: skew(-20deg);
	display: flex;
	position: absolute;
	z-index: 1;
}

.phase__parallelogram--right:hover {
	width: 30%;
}

.phase__parallelogram-text--right {
	transform: skew(20deg);
	text-align: center;
	margin: auto;
	font-size: var(--font-size-md);
	display: flex;
	width: 100%;
}

.phase__text {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 95%;
	white-space: nowrap;
	margin: 0 auto;
}

.phase__trapezoid {
	width: 20%;
	height: 10%;
	display: flex;
	position: absolute;
	background: var(--color-light-grey-3);
	z-index: 1;
}

.phase__trapezoid-text {
	text-align: center;
	margin: auto;
	font-size: var(--font-size-md);
	display: flex;
	width: 100%;
}
.StyledIconButtonLeft__root {
	padding: 0px !important;
	transform: skew(-20deg);
	position: absolute;
	top: 35%;
	left: -15%;
}
.StyledIconButtonRight__root {
	padding: 0px !important;
	transform: skew(20deg);
	position: absolute;
	top: 35%;
	right: -15%;
}
.StyledIconButtonMiddleLeft__root {
	padding: 0px !important;
	position: absolute;
	top: 35%;
	left: -5%;
}
