.dashboardContainer {
	width: 100%;
	height: calc(100vh - var(--viewbanner-height) - var(--navbar-height));
	background-color: var(--shades-grey-100);
	display: flex;
	justify-content: center;
}
.dashboard {
	display: flex;
	flex-direction: column;
}
.line {
	display: flex;
	flex-direction: row;
	margin: 10px 10px 0 10px;
}
.line--last {
	margin-bottom: 50px;
}
.line__element--left {
	margin: 0 5px 0 0;
}
.line__element--right {
	margin: 0 0 0 5px;
}
@media screen and (width >= 1200px) {
	.dashboard {
		width: 70%;
	}
}
@media screen and (width >= 800px) and (width <1200px) {
	.dashboard {
		width: 80%;
	}
}
@media screen and (width < 800px) {
	.dashboard {
		width: 100%;
	}
}
