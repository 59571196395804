.title {
	composes: font-md-medium from global;
	padding: 0;
}
.formControl {
	margin: 14px 0;
	min-width: 100%;
}
.content {
	vertical-align: middle;
	width: 100%;
	height: 100%;
}
.container {
	display: flex;
}
.paper {
	padding: 16px;
	text-align: center;
	flex: 1 0;
	margin: 8px 0;
}
.inputLabel {
	position: absolute;
	top: -20%;
}
.contentTypeChip {
	position: absolute;
	left: 16px;
	bottom: 20px;
}
