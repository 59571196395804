.phasesContainer {
	overflow: auto;
	height: calc(100% - 136px);
}
.counter {
	padding-left: var(--spacing-xs);
}
.circularLoaderContainer {
	height: calc(100% - 120px);
	display: flex;
	align-items: center;
	justify-content: center;
}
.container_search {
	width: calc(100% - var(--spacing-xs)) !important;
}
.container_search > div:first-of-type {
	padding-left: var(--spacing-xs);
}
.accordionContent {
	width: 100%;
}
.phaseName {
	composes: font-sm-medium from global;
	max-width: calc(100% - 40px);
	height: 32px;
	line-height: 32px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.actionContainer {
	display: flex;
	justify-content: flex-end;
	gap: var(--spacing-2xs);
	margin-right: var(--spacing-xs);
}
.emptyState__container {
	height: 100%;
	padding: 0 var(--spacing-xs);
	display: flex;
	align-items: center;
	justify-content: center;
}
.emptyState__icon {
	margin-right: var(--spacing-2xs);
}
