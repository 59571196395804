.letter {
	text-transform: capitalize;
	line-height: 32px;
	text-align: center;
	color: var(--color-white);
	background-color: var(--color-blue);
	font-size: var(--font-size-xl);
	display: inline-block;
	width: 32px;
	height: 32px;
	border-radius: 50%;
}
.letter[data-size="sm"] {
	width: 32px;
	height: 32px;
	line-height: 32px;
}
.letter[data-size="md"] {
	width: 40px;
	height: 40px;
	line-height: 40px;
}
.letter[data-size="lg"] {
	width: 48px;
	height: 48px;
	line-height: 48px;
}
.icon {
	font-size: var(--font-size-md);
}
.icon[data-size="2xs"] {
	font-size: var(--font-size-2xs);
}
.icon[data-size="xs"] {
	font-size: var(--font-size-xs);
}
.icon[data-size="sm"] {
	font-size: var(--font-size-sm);
}
.icon[data-size="md"] {
	font-size: var(--font-size-md);
}
.icon[data-size="lg"] {
	font-size: var(--font-size-lg);
}
.icon[data-size="xl"] {
	font-size: var(--font-size-xl);
}
.icon[data-size="2xl"] {
	font-size: var(--font-size-2xl);
}
.icon[data-size="3xl"] {
	font-size: var(--font-size-3xl);
}
.icon[data-size="4xl"] {
	font-size: var(--font-size-4xl);
}
.icon[data-size="5xl"] {
	font-size: var(--font-size-5xl);
}
.icon[data-size="8xl"] {
	font-size: var(--font-size-8xl);
}
