.table__iconHead {
	width: 32px;
	display: flex;
}
.icon--accepted {
	color: var(--color-green);
}
.icon--pending {
	color: var(--color-orange);
}
.icon--denied {
	color: var(--color-red);
}
.icon--toBeReviewed {
	color: var(--color-dark-blue);
}
.icon--notme {
	color: var(--color-black);
}
.header__documentName {
	padding-left: var(--view-padding-lateral);
}

.tooltip {
	box-shadow: 0px 5px 20px 0.3px var(--shadow-5);
	font-size: var(--font-size-sm);
}
.headerCell {
	padding-top: 42px;
}
.customHeaderCell {
	padding-top: 24px;
	background-color: var(--color-white) !important;
}
.customHeaderCell--status {
	width: 6%;
}
.lastStickyCell {
	text-align: right;
}
.stickyCell {
	position: sticky;
	background-color: var(--shades-grey-200);
	border-bottom: solid 2px var(--shades-grey-300);
	z-index: 1;
}
.firstRow__documentName {
	font-weight: bold;
}
.tableCell__documentName {
	width: 35%;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	padding-left: var(--view-padding-lateral);
}
.progressBar--white {
	background-color: var(--color-white);
}
.non-clickable {
	cursor: default !important;
}
.tableCell__role {
	max-width: 100px;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
}
.tableCell__placeHolder {
	padding: 16px 8px;
	width: 64px;
	min-width: 64px;
}
