.emptyState__wrapper {
	background-color: inherit;
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: relative;
}
.emptyState__header {
	font-size: var(--font-size-sm);
	font-weight: 500;
	text-align: center;
	margin-bottom: 8px;
}
.emptyState__body {
	font-size: var(--font-size-xs);
	font-weight: 400;
	overflow: auto;
	text-align: center;
}
.emptyState__body > a:hover {
	color: var(--color-blue);
}
.emptyState__body > a {
	margin-left: var(--spacing-2xs);
}
.emptyState__footer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 16px;
	text-align: center;
}
.emptyState__footer > * {
	size: "sm" !important;
}
