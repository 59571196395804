.container {
	display: flex;
	margin-bottom: 8px;
}
.circularProgress {
	margin: 60px auto;
}
.redress-split__button {
	padding-left: 8px;
	text-transform: none;
	place-content: normal;
	text-align: left;
	width: 100%;
	color: var(--color-black);
	border-radius: 0px;
}
