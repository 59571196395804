.container {
	width: 80%;
}

.container--margin {
	margin: 16px;
}

.title {
	display: flex;
	width: 100%;
	padding-top: 14px;
}

.title__text {
	font-size: var(--font-size-2xl);
	align-self: center;
	margin: 0 auto;
}
