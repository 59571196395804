.label__text {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
}
.label__text--fullWidth {
	width: 100%;
}
.label__text--start {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	flex-shrink: 1;
}
.label__text--end {
	white-space: nowrap;
	flex-basis: content;
	flex-grow: 0;
	flex-shrink: 0;
}
.label__text--fullTextCopied {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	background-color: var(--color-blue) !important;
	color: var(--color-white) !important;
}
