.header {
	position: relative;
	display: flex;
	justify-content: center;
	padding: 0 16px;
}

.header__title {
	align-self: center;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: var(--font-size-lg);
}
.header__buttons {
	position: absolute;
	right: 0px;
	bottom: -2px;
}
