.viewTabs {
	background-color: var(--color-white);
	height: var(--viewtabs-height);
	padding: 0 var(--view-padding-lateral);
	color: var(--color-black);
}
.viewTabs[data-nopadding="true"] {
	padding: 0 8px;
}
.appBar[data-level="first"] {
	background-color: var(--shades-grey-100);
	box-shadow: none;
	z-index: 11;
}
.tabs[data-level="first"] {
	background-color: var(--shades-grey-100);
	box-shadow: none;
}
.container[data-shadow="false"] {
	box-shadow: none;
}
.container {
	z-index: 10;
}
.viewTabs__indicator {
	display: flex;
	justify-content: center;
	background-color: var(--color-blue);
}
