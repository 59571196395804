.secondaryActions__container {
	display: flex;
	justify-content: flex-end;
	gap: var(--spacing-xs);
}
.image {
	overflow: auto;
	position: relative;
	mix-blend-mode: multiply;
}
.image img {
	box-shadow: 2px 2px 2px var(--color-light-grey-4);
}
.overlay {
	border-radius: 2px;
	z-index: 1;
	position: absolute;
	mix-blend-mode: multiply;
	background-size: cover;
}
.overlay[data-blinking="true"] {
	animation: blinker 4s linear infinite;
}
.overlay[data-color="primary"] {
	border: 1px solid var(--color-orange);
	background-color: var(--color-light-green-40);
}
.overlay[data-color="secondary"] {
	border: 1px solid var(--tints-blue-30);
	background-color: var(--tints-blue-10);
}
.overlay:hover {
	background-color: var(--tints-blue-30);
	animation: none;
}
.zoomButtons {
	position: absolute;
	z-index: 2;
	top: 70px;
	right: 50px;
	border: 1px solid lightgrey;
	border-radius: 24px;
	background-color: var(--color-light-grey-3);
	color: var(--color-black);
	opacity: 0.7;
}
.container {
	display: flex;
	flex-direction: column;
	margin: var(--spacing-s);
	padding: var(--spacing-xs);
	background-color: var(--color-white);
	border: 1px solid var(--shades-grey-300);
	border-radius: 5px;
	overflow-x: auto;
	height: calc(100% - 32px);
}
.input {
	width: 100px;
}
@keyframes blinker {
	50% {
		opacity: 0.3;
	}
}
