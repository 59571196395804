.content__radios {
	padding: 16px 0;
}
.radios__label {
	composes: font-md-regular from global;
	line-height: 1.5;
	letter-spacing: 0.15px;
	color: var(--color-black);
}
.content__inputs {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 8px 0;
}
.header {
	display: flex;
	flex-direction: column;
}
.inputTitle {
	text-align: left;
	composes: font-xl-bold from global;
}
.inputTitle > *:first-child {
	margin-right: 8px;
}
.dialog {
	text-align: start;
}
.infoIcon {
	margin-right: 8px;
}
.infoBox {
	background-color: var(--color-blue-10);
	padding: 8px;
	border-radius: 4px;
}
