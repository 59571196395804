.table {
	display: block;
	max-height: calc(100vh - 448px);
	overflow: auto;
}
.row {
	padding: var(--spacing-s) 0;
	display: flex;
	border-bottom: 1px solid var(--color-light-grey-3);
}
.row_header {
	composes: font-md-bold from global;
}
.cell__date {
	width: 110px;
	margin: auto 0;
}
.cell__name {
	width: calc(100% - 250px);
	margin: auto 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.cell__actions {
	width: 140px;
}
.icons {
	display: flex;
	justify-content: flex-end;
	gap: var(--spacing-xs);
}
