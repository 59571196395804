.accordionSummary__content {
	margin: 0 !important;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 8px 0;
	min-height: 72px;
	background-color: var(--color-white);
	border-bottom: 1px solid var(--color-light-grey-3);
}
.accordionSummary__root {
	margin: 0;
	padding: 0;
	min-height: 0 !important;
	transition-duration: 150ms, 150ms !important;
}
.accordion__root {
	box-shadow: none !important;
	position: inherit;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.accordionDetails__root {
	flex-direction: column;
	padding: 0;
}
.row__rightItems {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	margin-left: 32px;
}
.row__rightItems[data-issubscription="true"] {
	justify-content: flex-end;
}
.row__rightItems > * {
	display: flex;
	align-items: center;
	margin-right: 16px;
}
.row__leftItems {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.row__name--title {
	width: 250px;
}
.row__name {
	composes: font-sm-medium from global;
	overflow-wrap: break-word;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.row__name--regular {
	font-weight: normal !important;
}
.buttonsGroup__plusButtonContainer {
	width: 40px;
}
.rightItems__buttonsGroup {
	white-space: nowrap;
	min-width: 150px;
	justify-content: center;
}
.rightItems__buttonsGroup > *:nth-child(2) {
	margin: 0 8px;
}
.rightItems__description {
	composes: font-sm-regular from global;
	justify-content: left;
	width: 70%;
	min-width: 100px;
	word-break: break-word;
}
.rightItems__subscriptions {
	justify-content: left;
	width: 30%;
	min-width: 100px;
	font-size: var(--font-size-sm);
}
.subscriptionsButton {
	align-items: left;
}
.subscriptionsButton__text {
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.buttonHeightFit {
	height: fit-content !important;
	line-height: 18px;
}
.expandButtonContainer {
	width: 48px;
	display: flex;
	justify-content: center;
}
.rightItems__contents {
	min-width: 190px;
	display: flex;
	justify-content: center;
}
.rightItems__actionTitle {
	min-width: 208px;
	justify-content: left;
	composes: font-sm-medium from global;
}
.rightItems__actionTitle[data-rightaligned="true"] {
	justify-content: end;
}
.rightItems__actionTitle--empty {
	min-width: 150px;
}
.rightItems__descriptionTitle {
	justify-content: left;
	width: 70%;
	min-width: 100px;
	composes: font-sm-medium from global;
}
.rightItems__subscriptionsTitle {
	justify-content: left;
	width: 30%;
	min-width: 100px;
	composes: font-sm-medium from global;
}
.counterContainer {
	justify-content: left;
	min-width: 208px;
}

.rightItems__contentsTitle {
	min-width: 190px;
	justify-content: center;
	composes: font-sm-medium from global;
}
