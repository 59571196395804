.members-row {
	text-align: left;
	align-items: center;
	display: flex;
	flex-direction: row;
	border-right: 0;
	border-left: 0;
	border-top: 0;
	border-bottom: 2px solid var(--shades-grey-300);
	background-color: var(--color-white);
	width: 100%;
	font-size: var(--font-size-sm);
}
.members-row > * {
	padding: 8px 8px 8px 16px;
	min-height: 32px;
	display: flex;
	flex-direction: row;
	text-align: left;
	align-items: center;
}
.members-row__rights-on-documents {
	min-width: 200px;
}
.members-row__button-cell {
	min-width: 40px;
	width: 10%;
}
.members-row__select-cell {
	width: 25%;
	min-width: 132px;
}
.members-row__name-cell {
	min-width: 100px;
	width: 30%;
}
