.chip {
	height: 26px;
}
.chip__root {
	composes: font-xs-regular from global !important;
	color: var(--color-white);
	cursor: default;
	height: 24px;
	line-height: 24px;
	max-width: 200px;
	background-color: var(--color-light-grey-1);
	padding: 0 var(--spacing-xs);
	border-radius: 12px;
}
