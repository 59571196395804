.dialog-content__section {
	display: flex;
	flex-direction: column;
}

.dialog-content__section-title {
	font-weight: bold;
	font-size: var(--font-size-sm);
	text-transform: uppercase;
	margin-bottom: 12px;
}
.dialog-content__input-label {
	position: absolute;
	top: -20%;
}

.dialog-content__section-params {
	display: flex;
	place-content: space-evenly;
}

.dialog-content__section-param {
	margin-left: 8px;
}
