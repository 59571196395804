.header {
	display: flex;
	width: 100%;
	margin: var(--spacing-xs);
	padding: var(--spacing-xs);
}
.header > div {
	margin-right: var(--spacing-xs);
}
.header > div:nth-of-type(2) {
	width: 200px;
}
.searchContainer__textfield {
	min-width: 300px;
}
.header > button {
	margin-left: auto;
	margin-right: var(--spacing-m);
}
