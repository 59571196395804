.filter {
	display: flex;
	white-space: nowrap;
}
.filter__popup {
	background: var(--color-white);
	z-index: 10;
	max-width: 500px;
	max-height: 500px;
	display: flex;
	flex-direction: column;
	box-shadow: 10px 10px 30px var(--shadow-2);
	overflow: hidden;
}
.popup__header {
	display: flex;
	align-items: center;
	padding: 16px;
}
.header__title {
	width: 100%;
	font-size: var(--font-size-lg);
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin: 0;
}
.popup__content {
	position: relative;
	padding: 0 16px;
	overflow: auto;
}
.popup__footer {
	padding: 16px;
}
.button-group__clear-btn {
	height: 40px !important;
	width: 40px !important;
	background-color: var(--color-primary-hover) !important;
	border-radius: 0px 4px 4px 0px !important;
}
.button-group {
	height: 40px !important;
}
