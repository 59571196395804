.textfield__item {
	composes: font-sm-regular from global;
	width: 100%;
	height: 24px;
	line-height: 24px;
}
.textfield__item[data-disabled="true"] {
	pointer-events: auto;
}
.icon {
	width: 1em;
	height: 1em;
	display: inline-block;
	flex-shrink: 0;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	font-size: 1.5rem;
	position: absolute;
	right: 7px;
	top: calc(50% - 0.5em);
	pointer-events: none;
	color: rgba(0, 0, 0, 0.54);
}
