.row__container {
	display: flex;
	width: 100%;
	height: 32px;
	align-items: center;
	justify-content: space-between;
	gap: var(--spacing-xs);
	cursor: pointer;
	border-radius: 4px;
}
.row__container:not(:last-child) {
	margin-bottom: var(--spacing-2xs);
}
.row__container:hover {
	background-color: var(--color-light-grey-4);
}
.row__container[data-selected="true"] {
	background-color: var(--color-light-grey-4);
	color: var(--color-blue);
	cursor: initial;
}
.title__container {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.btn__container {
	display: flex;
}
.button {
	color: var(--color-dark-grey-2) !important;
	width: 24px !important;
	height: 24px !important;
}
.iconClassName {
	width: 16px !important;
	height: 16px !important;
}
