.btn {
	text-transform: none;
	height: 32px;
	min-width: 0px;
	padding: var(--spacing-xs);
	font-size: var(--font-size-sm);
	line-height: var(--font-size-sm);
	color: var(--color-blue);
	gap: 6px;
}
.btn[data-variant="contained"][data-color="primary"]:disabled {
	background-color: var(--shades-blue-100) !important;
	color: var(--color-white);
}
.btn[data-variant="contained"][data-color="primary"]:hover {
	background-color: var(--color-primary-hover);
}
.btn[data-variant="contained"][data-color="primary"] {
	background-color: var(--color-blue);
	color: var(--color-white);
}
.btn[data-variant="contained"][data-color="secondary"]:hover {
	background-color: var(--color-light-grey-2);
}
.btn[data-variant="contained"][data-color="secondary"] {
	background-color: var(--color-light-grey-4);
	color: var(--color-dark-grey-2);
}
.btn[data-variant="outlined"][data-color="primary"]:disabled {
	border: 1px solid var(--shades-blue-50) !important;
	color: var(--shades-blue-100);
}
.btn[data-variant="outlined"][data-color="primary"]:hover {
	border: 1px solid var(--color-primary-hover) !important;
	color: var(--color-primary-hover);
	background-color: transparent;
}
.btn[data-variant="outlined"][data-color="primary"] {
	border: solid 1px var(--color-blue);
}
.btn[data-variant="outlined"][data-color="secondary"]:disabled {
	border: solid 1px var(--color-light-grey-4);
	color: var(--color-light-grey-3);
}
.btn[data-variant="outlined"][data-color="secondary"]:hover {
	border: solid 1px var(--color-light-grey-2);
	color: var(--color-dark-grey-2);
	background-color: transparent;
}
.btn[data-variant="outlined"][data-color="secondary"] {
	border: solid 1px var(--color-light-grey-3);
	color: var(--color-dark-grey-2);
}
.btn[data-variant="text"][data-color="primary"]:disabled {
	color: var(--shades-blue-100);
}
.btn[data-variant="text"][data-color="primary"]:hover {
	text-decoration: underline;
}
.btn[data-variant="text"][data-color="primary"] {
	color: var(--color-blue);
	background-color: transparent;
}
.btn[data-variant="text"][data-color="secondary"]:disabled {
	color: var(--color-light-grey-2) !important;
}
.btn[data-variant="text"][data-color="secondary"]:hover {
	background-color: var(--color-light-grey-4);
}
.btn[data-variant="text"][data-color="secondary"] {
	color: var(--color-dark-grey-2);
}
.btn[data-size="xl"] {
	height: 48px;
	padding: 7px 22px;
	font-size: var(--font-size-md);
	line-height: var(--font-size-md);
}
.btn[data-size="lg"] {
	height: 40px;
	padding: 7px 22px;
	font-size: var(--font-size-md);
	line-height: var(--font-size-md);
}
.btn[data-size="md"] {
	height: 32px;
	min-width: 0px;
	padding: var(--spacing-xs);
	font-size: var(--font-size-sm);
	line-height: var(--font-size-sm);
	gap: 6px;
}
.btn[data-size="sm"] {
	height: 24px;
	gap: var(--spacing-2xs);
	padding: var(--spacing-xs);
	font-size: var(--font-size-xs);
	line-height: var(--font-size-xs);
}
