.container {
	composes: font-xs-medium from global;
	font-weight: 500 !important;
	display: flex;
	width: 100%;
	border-radius: var(--spacing-xs);
	background-color: var(--color-light-grey-4);
	margin-bottom: var(--spacing-2xs);
	color: var(--color-black);
}
.container:hover {
	background-color: var(--color-light-grey-3);
}
.container[data-is-child="true"] {
	background-color: var(--color-white);
}
.container[data-is-child="true"]:hover {
	background-color: var(--shades-grey-100);
}
.container[data-selected="true"] {
	color: var(--color-blue);
}
.container[data-selected="true"][data-is-child="true"] {
	color: var(--color-blue);
}
.container[data-level="0"] {
	font-weight: bold;
}
.container:not(:first-child) {
	font-weight: normal !important;
}
.content {
	width: 100%;
	height: 32px;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	cursor: pointer;
	display: flex;
	align-items: center;
}
.count {
	margin-left: auto;
	margin-right: var(--spacing-xs);
	composes: font-xs-medium from global;
	font-weight: 500 !important;
}
.label {
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.content[data-has-children="false"] {
	padding-left: var(--spacing-l);
}
.button__btn {
	margin-right: 0 !important;
	height: 32px !important;
}
.button__icon {
	color: var(--color-light-grey-1);
}
