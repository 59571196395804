.accordionContent {
	margin: 0 !important;
}
.accordion__header > * {
	font-size: var(--font-size-sm);
	align-items: center;
}
.accordion__root {
	box-shadow: none;
	padding: 0;
}
.accordion__root > * {
	padding: 0;
	min-height: unset !important;
}
.accordion__root::before {
	height: 0;
}
.arrowContainer {
	width: 32px;
	min-width: 32px;
}
.accordionDetails__root {
	padding: 0 0 0 45px;
	flex-direction: column;
}
.accordionDetails__root[data-rightpadding="true"] {
	padding-right: 45px;
}
.accordion-summary__content {
	margin: 0 !important;
}
.header {
	composes: font-lg-regular from global;
}
