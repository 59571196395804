.row {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0 var(--spacing-s);
	background-color: var(--color-white);
	border-bottom: 1px solid var(--shadow-1);
}
.row__information {
	height: 40px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.row__information--leftSide {
	display: flex;
	align-items: center;
	gap: var(--spacing-xs);
	width: calc(100% - 32px);
}
.row__attributes {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding-left: 48px;
	align-items: center;
	gap: var(--spacing-xs);
}
.row__title {
	composes: font-sm-regular from global;
	color: var(--color-dark-grey-1);
	white-space: nowrap;
	text-transform: uppercase;
	display: flex;
	align-items: center;
}
.breadcrumbContainer {
	width: calc(100% - 160px);
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
}
.divider {
	height: 18px;
}
.row__document {
	composes: font-sm-regular from global;
	display: inline-block;
	padding: 0 2px;
	color: var(--color-dark-grey-2);
	line-height: 1.5;
	letter-spacing: 0.15px;
}
.row__description {
	composes: font-sm-regular from global;
	color: var(--color-black);
	padding-left: 40px;
	margin: var(--spacing-xs);
}
.requiredIcon,
.infoIcon {
	font-size: var(--font-size-xs) !important;
	padding-right: 7px;
}
.row__information--actions {
	display: flex;
	align-items: center;
}
.row__information--score {
	margin-right: var(--spacing-xs);
	color: var(--color-light-grey-2);
}
