.dialog__title {
	display: flex;
}

.dialog__title .dialog__title-text {
	width: 100%;
	align-self: center;
}

.dialog__content {
	-ms-overflow-style: none;
	display: flex;
	flex-direction: column;
}

.dialog__content::-webkit-scrollbar {
	display: none;
}

.dialog__field {
	margin: 8px 0 !important;
}
