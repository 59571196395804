.btnGroup[data-variant="outlined"] {
	border: solid 1px var(--color-blue);
}
.btnGroup :hover {
	background-color: transparent !important;
}
.btnGroup * {
	height: 36px !important;
	font-size: var(--font-size-sm) !important;
	color: var(--color-blue);
}
.btnGroup *:disabled {
	background-color: var(--color-blue-10);
}
.btnGroup *:disabled:hover {
	background-color: var(--color-blue-10) !important;
}
.btnGroup[data-variant="outlined"][data-color="primary"] {
	border: solid 1px var(--color-blue);
}
.btnGroup[data-variant="outlined"][data-color="secondary"] {
	border: solid 1px var(--color-light-grey-3);
}
.btnGroup[data-color="primary"] * {
	color: var(--color-blue);
}
.btnGroup[data-color="secondary"] * {
	color: var(--color-dark-grey-2);
}
.btnGroup[data-color="secondary"] *:disabled {
	background-color: var(--color-light-grey-4);
}
.btnGroup[data-color="secondary"] *:disabled:hover {
	background-color: var(--color-light-grey-4) !important;
}
.btnGroup[data-color="primary"] *:disabled {
	background-color: var(--color-blue-10);
}
.btnGroup[data-color="primary"] *:disabled:hover {
	background-color: var(--color-blue-10) !important;
}
.btnGroup[data-size="sm"] * {
	height: 32px !important;
	font-size: var(--font-size-sm) !important;
}
.btnGroup[data-size="md"] * {
	height: 36px !important;
	font-size: var(--font-size-sm) !important;
}
.btnGroup[data-size="lg"] * {
	height: 40px !important;
	font-size: var(--font-size-md) !important;
}
