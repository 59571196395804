.searchContainer__textfield {
	width: 100%;
	margin: 16px 0;
}
.accordion__root {
	box-shadow: none !important;
	position: inherit;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	border-radius: 0 !important;
}
.accordion__root > div {
	padding-left: 0 !important;
}
.accordionSummary__content {
	margin: 0 !important;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 8px;
}
.accordionSummary__root {
	min-height: 0 !important;
	transition-duration: 150ms, 150ms !important;
}
.summary__content {
	display: flex;
	align-items: center;
	font-size: var(--font-size-sm);
	font-weight: 700;
	text-transform: uppercase;
	color: var(--shades-grey-600);
}
.expandIcon {
	margin-right: 8px;
}
.favoriteCategory {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: var(--font-size-sm);
}
.projectCategories__title {
	display: flex;
	font-size: var(--font-size-sm);
	font-weight: 700;
	text-transform: uppercase;
	color: var(--shades-grey-600);
	padding-left: 6px;
}
.projectCategories__container {
	padding: 0 16px;
}
.loaderContainer {
	width: 100%;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.horizontalDivider {
	margin-bottom: 16px;
}
.emptyState {
	composes: font-lg-regular from global;
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.emptyIcon {
	margin-right: 4px;
}
