.main {
	height: 100%;
}
.container {
	background-color: var(--color-white);
	height: calc(100% - 64px) !important;
	overflow-y: auto;
	padding: var(--spacing-m) var(--spacing-s) var(--spacing-s) var(--spacing-s);
	display: flex;
	flex-direction: column;
	composes: font-md-regular from global;
}
.footer {
	height: 64px;
	border-top: 1px solid var(--color-light-grey-3);
	background-color: var(--color-white);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: var(--spacing-s);
	gap: var(--spacing-xs);
}
.complexBanner {
	margin-top: auto;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	color: var(--color-dark-grey-2);
	padding: 0 var(--spacing-s);
	background-color: var(--color-orange-20);
}
.complexBanner > span > a {
	padding-left: var(--spacing-2xs);
}
.box {
	width: 100%;
	display: flex;
	align-items: center;
}
.box > div:first-of-type {
	width: 45px;
}
.box > span:last-of-type {
	margin-left: var(--spacing-s);
}
.box > button {
	margin-left: var(--spacing-xs);
}
.name_title {
	composes: font-md-regular from global;
	margin-bottom: var(--spacing-s);
}
.name_container {
	margin-bottom: var(--spacing-l);
}
.name_box {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: var(--spacing-s);
}
.panel__categoryNameContainer {
	height: 32px;
}
.panel__categoryNameInput {
	composes: font-md-regular from global;
	padding: var(--spacing-2xs) !important;
	padding-left: var(--spacing-xs) !important;
}
.fulltext_title {
	composes: font-md-regular from global;
	margin-bottom: var(--spacing-s);
}
.fulltext_main {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: var(--spacing-s);
}
.fulltext_main > div {
	width: calc(100% - 48px);
	margin-right: auto;
	height: 32px;
}
.fulltext_main > div > div > textarea {
	padding: 6px !important;
	font-size: var(--font-size-md) !important;
}
.multiplInput_container {
	width: 100%;
	display: flex;
	align-items: flex-start;
	padding-left: var(--spacing-4xl);
}
.multiplInput_container > span {
	margin-left: 14px;
	align-self: flex-start;
}
.copyDeactive {
	margin-top: var(--spacing-m);
}
.copyActive {
	margin-top: var(--spacing-3xl);
}
.multiplInput {
	width: calc(100% - 45px);
	margin-bottom: var(--spacing-m);
	margin-top: var(--spacing-s);
}
.multiplInput > div:nth-of-type(1) > div {
	height: 32px;
	padding-top: 1px;
}
.multiplInput > div:nth-of-type(1) > div > div {
	height: 32px;
}
.multiplInput > div:nth-of-type(2) > div:last-of-type {
	flex: none !important;
}
.link {
	display: contents;
}
