.errorMessage__wrapper {
	background-color: var(--color-white);
	border-radius: 4px;
	border: 1px solid var(--color-light-grey-3);
	padding: 50px;
	font-size: var(--font-size-xl);
	color: var(--color-dark-grey-1);
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.errorMessage__header {
	text-align: center;
	font-size: var(--font-size-5xl);
	margin-bottom: 20px;
}
.errorMessage__body {
	margin-bottom: 2rem;
	width: 80%;
	overflow: auto;
	text-align: center;
}
.errorMessage__contactSupport {
	margin-bottom: 1rem !important;
}
.errorMessage__footer {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	width: 50%;
}
