.container_filter {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	box-shadow: none;
	composes: font-sm-regular from global;
}
.tabs span {
	top: 35px;
}
.tabs > * {
	padding-left: var(--spacing-2xs) !important;
}
.container_search {
	display: flex;
	align-items: flex-start;
	gap: var(--spacing-xs);
	align-self: stretch;
}
.filterBulkContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.counter {
	padding-left: var(--spacing-2xs);
}
.filterContainer {
	flex: 0;
}
.filters {
	margin-left: -3px;
}
.bulkButton {
	flex: 1;
	white-space: nowrap;
	max-width: fit-content !important;
}
.listContainer {
	height: calc(100% - 136px);
	overflow: auto;
}
.listContainer > div {
	margin: 0 !important;
}
.loaderContainer {
	display: flex;
	justify-content: center;
	padding-top: var(--spacing-l);
	width: 100%;
}
.noContent {
	composes: font-sm-regular from global;
	padding: 10px 18px;
	border-radius: 4px;
	background-color: var(--shades-blue-50);
	color: var(--shadow-6);
}
.noContent__infoIcon {
	margin-right: 10px;
}
