.tileFooter {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 5px 0 0 0;
}
.tileFooter__content {
	margin: 5px 0 0 0;
	width: 100%;
}
