.main {
	margin-top: var(--spacing-s);
}
.title {
	font-size: var(--font-size-l);
	font-weight: bold;
}
.main > div {
	margin-top: var(--spacing-xs);
}
