.drawer {
	width: 100%;
}
.drawer__newSearchLabel {
	white-space: nowrap;
}
.buttonsContainer {
	display: flex;
	flex-wrap: wrap;
	margin-left: -4px;
}
.buttonsContainer > * {
	flex-grow: 1;
	margin: 4px;
}
.infiniteSelector {
	padding: 10.5px 12px;
}
.infiniteSelector__list {
	max-height: 400px;
}
.searchFieldsContainer {
	display: flex;
	flex-wrap: wrap;
}
.searchFieldsContainer > * {
	margin: 4px;
	flex-grow: 1;
	font-size: var(--font-size-sm);
	font-weight: 400;
	height: 32px;
}
