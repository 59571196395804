.cell {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.cell--disabled {
	background-color: var(--shades-grey-300);
	height: 100%;
	width: 100%;
}
.grid {
	max-width: 80%;
	max-height: 80%;
}
