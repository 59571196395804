.import__title {
	font-size: var(--font-size-md);
	text-align: center;
}

.import__btn {
	display: flex;
	flex-direction: column;
	margin: 16px;
}

.import__btn-types {
	margin: auto;
	font-size: var(--font-size-xs);
}
