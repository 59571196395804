.match__text {
	font-size: var(--font-size-md);
}
.match__radio {
	align-items: start;
}
.match__radio-label {
	text-align: left;
	padding: 9px 0;
}
