.req-row {
	padding-left: 16px;
	text-align: left;
	border-right: 0;
	border-left: 0;
	border-top: 0;
}

.req-row--selected {
	background-color: var(--color-blue);
	color: var(--color-white);
}

.req-row__header {
	display: flex;
	padding-bottom: 2px;
}

.req-row__title {
	font-weight: bold;
	font-size: var(--font-size-sm);
}

.req-row__ref {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	align-self: center;
	font-style: italic;
	font-size: var(--font-size-sm);
	margin: 0 4px;
}

.req-row__content {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	margin: 0;
	font-size: var(--font-size-sm);
}
