.pageContainer {
	display: inline-block;
	height: 32px;
	line-height: 32px;
	margin-top: 1px;
	margin-right: 32px;
	min-width: 100px;
}
.pageContainer__pageInput {
	composes: font-md-medium from global;
	border-radius: 4px;
	margin-right: 8px;
	background-color: var(--color-white);
}
.pageContainer__pageInput--padding {
	width: 64px !important;
	height: 32px;
	padding: 0;
	text-align: center;
}
