.informationContent {
	padding: var(--spacing-xs);
	background-color: var(--color-white);
	border-radius: 4px;
	border: 1px solid var(----color-light-grey-4);
	margin: var(--spacing-xs);
}
.breadcrumbContainer {
	width: 100%;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
}
.breadcrumb {
	composes: font-xs-regular from global;
}

.informationContent__text {
	composes: font-sm-regular from global;
	margin-bottom: var(--spacing-2xs);
	max-height: 160px;
	overflow: auto;
}
.informationContent__text[data-hide="true"] {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
}
.informationContent__btnContainer {
	display: flex;
	width: 100%;
	justify-content: flex-end;
}
.informationContent__btn {
	margin-left: 8px;
	padding: 8px !important;
	height: 28px !important;
	font-size: 12px !important;
}
.informationContent__btn > span {
	margin-right: 0 !important;
	height: var(--font-size-xs);
	width: var(--font-size-xs);
}
.tabs > * {
	padding: 0 16px !important;
	height: 40px;
	min-height: 40px;
}
.tab {
	composes: font-xs-bold from global;
	font-size: var(--font-size-xs);
	height: 40px;
	min-height: 40px;
	padding: 0 4px;
	margin-right: 4px;
}
.panel {
	height: 100%;
	pointer-events: all;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: calc(100% - 64px);
	background-color: var(--color-white);
}
.panel__content {
	overflow-y: hidden;
	height: calc(100% - 28px);
	width: 100%;
}
.panel__subcontent {
	overflow-y: auto;
}
