.pendingDetection__matchedNorm {
	cursor: pointer;
	padding: 8px;
	background-color: var(--color-light-grey-4);
	border-radius: 8px;
	white-space: nowrap;
}
.pendingDetection__matchedNorm--blue {
	background-color: var(--color-blue);
	color: var(--color-white);
}
.pendingDetection__matchedNorm--blue > * {
	color: var(--color-white);
}
.pendingDetection__matchedNorm:hover {
	background-color: var(--shades-blue-50);
}
.pendingDetection__matchedNorm--blue:hover {
	background-color: var(--shades-blue-200);
}
.matchedIcon {
	margin-right: 8px;
}
.pendingDetection__cell {
	text-align: center;
}
