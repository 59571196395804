.row {
	min-height: var(--spacing-2xl);
	border: 1px solid var(--color-light-grey-3);
	width: auto;
	margin: 0 var(--spacing-s);
	padding: var(--spacing-s);
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--color-light-grey-3);
}
.name {
	overflow: hidden;
	text-overflow: ellipsis;
	width: calc(100% - 132px);
}
.projects {
	width: 100px;
}
.actions {
	width: 32px;
}
