.margin-bottom {
	margin-bottom: 16px !important;
}

.documents {
	width: 400px;
	overflow-y: auto;
	border-right: 1px solid var(--color-light-grey-3);
}

.documents__header {
	display: flex;
	margin: 16px;
}

.documents__header .documents__header-title {
	align-self: center;
	text-align: center;
	width: 100%;
	font-weight: 500;
}

.documents__header .documents__header-add:hover {
	color: var(--color-white);
	background-color: var(--color-blue);
}

.documents__search {
	padding: 8px 16px;
}

.documents__filters {
	margin: 4px 2px;
}

.documents__list {
	width: 100%;
}

.documents__list .documents__list-row {
	font-size: var(--font-size-xs);
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--color-light-grey-3);
	border-bottom-width: thin;
}

.documents__list .documents__list-row:hover {
	background-color: var(--color-light-grey-3);
	color: var(--color-white);
}

.documents__list-header {
	font-size: var(--font-size-xs);
	padding-top: 8px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.documentList__emptyState {
	display: flex;
	height: calc(100% - 140px);
	align-items: center;
	justify-content: center;
}
