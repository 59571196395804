.buttonWrapper[data-active="true"] {
	display: inline-block;
	width: 25px !important;
}
.button {
	width: 25px !important;
	height: 25px !important;
	transition: background-color 250ms ease-in-out;
}
.button--not-clickable {
	cursor: default !important;
}
.button > * > svg {
	font-size: var(--font-size-md) !important;
	transition: font-size 250ms ease-in-out, opacity 250ms ease-in-out;
}
.button:hover > * > svg {
	font-size: var(--font-size-xl) !important;
	opacity: 1 !important;
	transition: font-size 250ms ease-in-out, opacity 250ms ease-in-out;
}
.button[data-selectedexist="true"][data-active="false"] > * > svg {
	opacity: 0.3;
	transition: opacity 250ms ease-in-out;
}
.button[data-active="true"][data-positionstatus="YES"] {
	background-color: var(--color-green-30);
	transition: background-color 250ms ease-in-out;
}
.button[data-active="true"][data-positionstatus="PENDING"] {
	background-color: var(--color-orange-30);
	transition: background-color 250ms ease-in-out;
}
.button[data-active="true"][data-positionstatus="NO"] {
	background-color: var(--color-red-30);
	transition: background-color 250ms ease-in-out;
}
.button[data-active="true"][data-positionstatus="NOT_ME"] {
	background-color: var(--color-black-30);
	transition: background-color 250ms ease-in-out;
}
.button[data-active="true"] > * > svg {
	font-size: var(--font-size-lg) !important;
	transition: font-size 250ms ease-in-out;
}
