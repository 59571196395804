.cell {
	padding: 32px 16px;
	text-align: center;
}
.cell__emptyState {
	padding: 120px;
	text-align: center;
}
.emptyState {
	composes: font-sm-medium from global;
}
