.main {
	background-color: var(--shades-grey-100);
	height: 100%;
	display: flex;
	flex-direction: column;
}
.tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	width: calc(100% - 4px);
	width: 100%;
	border-bottom: 2px solid var(--color-light-grey-4);
	box-shadow: none;
	composes: font-sm-medium from global;
}
.tabs > div {
	width: 100%;
	background-color: var(--shades-grey-100) !important;
	margin: 0px !important;
	padding: 0px !important;
}
.tabs > div > div > div {
	display: flex;
	align-items: center;
	justify-content: center;
}
.container__filters {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-2xs);
}
.container__filters > * {
	min-width: 150px;
	flex-grow: 1;
	background-color: var(--color-white);
}
.container__filters > *:first-child {
	margin-right: var(--spacing-xs) !important;
}
.filter__textField {
	height: 32px;
	composes: font-sm-regular from global !important;
}
.infiniteList {
	margin-top: 0px !important;
}
.InfiniteList__textField {
	composes: font-sm-regular from global !important;
}
.infiniteList input {
	height: 100%;
	font-size: var(--font-size-sm);
}
.infiniteList > *:first-child {
	margin: 0px var(--spacing-2xs) !important;
}
.listContainer {
	height: calc(100% - 150px) !important;
}
.scrollContainer {
	height: 100% !important;
}
