.form {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	column-gap: 0px;
	height: 400px;
}
.user {
	width: 100%;
	border: 1px solid var(--color-light-grey-3);
	padding-left: 8px;
	padding-right: 8px;
	border-radius: 10px;
}
.user__container {
	grid-column: 1/6;
	grid-row: 1;
	white-space: normal;
	text-align: justify;
	overflow-y: auto;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 10px;
}
.selectedContainer {
	grid-column: 6/11;
	grid-row: 1;
	white-space: normal;
	text-align: justify;
	overflow-y: auto;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 10px;
}
.role {
	padding: 0px;
	border-radius: 5px;
	border: 1px solid var(--color-light-grey-3);
	margin-bottom: 5px;
	font-size: var(--font-size-xs);
	text-align: center;
	width: 100%;
}
.formControl {
	margin: 8px;
	width: 90%;
}
.dateField {
	margin-left: 8px;
	margin-right: 8px;
}
.chips {
	display: flex;
	flex-wrap: wrap;
}
.chip {
	margin: 2px;
}
.noLabel {
	margin-top: 24px;
}
.text {
	text-align: center;
	font-size: var(--font-size-xl);
}
.textError {
	padding: 2px 32px 2px 32px;
	color: var(--color-red);
	text-align: justify;
}
.title {
	padding: 0;
}
.dialog__role {
	padding-left: 120px;
	padding-right: 120px;
	text-align: center;
}
.roleBtn:hover {
	background-color: transparent !important;
}
