.drawer {
	width: 100%;
}
.tabs > * {
	padding: 0;
}
.textField {
	background-color: var(--color-white);
}
.textField > * > *:nth-child(3) {
	border: 1px solid var(--shades-grey-300);
}
.infiniteSelector {
	padding: 10.5px 12px;
}
.infiniteSelector__list {
	max-height: 400px;
}
.newSearchButton {
	white-space: nowrap;
	margin: 4px;
}
.searchFieldsContainer {
	display: flex;
	flex-wrap: wrap;
}
.searchFieldsContainer > * {
	margin: 4px;
	flex-grow: 1;
	font-size: var(--font-size-sm);
	font-weight: 400;
	height: 32px;
}
