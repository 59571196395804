.content__radios {
	padding: 16px 0;
}
.radios__label {
	composes: font-md-regular from global;
	line-height: 1.5;
	letter-spacing: 0.15px;
	color: var(--color-black);
}
.content__inputs {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 8px 0;
}
