.projectReqReview {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0 8px;
	width: 100%;
	height: 100%;
}
.header {
	composes: font-sm-regular from global;
	padding: 8px;
}
.figuresContainer {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	padding-top: 10px;
}
.figure {
	width: 100%;
	background-color: var(--color-white);
	color: var(--color-dark-blue);
	padding: 8px 0;
	text-align: center;
	display: flex;
	flex-direction: column;
}
.figure__number {
	color: var(--color-dark-blue);
	font-size: var(--font-size-xl);
}
.figure__label {
	color: var(--color-dark-grey-1);
	composes: font-xs-regular from global;
}
.tooltip {
	box-shadow: 0px 5px 20px 0.3px var(--shadow-5);
	font-size: var(--font-size-sm);
}
.manageRightsBtn {
	margin: 6px auto;
}
