.row {
	display: flex;
}
.row__checkbox {
	padding: 0px !important;
	margin-right: var(--spacing-xs);
}
.row__content {
	width: calc(100% - 100px);
}
.row__documentName {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	composes: font-sm-regular from global;
}
.row__documentRef {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	composes: font-xs-regular from global;
	color: var(--color-light-grey-1);
}
.row__counters {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-1);
}
