.container {
	display: flex;
	flex-direction: column;
	margin: 16px 64px;
	padding: 16px;
	background-color: var(--color-white);
	border: 1px solid var(--shades-grey-300);
	border-radius: 5px;
	overflow: visible;
}
