.infoIcon {
	font-size: var(--font-size-xs) !important;
	padding-right: 7px;
}
.panel__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: auto;
	min-height: 64px;
	max-width: 100%;
	background-color: var(--color-white);
	border-bottom: 1px solid var(--shades-grey-300);
	padding: 0 16px;
}
.header__icon {
	padding-right: 8px;
}
.header__icon--large {
	font-size: var(--font-size-4xl);
}
.header__text {
	composes: font-md-bold from global;
	letter-spacing: 0.43px;
	color: var(--color-black);
	margin-right: 4px;
	text-align: left;
	display: flex;
	align-items: center;
}
.mainContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.mainContainer__header {
	display: flex;
	align-items: center;
}
.secondaryContainer {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-1);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	overflow: hidden;
}
.projectName {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: calc(100% - 47px);
}
.verticalDivider {
	width: 1px;
	height: 16px;
	margin: 0 8px;
}
