/* roboto-300 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local(""),
		url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 300;
	src: local(""),
		url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local(""),
		url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src: local(""),
		url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 500;
	src: local(""),
		url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local(""),
		url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local(""),
		url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 700;
	src: local(""),
		url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url("../resources/fonts/roboto/roboto-v27-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
