.filter__container {
	margin-top: 35px;
}
.filter__title {
	font-size: var(--font-size-md);
	text-align: center;
}

.filter__search_bar {
	margin: 16px;
}

.element__row {
	display: flex;
}

.element__row:hover {
	background-color: var(--color-light-grey-3);
	color: var(--color-white);
}

.element__row .element__text {
	align-self: center;
	padding: 0px 16px;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.element__row .element__button {
	padding-right: 8px;
}

.element__row:hover .element__icon {
	color: var(--color-white) !important;
}
