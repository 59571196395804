.main {
	flex: 3;
	padding: var(--spacing-s);
	padding-right: 0;
}
.row {
	composes: font-sm-regular from global;
	width: 50%;
	padding: 0px var(--spacing-xs) 0px !important;
}

.content {
	height: calc(100% - 24px);
	width: 100%;
	overflow-y: auto;
}

.noRowsContainer {
	width: 720px;
	height: 161px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.emptyState__wrapper {
	margin: auto;
	background-color: var(--color-white);
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.emptyState__header {
	font-size: var(--font-size-sm);
	font-weight: 600;
	text-align: center;
	margin-bottom: 8px;
}
.emptyState__body {
	font-size: var(--font-size-xs);
	font-weight: 500;
	overflow: auto;
	text-align: center;
}

.emptyState__footer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 16px;
	text-align: center;
}
.emptyState__footer > *:first-child {
	margin-right: 10px;
}
