.side-panel__content {
	margin: var(--spacing-m);
	border-radius: 4px;
	padding: var(--spacing-m);
	box-shadow: 0 0 0 1px var(--shadow-1);
	background-color: var(--color-white);
	display: flex;
	flex-direction: column;
}
.side-panel__header {
	composes: font-xl-medium from global;
	margin-bottom: var(--spacing-l);
}

.timeline {
	width: 100%;
	padding: 0 !important;
}
