.sidePanelContainer {
	margin: 16px 16px;
}
.header {
	display: flex;
	flex-wrap: wrap;
}
.btnContainer__btnSecondary {
	margin-right: 10px;
}
.title {
	composes: font-md-bold from global;
}
.optionsContainer {
	margin-top: var(--spacing-xs);
	max-height: calc(100vh - 246px);
}
.btnContainer {
	margin-left: auto;
	display: flex;
	display: inline-block;
}
