.dialog__input {
	margin: 10px 0;
}
.dialog__input--first {
	margin-bottom: 25px;
}
.switch {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
