.result {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: var(--spacing-s);
	height: 100%;
	overflow: auto;
}
.result__loader {
	align-items: center;
}
.result__results {
	display: flex;
	flex: 0 0 50px;
	align-items: center;
	gap: 10px;
	width: 100%;
	padding-left: 8px;
}
.results__count {
	composes: font-sm-medium from global;
	color: var(--color-black);
}
.results__spacer {
	flex-grow: 1;
}
.results--margin {
	margin-left: 4px;
}
.results--text {
	composes: font-xs-bold from global;
	text-transform: uppercase;
	color: var(--color-light-grey-2);
}
.results__sort {
	composes: font-sm-regular from global;
	color: var(--color-black);
	line-height: 1.71;
	letter-spacing: 0.4px;
	gap: var(--spacing-xs);
	display: inline-flex;
	align-items: center;
}
.result__row {
	display: flex;
	width: 100%;
	align-items: flex-start;
	padding: 8px;
}
.results__listWrapper {
	width: 100%;
	height: calc(100vh - 311px);
}
.infiniteSelector {
	composes: font-sm-regular from global;
	padding: 10.5px 12px;
}
.infiniteSelector__list {
	max-height: 400px;
}
.projectSelector {
	margin-left: 16px;
}
.startFiltering {
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: relative;
}
