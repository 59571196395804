.container {
	display: flex;
	margin-bottom: var(--spacing-s);
}
.conversation__icon {
	height: 30px;
	width: 30px;
	margin-right: var(--spacing-xs);
	composes: font-lg-medium from global;
	background-color: var(--color-dark-blue);
}
.content__container {
	border: 1px solid var(--color-light-grey-3);
	border-radius: 4px;
	padding: var(--spacing-xs);
	width: 100%;
}
.btn__container {
	display: flex;
	justify-content: space-between;
	margin-top: var(--spacing-xs);
}
.user__icon {
	line-height: 30px;
	height: 30px;
	width: 30px;
	composes: font-sm-medium from global !important;
	margin-right: var(--spacing-xs);
	background-color: var(--color-light-grey-3);
	color: var(--color-black);
}
.copyButton {
	display: flex;
	justify-content: flex-end;
	padding-top: 8px;
}
.response_actions {
	display: flex;
	justify-content: space-between;
}
.loading_answer {
	display: flex;
	align-items: center;
	justify-content: center;
}
