.formWrapper {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	column-gap: 0px;
	height: 400px;
}
.userContainer {
	grid-column: 1/6;
	grid-row: 1;
	white-space: normal;
	text-align: justify;
	overflow-y: auto;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 10px;
}
.selectedContainer {
	grid-column: 6/11;
	grid-row: 1;
	white-space: normal;
	text-align: justify;
	overflow-y: auto;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 10px;
}
.user {
	width: 100%;
	border: 1px solid var(--color-light-grey-3);
	padding-left: 8px;
	padding-right: 8px;
	border-radius: 10px;
}
.userIcon {
	margin-right: 10px;
}

.formControl {
	margin: 8px;
	width: 90%;
}
.dateField {
	margin-left: 8px;
	margin-right: 8px;
}
.chips {
	display: flex;
	flex-wrap: wrap;
}
.chip {
	margin: 2px;
}
.noLabel {
	margin-top: 24px;
}
.text {
	padding: 8px 32px 8px 32px;
}
.textError {
	padding: 8px 32px 8px 32px;
	color: var(--color-red);
}
