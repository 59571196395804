.buttonContainer {
	margin-top: -4px;
}
.button {
	width: 27px !important;
}
.button[data-checked="true"] * {
	height: 20px !important;
	width: 20px !important;
}
.button[data-other-checked="true"] * {
	transition: 250ms;
	opacity: 0.6;
}
.button:hover * {
	height: 20px !important;
	width: 20px !important;
	opacity: 1;
	transition: 250ms;
}
.button--accepted[data-checked="true"] {
	transition: 250ms;
	height: 26px !important;
	width: 26px !important;
	background-color: var(--color-green-20);
}
.button--pending[data-checked="true"] {
	transition: 250ms;
	height: 27px !important;
	width: 27px !important;
	background-color: var(--color-orange-20);
}
.button--denied[data-checked="true"] {
	transition: 250ms;
	height: 27px !important;
	width: 27px !important;
	background-color: var(--color-red-20);
}
.button--notMe[data-checked="true"][data-is-current="true"] {
	transition: 250ms;
	height: 27px !important;
	width: 27px !important;
	background-color: var(--shadow-1);
}
.button--notMe[data-checked="true"][data-is-current="false"] {
	transition: 250ms;
	color: var(--color-light-grey-1) !important;
	height: 16px !important;
	width: 16px !important;
}
.button--accepted:disabled {
	background-color: var(--color-green-20);
}
.button--accepted:disabled > * > svg {
	color: var(--color-green) !important;
}
.button--pending:disabled {
	background-color: var(--color-orange-20);
}
.button--pending:disabled > * > svg {
	color: var(--color-orange) !important;
}
.button--denied:disabled {
	background-color: var(--color-red-20);
}
.button--denied:disabled > * > svg {
	color: var(--color-red) !important;
}
.button--notMe:disabled > * > svg {
	color: var(--color-dark-grey-2) !important;
}
.icon {
	transition: 250ms;
	height: 16px !important;
	width: 16px !important;
}
.validationIcon {
	float: left;
	font-weight: bold;
}
.icon__font--white {
	color: var(--color-white);
}
.statusIcon {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	padding: 4px;
}
.statusIcon[data-type="NOT_ME"] {
	background-color: var(--status-not-me-20);
}
.statusIcon[data-type="YES"] {
	background-color: var(--status-yes-20) !important;
}
.statusIcon[data-type="NO"] {
	background-color: var(--status-no-20);
}
.statusIcon[data-type="PENDING"] {
	background-color: var(--status-pending-20) !important;
}
