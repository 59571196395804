.main-container {
	display: flex;
	flex-direction: row;
	padding: 32px;
	height: 100%;
}
.main-container > *:nth-child(1) {
	margin-right: 32px;
}
.main-container > *:last-child {
	margin-left: 32px;
}
.container__left-panel {
	overflow: auto;
	display: flex;
	flex-direction: column;
	width: 35%;
}
.step-title {
	composes: font-md-bold from global;
	margin-bottom: 32px;
}
