.container {
	display: flex;
	position: relative;
	height: 100%;
	overflow: hidden;
}

.left-panel {
	width: 40%;
	overflow-y: hidden;
	overflow-x: hidden;
}

.right-panel {
	width: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
}

.redress-header {
	background-color: var(--color-light-grey-3);
	color: var(--color-black);
	font-size: var(--font-size-sm);
	font-weight: bold;
	display: flex;
	justify-content: center;
}
.collapse-button {
	min-width: 0;
	padding: 0 !important;
	background-color: var(--color-light-grey-3);
	border-radius: 0;
	width: 20px !important;
}

.StyledTabs__root {
	border-bottom: 1px solid var(--color-blue);
}
.StyledTabs__indicator {
	background-color: var(--color-blue);
}
