.container {
	display: flex;
	flex-direction: row;
}
.container > *:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.container > *:last-child {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.progressSlice {
	display: flex;
	justify-content: center;
	color: white;
	composes: font-md-regular from global;
}
