.searchCardSelectionList > header {
	position: sticky;
	top: 0;
}
.searchCardSelectionList > div {
	overflow-y: auto;
}
.searchListContainer {
	height: calc(35vh - 64px);
}
