.name {
	display: flex;
	width: 100%;
	align-items: center;
	padding: 0 var(--spacing-s);
	margin-bottom: var(--spacing-s);
}
.name > div:first-of-type {
	width: 60%;
	margin-right: var(--spacing-s);
}
