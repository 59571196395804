.container {
	width: 100%;
	height: 100%;
	min-height: 96px;
	overflow: auto;
	padding: 0 var(--spacing-s);
}
.infoIcon {
	color: var(--color-blue);
	margin-right: var(--spacing-2xs);
}
.infoBox {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--tints-blue-10);
	border-radius: 4px;
	padding: var(--spacing-xs);
	margin-bottom: var(--spacing-xs);
}
.empty {
	composes: font-md-regular from global;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: var(--spacing-xs);
}
