.container {
	width: 100%;
	overflow-x: hidden;
}
.task__filters {
	background-color: var(--color-white);
	display: flex;
	flex-direction: row;
	padding: 4px 4px 4px var(--view-padding-lateral) !important;
	width: 100%;
	border-bottom: 1px solid var(--color-light-grey-3);
	position: sticky;
	top: 0;
	z-index: 1;
	margin-left: 8px;
}
.task__filtersWrapper {
	width: 100%;
}

.task__container {
	display: flex;
	position: relative;
	flex-direction: column;
}
.row__firstCell {
	padding-left: var(--view-padding-lateral);
}
.table__iconHead {
	width: 32px;
	display: flex;
}
