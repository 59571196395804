.btn {
	margin-bottom: 8px;
}
.btn--selected {
	background-color: var(--shades-blue-100) !important;
}
.btn--selected:hover {
	background-color: var(--color-light-grey-4) !important;
}
.btn__text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
