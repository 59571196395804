.loader {
	position: absolute;
	top: 10%;
	text-align: center;
	width: 100%;
}
.qa-list {
	height: 100%;
	width: 100%;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
}
.qa-list__counter {
	background: var(--color-white);
	font-size: var(--font-size-xs);
	font-weight: bold;
	font-style: italic;
	border-bottom: solid 2px var(--shades-grey-300);
}
.qa-list__infinite-list {
	width: initial !important;
	overflow-y: auto !important;
	height: 100% !important;
}
.infinite-list-container {
	height: 100% !important;
	overflow-y: auto;
}

.qa-list__infinite-list > * {
	height: 100% !important;
	overflow: visible !important;
}
