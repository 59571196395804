.additionalSidePanel__container {
	position: absolute;
	top: 0px;
	right: -14px;
	width: 90%;
	height: 100%;
	background-color: var(--shades-grey-100);
	z-index: 1000;
	box-shadow: -1px 0px 0px 0px var(--color-light-grey-3), -15px 0px 20px 0px var(--color-black-10);
}
