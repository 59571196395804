.dialog {
	z-index: 900;
	bottom: var(--spacing-l) !important;
	right: 5% !important;
	top: unset;
	left: unset;
}
.dialog__container {
	align-items: flex-end;
	height: fit-content;
}
.dialog__paper {
	margin: 0;
	overflow-y: hidden;
	box-shadow: 0 1px 14px 0 var(--shadow-1), 0 5px 8px 0 var(--shadow-1), 0 3px 5px -1px var(--shadow-2);
}
.dialog__content {
	width: 304px !important;
	max-height: 342px !important;
	padding: 0 !important;
	border-radius: 4px !important;
	background-color: var(--color-white);
}
.content__reduced {
	height: 32px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	grid-gap: var(--spacing-xs);
	gap: var(--spacing-xs);
	padding: var(--spacing-s);
	background-color: var(--shades-grey-700);
	align-content: center;
}
.reduced__text {
	composes: font-sm-regular from global;
	color: var(--color-white);
	display: inline-flex;
	align-content: center;
	justify-content: center;
	align-items: center;
}
.content__import {
	height: 32px;
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: var(--spacing-xs);
	padding: var(--spacing-xs);
	background-color: var(--shades-grey-700);
}
.import__text {
	composes: font-sm-regular from global;
	color: var(--color-white);
}
.import__btns {
	display: inline-flex;
	flex-grow: 1;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
}
.import__btns > * {
	margin-left: var(--spacing-xs);
}
.import__btn {
	width: 20px !important;
	height: 20px !important;
}
.import__btn:disabled {
	background-color: var(--shades-grey-700) !important;
}
.content__files {
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	overflow: auto;
	max-height: 308px;
}
.content__loader {
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	padding: var(--spacing-s) var(--spacing-m) var(--spacing-m) var(--spacing-m);
	height: 308px;
}
.loader__text {
	composes: font-xs-regular from global;
	color: var(--color-black);
}
.import__header {
	composes: font-sm-regular from global;
	height: 32px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 5px 8px;
	color: var(--tints-blue);
}
