.main {
	background-color: var(--color-white);
	display: flex;
	flex-direction: column;
	padding: var(--spacing-xs) 0;
	width: 100%;
	height: 100%;
}
.toggleGroup {
	width: 100%;
	margin-bottom: var(--spacing-xs);
}
.toggleGroup__btn {
	height: 26px;
	text-transform: none;
	composes: font-xs-medium from global;
}
.toggleGroup__btn > span:first-of-type > svg {
	margin-right: var(--spacing-xs);
}
.loaderContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: var(--spacing-xs) 0;
}
