.document {
	display: flex;
	align-items: center;
	background-color: var(--color-light-grey-4);
	padding: 8px 8px;
	margin: 4px;
	left: 36px !important;
}
.document__handle {
	display: inline-flex;
	align-items: center;
	margin-right: 8px;
}
.documentInformation {
	display: flex;
	align-items: center;
	width: calc(100% - 36px);
	overflow: hidden;
}
.documentName {
	display: inline-block;
	height: fit-content;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.documentName[data-automatically-classified="true"] {
	max-width: calc(100% - 200px);
}
.docRowInformationContainer {
	width: calc(100% - 24px);
	display: inline-flex;
	justify-content: space-between;
}
.autoClassified__helpertext {
	composes: font-md-regular from global;
	white-space: nowrap;
	color: var(--color-dark-grey-1);
	margin-left: 16px;
}
