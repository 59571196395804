.right-panel {
	width: 25%;
	border-right: 1px solid var(--color-light-grey-3);
	overflow-y: auto;
	overflow-x: hidden;
}

.left-panel {
	width: 25%;
	border-left: 1px solid var(--color-light-grey-3);
	overflow-y: auto;
	overflow-x: hidden;
}

.middle-panel {
	width: 50%;
	border-right: 1px solid var(--color-light-grey-3);
	border-left: 1px solid var(--color-light-grey-3);
	overflow-y: auto;
	overflow-x: hidden;
}
