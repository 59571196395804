.container {
	padding: 16px;
}
.templateBox {
	display: flex;
	flex-direction: row;
}
.templateBox__checkboxContainer {
	width: 48px;
}
.templateBox__contentContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.contentContainer__header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
	min-height: 61px;
}
.header__leftItems {
	display: flex;
	flex-direction: column;
}
.leftItems__title {
	text-align: left;
	margin-bottom: 8px;
	composes: font-md-bold from global;
}
.leftItems__subtitleContainer {
	display: flex;
	flex-direction: row;
}
.subTitleContainer__username {
	composes: font-sm-regular from global;
}
.subTitleContainer__elapsed {
	composes: font-sm-regular from global;
	color: var(--shades-grey-500);
}
.header__rightItems {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	min-width: 40%;
}
.header__rightItems > *:not(:last-child) {
	margin-right: 8px;
}
.contentContainer__body {
	text-align: left;
	composes: font-md-regular from global;
}
.rightItems__results {
	composes: font-md-regular from global;
	color: var(--shades-grey-500);
}
