.section {
	margin-bottom: 16px;
}

.section .section__title {
	font-weight: bold;
	font-size: var(--font-size-sm);
	text-transform: uppercase;
	display: flex;
	align-items: center;
}

.section__title > span:first-of-type {
	margin-right: auto;
}

.section__title > span:last-of-type {
	margin-right: var(--spacing-s);
}

.section .section__content {
	margin: 0 18px;
}
