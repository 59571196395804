.input__select {
	padding: 5px 12px 4px;
}
.select__placeHolder {
	composes: font-sm-regular from global;
	line-height: 1rem;
	display: inline-block;
	padding: 0 4px;
	height: 20px;
	background-color: var(--color-white);
}
.selector__infiniteList {
	composes: font-sm-regular from global;
	max-height: 150px;
}
