.main {
	height: 100%;
}

.container {
	border-radius: 4px;
	border: 1px solid var(--color-light-grey-4);
	background-color: #fff;
	height: 100%;
	padding: var(--spacing-xs);
}
.emptyState {
	box-shadow: none;
	border-radius: var(--spacing-2xs);
	padding: var(--spacing-s) var(--spacing-xs);
	background-color: var(--color-white);
	border: 1px solid var(--color-light-grey-4);
	composes: font-xs-regular from global;
}
