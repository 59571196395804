.role-btn {
	color: var(--color-black) !important;
	border-radius: 0px !important;
	border-bottom: 2px solid transparent;
}
.role-btn--selected {
	cursor: default !important;
	color: var(--color-blue) !important;
	border-bottom-color: var(--color-blue);
}
.dropdown {
	background: var(--color-white);
	z-index: 1100;
	max-width: 500px;
	max-height: 500px;
	box-shadow: 0 2px 4px var(--shadow-04);
	border-radius: var(--spacing-xs);
	overflow: hidden;
	display: flex;
}
.dropdown__content {
	position: relative;
	overflow: auto;
	display: flex;
	flex-direction: column;
	width: 100%;
}
.roleDisplay {
	display: flex;
	align-items: center;
}
.roleDisplay > svg {
	margin-left: var(--spacing-xs);
}
.roleContent {
	justify-content: flex-start;
}
.roleContent[data-active="true"] {
	color: var(--color-blue) !important;
}
.wrapper {
	width: 100%;
}
.wrapper > button > span:first-of-type {
	margin-left: var(--spacing-xs);
}
.wrapper > button > span:last-of-type {
	margin-right: var(--spacing-s);
}
.wrapper > button:hover {
	background-color: var(--shades-grey-100) !important;
}
