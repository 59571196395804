.sectionHeader {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 0px;
	border-top: 1px solid var(--color-light-grey-3);
	border-bottom: 1px solid var(--color-light-grey-3);
	height: fit-content;
}
.sectionHeader:not(:last-of-type) {
	border-bottom: 0px !important;
}
.sectionHeader > span {
	text-transform: none !important;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.sectionHeader > div {
	height: fit-content;
	overflow: auto;
}
.title {
	color: var(--color-light-grey-1);
	margin-bottom: var(--spacing-2xs);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.content {
	white-space: brek-word;
}
.paragraphContainer {
	margin-bottom: var(--spacing-xs);
}
