.panelContainer > * {
	box-shadow: none;
	border-radius: 4px;
}
.panelContainer > * :before {
	background-color: var(--color-white);
}
.rightIcon {
	color: var(--color-blue);
	transition: none;
	font-size: var(--font-size-2xl) !important;
}
.rightIcon--translucent {
	opacity: 0;
	transition: opacity 1s;
}
.emptyState {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.emptyStateIcon {
	margin-right: 8px;
	font-size: var(--font-size-2xl) !important;
}
.selectAll {
	margin-top: 10px;
	margin-left: 23px;
}
.container {
	composes: font-sm-regular from global;
	width: 100%;
	height: calc(100% - 31px);
	display: flex;
	flex-direction: column;
	align-items: center;
}
.list {
	overflow: auto;
}
.container > * {
	width: 100%;
	max-width: 800px;
}
.inputProps {
	composes: font-sm-regular from global;
	height: 32px;
}
