.menuItem--selected {
	font-size: var(--font-size-sm);
	color: var(--color-blue);
	cursor: default;
}
.menuItem--selected:hover {
	background-color: var(--color-white);
}
.menuItem {
	font-size: var(--font-size-sm);
	white-space: pre-wrap;
	overflow-wrap: break-word;
	max-width: 216px;
}
