.title {
	composes: font-xs-medium from global;
	color: var(--color-light-grey-1);
	margin: 16px 8px 8px 8px;
	display: flex;
	align-items: center;
}
.title > svg {
	margin-left: auto;
	color: var(--color-light-grey-1);
}
.container {
	width: 100%;
	height: calc(100% - 90px);
}
.container[data-closed="true"] {
	display: none;
}

.scrollcontainer {
	overflow: auto;
	height: 100%;
}
