.container {
	background-color: var(--shades-grey-100);
	height: calc(100% + 16px);
	padding-top: var(--spacing-xs);
}
.options {
	background-color: var(--shades-grey-100);
	border-style: none !important;
	width: auto;
	padding: 0;
	margin: 0 var(--spacing-xs) 0 0;
}
.emptyState {
	box-shadow: none;
	border-radius: var(--spacing-2xs);
	color: var(--color-black);
	margin: 0 var(--spacing-xs);
	padding: var(--spacing-s);
	background-color: var(--color-white);
	border: 1px solid var(--color-light-grey-4);
	composes: font-xs-regular from global;
}
.loading {
	text-align: center;
	margin-top: 16px;
}
.rows {
	margin: 0 var(--spacing-xs);
	height: calc(100% - 48px);
	overflow-y: auto;
}
