.accordion__root::before {
	display: none;
}
.accordion__root {
	box-shadow: none !important;
	position: inherit;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	border-radius: 0 !important;
	width: 100%;
}
.accordionSummary__content {
	width: 100%;
	margin: 0 !important;
	display: flex;
	justify-content: flex-start;
	font-size: var(--font-size-sm);
	align-items: center;
	padding: 0 0 0 17px;
	min-height: 0;
	height: 32px;
}
.accordionSummary__content--disabled {
	color: var(--color-light-grey-1);
	cursor: default !important;
}
.accordionSummary__root {
	margin: 0;
	padding: 0;
	min-height: 0 !important;
	transition-duration: 150ms, 150ms !important;
}
.expandIcon {
	width: 18px;
	margin-left: -18px;
}
.checkBox {
	padding: 6px;
}
.documentName {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.documentName--greyed {
	color: var(--shades-grey-400);
}
.documentName--bold {
	composes: font-sm-medium from global;
}
.accordionDetails__root {
	flex-direction: column;
	padding: 0;
}
.row__text {
	display: flex;
	align-items: center;
	width: 100%;
}
.emptyTooltip {
	margin-top: 0px !important;
}
.row__loader {
	margin-left: -17px;
}
