.versionIcon {
	border: none !important;
}
.content {
	max-width: 500px;
	max-height: 500px;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	overflow-y: auto;
	text-align: justify;
}
.modal__actions {
	display: flex;
	justify-content: space-between;
}
.modal__actions > div {
	display: flex;
	align-items: center;
}
.icon {
	padding: 0;
	font-size: var(--font-size-2xl);
	color: var(--color-blue);
}
.negative__label--underline {
	text-decoration: underline;
}
.negative__label--clickable {
	cursor: pointer;
}
.negative__label--margin-right {
	margin-right: 16px;
}
.subTitle {
	font-weight: bold;
	margin: 16px 0 8px 0;
}
.modal__notNewContent {
	composes: font-sm-bold from global;
	margin-top: 32px;
}
