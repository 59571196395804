.box {
	width: 100%;
	margin-bottom: var(--spacing-s);
}
.box > div:first-of-type {
	display: flex;
	align-items: center;
	margin-left: var(--spacing-m);
}
.title {
	composes: font-sm-bold from global;
}
.box > div:nth-of-type(2) {
	margin-left: 66px;
}
