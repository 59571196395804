.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 4px 8px;
	padding: 16px;
}
.result {
	overflow-wrap: break-word;
	margin-bottom: 8px;
	composes: font-sm-regular from global;
}
.container__line {
	width: 100%;
	align-self: flex-end;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	composes: font-xs-regular from global;
}
.highlighted {
	background-color: var(--color-yellow);
}
.line__leftItems {
	color: var(--color-light-grey-1);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: calc(70% - 8px);
	margin-right: 8px;
}
.line__rightItems {
	min-width: calc(30% - 8px);
	max-width: calc(30% - 8px);
	display: flex;
	justify-content: flex-end;
	align-items: center;
	white-space: nowrap;
	color: var(--color-light-grey-1);
	margin: 0 8px;
}
