.panelHeader {
	display: flex;
	flex-direction: column;
	padding: 8px;
}
.panelHeader__row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 8px;
}
.panelHeader__row:last-child {
	padding-bottom: 0;
}
.panelHeader__searchBtn {
	padding: 0;
}
