.headerCell {
	background-color: var(--color-white);
	padding: 10px;
	font-size: var(--font-size-sm);
}
.tableRow--selected {
	background-color: var(--color-light-grey-4);
}
.cell__name--bold {
	font-weight: bold;
}
.cell__name {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 16px !important;
}
.tableRow > * {
	min-height: 64px;
	padding: 0;
}
