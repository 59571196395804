.container {
	padding: var(--spacing-xs) var(--spacing-xs);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.header {
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--spacing-xs);
	white-space: nowrap;
}
.header__leftItems {
	overflow: hidden;
}
.header__rightItems,
.header__leftItems {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.header__rightItems > *:first-child,
.header__leftItems > *:first-child {
	margin-right: 8px;
}
.leftItems__primaryName {
	composes: font-xs-medium from global;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.leftItems__secondaryName {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-1);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-left: 4px;
}
.date {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-1);
}
.date[data-ishovered="false"] {
	animation: dateAppear 300ms ease-in-out;
	animation-fill-mode: forwards;
}
.date[data-ishovered="true"] {
	animation: dateDisappear 250ms ease-in-out;
	animation-fill-mode: forwards;
}
.body {
	height: auto;
}
.comment {
	composes: font-xs-regular from global;
}
.commentLine {
	margin: 2px 0 !important;
}
.highlighted {
	color: var(--color-dark-blue);
	text-decoration: underline;
}
.editContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: var(--spacing-2xs);
	height: 24px;
}
.helperTextClassName {
	width: calc(100% - 38px);
	height: 16px;
	margin-bottom: -12px;
	padding-top: 10px;
	text-align: right;
	font-size: 12px;
}
.line__rightItems {
	width: 100%;
	height: 50%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	white-space: nowrap;
	color: var(--color-light-grey-1);
}
.textField > div {
	margin-right: 0px;
}
.textField textarea {
	padding-left: var(--spacing-xs) !important;
}
.btnClassName {
	color: var(--color-dark-grey-2) !important;
	width: 24px !important;
	height: 24px !important;
}
.iconClassName {
	width: 12px !important;
	height: 12px !important;
}
.iconsContainer {
	height: 25px;
	text-align: right;
}
.iconsContainer[data-ishovered="true"][data-isstatusselected="true"] {
	animation: iconContainerExpand 250ms ease-in-out;
	animation-fill-mode: forwards;
}
.iconsContainer[data-ishovered="false"][data-isstatusselected="true"] {
	animation: iconContainerSqueeze 250ms ease-in-out;
	animation-fill-mode: forwards;
}
.iconsContainer > span {
	display: inline-block;
	overflow: hidden;
}
.iconsContainer[data-ishovered="true"][data-isstatusselected="true"] > span {
	animation: iconContainerExpand__icons 250ms ease-in-out;
	animation-fill-mode: forwards;
}
.iconsContainer[data-ishovered="false"][data-isstatusselected="true"] > span {
	animation: iconContainerSqueeze__icons 250ms ease-in-out;
	animation-fill-mode: forwards;
}
.button:hover {
	background-color: var(--color-light-grey-3);
}
@keyframes dateAppear {
	0% {
		opacity: 0;
		max-width: 0;
	}
	95% {
		opacity: 1;
	}
}
@keyframes dateDisappear {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		max-width: 0;
		overflow: hidden;
	}
}
@keyframes iconContainerSqueeze {
	from {
		width: 132px;
	}
	to {
		width: 32px;
	}
}
@keyframes iconContainerExpand {
	from {
		width: 32px;
	}
	to {
		width: 132px;
	}
}
@keyframes iconContainerExpand__icons {
	0% {
		width: 0px;
	}
	70% {
		width: 25px;
		margin: 0;
	}
	100% {
		margin: 0 4px;
		width: 25px;
	}
}
@keyframes iconContainerSqueeze__icons {
	0% {
		margin: 0 4px;
	}
	40% {
		margin: 0;
		width: 25px;
	}
	100% {
		width: 0px;
		margin: 0;
	}
}
