.splitBtn__btn {
	border-radius: 4px 0 0 4px !important;
	border: 1px solid var(--color-blue);
}
.splitBtn__btn:hover {
	background-color: var(--color-white) !important;
}
.splitBtn__btn[data-variant="contained"][data-color="primary"]:hover {
	background-color: var(--color-blue) !important;
}
.splitBtn__btn[data-variant="contained"][data-color="secondary"]:hover {
	background-color: var(--color-light-grey-4) !important;
}
.splitBtn__btn[data-variant="outlined"]:hover {
	background-color: var(--color-white) !important;
}
.splitBtn__iconBtn {
	height: 36px;
	border: 1px solid var(--color-blue);
	border-radius: 0 4px 4px 0 !important;
	border-left: 0px !important;
}
.splitBtn__iconBtn:focus {
	outline: none;
}
.splitBtn__iconBtn[data-color="primary"] {
	border-color: var(--color-blue);
}
.splitBtn__iconBtn[data-color="secondary"] {
	border-color: var(--color-light-grey-3);
}
.splitBtn__iconBtn[data-color="primary"][data-variant="outlined"] {
	border-color: var(--color-blue);
}
.splitBtn__iconBtn[data-color="secondary"][data-variant="outlined"] {
	border-color: var(--color-light-grey-3);
}
.splitBtn__iconBtn[data-color="primary"][data-variant="contained"] {
	border: 1px solid var(--color-primary-hover);
	background-color: var(--color-blue);
}
.splitBtn__iconBtn[data-color="secondary"][data-variant="contained"] {
	border: 1px solid var(--color-light-grey-3);
	background-color: var(--color-light-grey-4);
}
.splitBtn__btn[data-color="primary"][data-variant="contained"] {
	border: 1px solid var(--color-primary-hover) !important;
}
.splitBtn__btn[data-color="secondary"][data-variant="contained"] {
	border: 1px solid var(--color-light-grey-3) !important;
}
.splitBtn__iconBtn--active {
	background-color: var(--color-blue-10) !important;
}
.splitBtn__iconBtn--active[data-color="primary"][data-variant="outlined"] {
	background-color: var(--color-blue-10) !important;
}
.splitBtn__iconBtn--active[data-color="secondary"][data-variant="outlined"] {
	background-color: var(--color-light-grey-4) !important;
}
.splitBtn__iconBtn--active[data-color="primary"][data-variant="contained"] {
	background-color: var(--color-primary-hover) !important;
}
.splitBtn__iconBtn--active[data-color="secondary"][data-variant="contained"] {
	background-color: var(--color-light-grey-3) !important;
}
.splitBtn__iconBtn[data-size="sm"] * {
	font-size: 12px !important;
}
.splitBtn__iconBtn[data-size="md"] * {
	font-size: 12px !important;
}
.splitBtn__iconBtn[data-size="sm"] {
	height: 24px;
	width: 24px;
}
.splitBtn__iconBtn[data-size="md"] {
	height: 32px;
	width: 32px;
}
.splitBtn__iconBtn[data-size="lg"] {
	height: 40px;
	width: 40px;
}
.splitBtn__iconBtn[data-size="xl"] {
	height: 48px;
	width: 48px;
}
.splitBtn__option {
	flex-direction: column;
	align-items: flex-start;
}
.splitBtn__option--subLabel {
	composes: font-xs-regular from global;
	color: var(--color-dark-grey-1);
}
