.banner {
	min-height: var(--viewbanner-height);
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 8px;
	opacity: 1;
	box-shadow: var(--shades-grey-v2-25) 0px 1px 0px, var(--shades-grey-v2-10) 0px 4px 4px;
}
.banner__options {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.banner__opportunityBtn {
	color: var(--shades-grey-v2-70) !important;
	padding: 0 16px !important;
}
