.dropDownMenu {
	padding: 9px 10px !important;
	font-weight: 400;
	height: 48px !important;
	border-radius: 0;
	text-transform: none;
	border: 3px solid transparent;
}
.dropDownMenu__text--crop {
	max-width: 8vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
@media screen and (width < 1280px) {
	.dropDownMenu__text--crop {
		max-width: 3vw;
	}
	.dropDownMenu {
		padding: 9px 5px !important;
	}
}
.dropDownMenu--selected {
	border-bottom-color: var(--color-blue);
}
.dropDownMenu:checked {
	border: 0px !important;
}
.dropDownMenu:hover {
	color: var(--color-blue) !important;
}
.dropDown--contained:hover {
	color: var(--color-white) !important;
}
.dropDown--contained {
	background-color: var(--color-blue) !important;
	color: var(--color-white) !important;
	border-radius: 0px;
}
.dropDownMenu--bold {
	font-weight: bold;
}
.dropDownMenu__icon--open {
	color: var(--color-white) !important;
}
.dropDownMenu:hover .dropDownMenu__icon {
	color: var(--color-blue) !important;
}
.dropDown--contained:hover .dropDownMenu__icon {
	color: var(--color-white) !important;
}
.dropDownMenu__icon {
	margin-left: 5px;
}
