.container {
	padding-top: 16px;
	height: 100%;
}
.container > * {
	width: 100% !important;
}
.documentRow {
	display: flex;
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: var(--shades-grey-v2-30);
}
.header__content > b {
	display: block;
	margin-bottom: -6px;
}
.cell__header {
	composes: font-sm-medium from global;
	color: var(--color-dark-grey-3);
	padding: 16px 16px 0 16px;
}
.cell__header--subtext {
	font-weight: normal !important;
}
.cell {
	composes: font-sm-regular from global;
	color: var(--color-dark-grey-3);
	display: flex;
	align-items: center;
	padding: 16px;
}
.cell--30 {
	width: 30%;
}
.cell--70 {
	width: 70%;
}
.documentName {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.listContainer {
	overflow: auto;
}
.listContainer[data-ispopup="true"] {
	height: calc(50vh - 150px);
}
.listContainer[data-ispopup="false"] {
	height: calc(100% - 100px);
}
