.container {
	position: absolute;
	display: flex;
	overflow: hidden;
	justify-content: flex-end;
	top: 87px;
	width: 49px;
	background-color: var(--shades-grey-300);
	height: calc(100vh - 87px);
}
.container[data-position="right"] {
	direction: ltr;
	right: 0px;
}
.container[data-position="left"] {
	direction: rtl;
	left: 0px;
}
.container[data-open="true"] {
	width: calc(37vw - 114px);
	min-width: calc(-114px + 37vw);
	max-width: calc(-114px + 60vw);
}
.container[data-open="false"] {
	width: 49px;
	transition: width 0.3s;
}
.container__menus {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 49px;
}
.container__menuContent {
	height: 100%;
	width: 35vw;
	background-color: var(--color-white);
}
.button__badge {
	padding: 2px !important;
}
.button__badge span:last-child {
	background-color: var(--color-white) !important;
	color: var(--color-black);
	padding: 4px;
	font-size: 10px;
}
.resizer {
	border-right: 1px solid var(--color-light-grey-3);
	width: 4px;
	z-index: 100;
	position: absolute;
	top: 175px;
	height: calc(100vh - 175px);
}
.resizer[data-caution-banner-disabled="true"] {
	top: 135px;
	height: calc(100vh - 135px);
}
