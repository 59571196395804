.container[data-caution-banner-disabled="false"] {
	height: calc(100% - var(--viewbanner-height) - var(--navbar-height) - 161px);
}
.container {
	display: flex;
	flex-direction: row;
	height: calc(100% - var(--viewbanner-height) - var(--navbar-height) - 121px);
	background-color: var(--shades-grey-300);
}
.divider {
	width: calc(100% - 16px);
	margin: var(--spacing-xs);
}
.leftSide__container {
	margin-right: var(--spacing-xs);
	width: 70%;
	height: 100%;
	background-color: var(--color-white);
}
.rightSide__container {
	margin-left: var(--spacing-xs);
	width: 30%;
	height: 100%;
	overflow: auto;
	background-color: var(--color-white);
}
.noDeliverables__container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: var(--spacing-s);
}
