.wrapper {
	place-self: center;
}
.btn {
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: normal;
	cursor: pointer;
	color: var(--color-dark-grey-2);
	border-radius: 50%;
	border: transparent;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	background-color: inherit;
}
.btn[data-disabledhovering="false"] .btn:hover {
	background-color: var(--shades-blue-50);
}
.btn:disabled {
	cursor: default;
	pointer-events: none;
	border-color: var(--color-light-grey-2) !important;
	background-color: var(--color-white);
}
.btn__icon[disabled] {
	color: var(--color-light-grey-2) !important;
}
.btn[data-size="xs"] {
	width: 24px;
	height: 24px;
}
.btn[data-size="sm"] {
	width: 32px;
	height: 32px;
}
.btn[data-size="md"] {
	width: 40px;
	height: 40px;
}
.btn[data-size="lg"] {
	width: 48px;
	height: 48px;
}
.btn[data-color="secondary"] {
	color: var(--color-dark-grey-2);
	border-color: var(--color-dark-grey-2);
}
.btn[data-color="primary"] {
	color: var(--color-blue);
	border-color: var(--color-blue);
}
.btn[data-disabledhovering="false"] .btn[data-color="secondary"]:hover {
	background-color: var(--color-light-grey-4);
}
.btn[data-disabledhovering="false"] .btn[data-color="primary"]:hover {
	background-color: var(--shades-blue-50);
}
.btn[data-color="secondary"][data-variant="contained"] {
	background-color: var(--color-light-grey-4);
	color: var(--color-dark-grey-2);
}
.btn[data-color="primary"][data-variant="contained"] {
	background-color: var(--color-blue);
	color: var(--color-white);
}
.btn[data-variant="outlined"][data-color="secondary"]:hover {
	background-color: var(--color-light-grey-4);
}
.btn[data-variant="outlined"][data-color="primary"]:hover {
	background-color: var(--color-primary-hover);
}
.btn[data-disabledhovering="false"] .btn[data-variant="contained"][data-color="secondary"]:hover {
	background-color: var(--color-light-grey-4);
}
.btn[data-disabledhovering="false"] .btn[data-variant="contained"][data-color="primary"]:hover {
	background-color: var(--color-primary-hover);
}
.btn[data-color="secondary"][data-variant="outlined"] {
	border: 1px solid var(--color-light-grey-2) !important;
	border-radius: 4px;
}
.btn[data-color="primary"][data-variant="outlined"] {
	border: 1px solid var(--color-blue) !important;
	border-radius: 4px;
}
.btn__label {
	display: flex;
	justify-content: center;
	align-content: center;
	font-size: var(--font-size-md);
	pointer-events: none;
}
.btn__badge {
	padding: 8px !important;
}
