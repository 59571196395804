.informationAccordion {
	display: flex;
	flex-wrap: wrap;
}
.informationAccordion > * {
	flex-grow: 1;
}
.loaderContainer {
	width: 100%;
	text-align: center;
	margin-top: 50px;
}
.accordionTitle {
	composes: font-xl-bold from global;
}
.detailInfo {
	display: flex;
	align-items: baseline;
	padding: 8px 0;
}
.detailInfo__title {
	composes: font-sm-bold from global;
	text-transform: uppercase;
	color: var(--shades-grey-400);
	padding-right: 16px;
	width: 150px;
	min-width: 150px;
	text-align: end;
}
