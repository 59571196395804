.alert {
	composes: font-sm-regular from global;
	width: 80%;
	margin: var(--spacing-s) auto;
}
.companyContainer {
	display: flex;
	flex-direction: column;
	border-right: 1px solid var(--color-light-grey-3);
}
.container--full-width {
	width: 100%;
}

.container--half-width {
	width: 50%;
}
.selectedCompany {
	width: 50%;
	border-left: 1px solid var(--color-light-grey-3);
}
.companyTable {
	height: inherit;
	overflow-y: auto;
}
.companyInfo {
	display: flex;
	flex-direction: column;
	margin: var(--spacing-s);
}
.companyInfo__edit {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: var(--spacing-s) auto;
	width: 80%;
}
.dividerSpace {
	margin: var(--spacing-xs);
}
