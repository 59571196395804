.clear-button__container {
	text-align: left;
	margin: 0 0 -4px 0 !important;
}
.error {
	color: var(--color-red);
}
.errors-container__list {
	color: var(--color-red);
	text-align: left;
}
.errors-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.error__failedEmails {
	max-width: 100%;
	color: var(--color-red);
}
.error__failedEmails[data-hide="true"] {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}
.errors-container > * {
	width: 80% !important;
	margin-top: 16px;
}
.textfield__hint {
	font-size: var(--font-size-xs);
	color: var(--color-blue);
	white-space: nowrap;
}
.textfield__hint--grey {
	color: var(--color-dark-grey-1);
}
.informationContent__btn {
	padding: 0 !important;
}
.informationContent__btn > span {
	margin-right: 0 !important;
	height: var(--font-size-xs);
	width: var(--font-size-xs);
}
