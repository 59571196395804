.main > div:last-of-type {
	text-align: center;
}
.showMore {
	composes: font-sm-medium from global;
	color: var(--color-blue);
	cursor: pointer;
}
.main > div:last-of-type > hr {
	margin-bottom: var(--spacing-xs);
}
