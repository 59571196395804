.container {
	border-radius: 4px;
	padding: 8px;
	background-color: var(--color-white);
	border: 1px solid var(--shades-grey-v2-30);
	max-height: 100%;
	overflow: auto;
}
.header {
	display: flex;
	align-items: center;
}
.rightIcon {
	color: var(--color-blue);
	transition: none;
	font-size: 16px !important;
	margin-left: 12px;
}
.rightIcon--translucent {
	opacity: 0;
	transition: opacity 1s;
}
