.main-container {
	padding: 8px var(--view-padding-lateral) 0 var(--view-padding-lateral);
	display: flex;
	flex-direction: column;
	height: calc(100% - 88px);
}
.buttons__container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 16px;
	height: 120px;
	min-height: 120px;
}
.buttons__container--right {
	display: flex;
	gap: var(--spacing-xs);
}
.cancel-dialog {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 32px;
}
.cancel-icon {
	font-size: var(--font-size-8xl);
	color: var(--color-red);
}
.container__backdrop {
	display: flex;
	flex-direction: column;
	place-items: center;
}
.backdrop {
	z-index: 1000;
	color: var(--color-white);
}
.stepLabel {
	composes: font-xs-regular from global;
}
.content {
	height: calc(100% - 180px);
}
