.detailBlockContainer {
	background-color: var(--color-light-grey-4);
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
}
.detailBlockContainer[data-highlighted="true"] {
	background-clip: content-box;
	background-color: var(--color-light-grey-3);
}
.detailBlockContainer[data-hovered="true"] {
	background-color: var(--tints-grey);
}
.detailBlock {
	align-items: center;
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.detailBlock__indicator {
	cursor: default;
}
.thematicDisplayer {
	float: left;
}

.statusButtons {
	float: left;
	margin-top: 5px;
}
.notForUs {
	color: var(--color-light-grey-3);
	padding-left: 20px;
	font-weight: bold;
}
.additionalBlocks {
	float: left;
	align-items: center;
}
.freeze {
	width: 24px;
}
.button {
	background-color: var(--color-white);
	margin: 0 2px;
	border: solid 1px var(--color-light-grey-4) !important;
	border-radius: 4px !important;
	min-width: 0 !important;
}
@media screen and (width < 1440px) {
	.detailBlockContainer {
		height: 24px;
	}
	.button {
		margin: var(--spacing-2xs) 2px;
		height: 24px !important;
		width: 24px !important;
	}
}
@media screen and (width >= 1440px) {
	.detailBlockContainer {
		height: 32px;
	}
	.button {
		height: 32px !important;
		width: 32px !important;
	}
}
