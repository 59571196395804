.container {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xs);
	background-color: var(--color-white);
	border-radius: 4px;
	padding: 8px;
	width: 100%;
}
.header {
	composes: font-sm-regular from global;
	color: var(--color-light-grey-1);
	display: flex;
	align-items: center;
}
.projectName {
	composes: font-sm-regular from global !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.infoIcon {
	padding-right: 7px;
}
.verticalDivider {
	width: 1px;
	height: 16px;
	margin: 0 8px;
}
.content {
	composes: font-xs-regular from global;
}
.footer {
	composes: font-xs-regular from global;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: var(--color-light-grey-1);
}
.openBtn {
	margin-left: 8px;
}
.breadcrumbContainer {
	width: 100%;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
}
.breadcrumb {
	composes: font-xs-regular from global;
}
