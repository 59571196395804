.option__text {
	padding-left: 16px;
	text-transform: capitalize;
	display: inline-block;
	width: 160px;
}
.option__control {
	display: inline-block;
	text-align: center;
	margin-right: 6px;
	width: 60px;
}
.option {
	display: flex;
	min-height: 32px;
	color: var(--color-black);
	align-items: center;
}
