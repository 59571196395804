.dropDownContainer {
	padding: var(--spacing-xs) 0;
	display: flex;
	flex-direction: column;
}
.dropDownContainer__optionsExtender {
	height: var(--spacing-2xl) !important;
	composes: font-md-medium from global;
	text-transform: none;
	text-align: left;
	padding: 0px !important;
}
.option__text {
	padding-left: var(--spacing-s);
	display: inline-block;
	width: 160px;
}
.option__control {
	display: inline-block;
	text-align: center;
	margin-right: 6px;
	width: 60px;
}
.labeledCheckbox {
	margin: 0 !important;
	padding: 0 var(--spacing-s) 0 0 !important;
	border: 1px solid var(--color-light-grey-3);
}
.radioGroup > label:first-of-type {
	border-radius: 4px 4px 0 0;
}
.radioGroup > label:last-of-type {
	border-radius: 0 0 4px 4px;
}
.dropdown {
	margin-top: var(--spacing-2xs) !important;
}
