.container {
	width: 100%;
	display: flex;
	align-items: center;
}

.separator {
	margin: 0px 16px;
}
.collapseButton {
	background-color: var(--shades-grey-100) !important;
	border-radius: 4px !important;
	padding: 0px !important;
	min-width: 24px !important;
	width: 24px !important;
	min-height: 16px !important;
	height: 16px !important;
}
.folderIcon {
	margin-right: 8px;
}
