.dialog-content__text {
	font-size: var(--font-size-md);
}
.dialog-content {
	align-items: center;
	display: flex;
	flex-direction: column;
}
.dialog-content > * {
	margin-bottom: 16px;
}
.dialog-content > *:last-child {
	margin-bottom: 0;
}
.dialog-content__icon {
	font-size: var(--font-size-8xl);
	color: var(--color-red);
}
