.main {
	height: 48px;
	width: auto;
	margin: 0 var(--spacing-s);
	padding: var(--spacing-s);
	display: flex;
}
.main > div {
	flex: 1;
	align-items: center;
}
