.projectWidgetContainer {
	display: flex;
	padding: 32px 32px 16px 32px;
	flex-wrap: wrap;
	gap: 16px;
}
.projectWidget {
	min-width: 320px;
	width: calc(50% - 10px);
	height: fit-content;
	margin: 0 auto;
}
.projectTitle {
	display: flex;
	margin: 16px 0;
	width: 100%;
}
