.mainContainer {
	width: 100%;
	height: calc(100vh - 87px) !important;
}
.contentContainer {
	height: 100%;
	width: 100%;
	display: inline-flex;
	flex-wrap: wrap;
}
.screenPart {
	height: 100%;
	overflow-y: auto;
	background-color: var(--tints-blue-5);
}
.projectList {
	width: 60%;
}
.widgets {
	max-width: 40%;
}
.noProject {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
}
.noProject__text {
	composes: font-sm-regular from global;
	font-style: italic;
}
.noProject > * {
	width: fit-content;
	margin-top: 16px;
}

@media screen and (max-width: 1100px) {
	.projectList {
		width: 100%;
	}
	.widgets {
		display: none;
	}
}

@media screen and (min-width: 700px) {
	.projectList {
		min-width: 700px;
	}
	.widgets {
		width: calc(100% - 700px);
	}
}
