.container {
	height: fit-content;
	align-items: stretch;
	flex-grow: 0;
	border-radius: 4px;
	box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 20%);
	border: 1px solid var(--shades-grey-200);
	margin: 8px 4px;
	background-color: var(--color-white);
}
.container[data-shadow="false"] {
	border: 1px solid var(--color-light-grey-4);
	box-shadow: none;
}
.container[data-clickable="true"] {
	cursor: pointer;
	transition: box-shadow 250ms;
}
.container[data-clickable="true"]:hover {
	cursor: pointer;
	border: 1px solid var(--color-light-grey-3);
}
