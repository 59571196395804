.container {
	background-color: var(--shades-grey-100);
	padding-top: var(--spacing-xs);
	height: 100%;
}
.container__newComment {
	margin: var(--spacing-xs) var(--spacing-xs);
	padding: var(--spacing-xs);
}
.list__fullHeight {
	height: 100%;
}
.list__emptyText {
	height: calc(100% - 100px);
}
.list__shortText {
	height: calc(100% - 125px);
}
.list__largeText {
	height: calc(100% - 175px);
}
.listScrollContainer {
	margin-top: var(--spacing-xs);
	margin-bottom: var(--spacing-s);
	background-color: inherit;
}
.textField > div {
	margin-right: 0;
}
.textField textarea {
	padding-left: var(--spacing-xs) !important;
}
.container__line {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: var(--spacing-xs) 0;
}
.name {
	composes: font-xs-medium from global;
	color: var(--color-black);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.line__rightItems {
	width: 100%;
	height: 50%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	white-space: nowrap;
	color: var(--color-light-grey-1);
}
.btnClassName {
	color: var(--color-dark-grey-2) !important;
	width: var(--spacing-m) !important;
	height: var(--spacing-m) !important;
}
.iconClassName {
	width: 12px !important;
	height: 12px !important;
}
.helperTextClassName {
	width: calc(100% - 38px);
	height: var(--spacing-s);
	margin-bottom: -12px;
	padding-top: 10px;
	text-align: right;
	font-size: 12px;
}
.tabs {
	top: -1px;
	padding: 0px var(--spacing-xs);
	width: calc(100% - 8px);
	box-shadow: none;
	background-color: var(--shades-grey-100);
	margin: -8px 0px -8px 4px;
}
.tabs > div {
	padding: 0;
}
.tabs span {
	position: absolute;
	top: 35px;
}
.tabs > * {
	background-color: var(--shades-grey-100);
	padding-left: var(--spacing-xs);
}
.tabs button {
	font-size: 12px;
	font-weight: 500;
}
.emptyStateContainer {
	width: 100%;
	margin-top: var(--spacing-l);
	gap: var(--spacing-2xs);
	display: flex;
	justify-content: center;
	align-items: center;
}
.displayMore__button {
	margin: 0 var(--spacing-xs) var(--spacing-m);
	width: calc(100% - 16px);
}
