.container {
	background-color: var(--shades-grey-100);
	padding: 8px;
	height: calc(100% + 16px);
}
.main {
	margin: var(--spacing-2xs) !important;
	height: 100%;
}
.noContent {
	composes: font-sm-regular from global;
	padding: 10px 18px;
	border-radius: 4px;
	background-color: var(--shades-blue-50);
	color: var(--shadow-6);
}
.noContent__infoIcon {
	margin-right: 10px;
}
.loading {
	width: -moz-fit-content;
	width: fit-content;
	margin: 16px auto;
}
.emptyState {
	box-shadow: none;
	border-radius: var(--spacing-2xs);
	color: var(--color-black);
	padding: var(--spacing-s) var(--spacing-xs);
	background-color: var(--color-white);
	border: 1px solid var(--color-light-grey-4);
	composes: font-xs-regular from global;
}
