.errorBoundary__container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.errorBoundary__message {
	font-weight: bold;
	color: var(--color-red);
}
