.main {
	background-color: var(--shades-grey-100);
	padding: var(--spacing-xs);
	height: calc(100% + var(--spacing-s));
}
.container {
	height: calc(100% - var(--spacing-xl));
}
.emptyState {
	box-shadow: none;
	border-radius: var(--spacing-2xs);
	padding: var(--spacing-s) var(--spacing-xs);
	background-color: var(--color-white);
	border: 1px solid var(--color-light-grey-4);
	composes: font-xs-regular from global;
}
