.tile {
	border-radius: 4px;
	background-color: var(--color-white);
	width: 100%;
	padding: 5px 10px 10px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: var(--color-dark-blue);
	letter-spacing: 0.00938em;
	box-shadow: 0px 0px 10px 0px var(--shadow-2);
}
