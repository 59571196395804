.overlay-hover__main-container {
	position: absolute;
	background-color: var(--color-white);
	padding-left: 5px;
	padding-right: 5px;
	z-index: 100;
	text-align: center;
	border: 1px solid var(--color-light-grey-3);
	align-items: center;
	border-radius: 6px;
	font-size: var(--font-size-xs);
	display: flex;
	flex-direction: row;
}
.overlay-hover__requirement-identifier {
	margin: 6px 8px;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 8px;
	padding: 0px 6px 0px 6px;
	text-transform: uppercase;
	max-width: 12vw;
	overflow: hidden;
	text-overflow: ellipsis;
}
.overlay-click > * {
	display: inline-block;
	margin: -2px;
	padding: 2px;
}
