.main-container {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 32px;
	height: 100%;
}
.main-container > div:last-of-type {
	height: 100%;
	overflow: auto;
}
.container__header {
	display: grid;
	grid-template-columns: auto 1fr;
	justify-content: flex-start;
	margin-bottom: 24px;
}
.step-title {
	composes: font-md-bold from global;
	display: flex;
	gap: var(--spacing-xs);
}
.tooltip {
	line-height: 19px;
	margin-left: 5px;
	grid-column: 2;
}
.category-message {
	composes: font-sm-regular from global;
	border: 1px solid var(--tints-blue-30);
	border-radius: 8px;
	width: 50%;
	margin-bottom: var(--spacing-s);
	display: flex;
	align-items: center;
}
.category-warning {
	padding: var(--spacing-xs) var(--spacing-s);
}
.category-further {
	padding: 0 var(--spacing-s);
}
.category-warning > svg {
	margin-right: var(--spacing-s);
}
.noAccess__container {
	background-color: var(--color-blue-10);
	border-radius: 4px;
	height: fit-content !important;
	padding: var(--spacing-s);
}
