.emptyState {
	display: flex;
	text-transform: uppercase;
}
.emptyState__text {
	margin: 50% auto;
}
.details {
	display: flex;
	flex-direction: column;
	margin: 16px 0;
	align-items: center;
	justify-items: center;
}
.details__text {
	font-weight: bold;
}
.details__date {
	font-style: italic;
}
.details__emptyState {
	font-style: italic;
	padding-left: 8px;
}
.StyledAccordionDetails__root {
	padding: 0 !important;
}
