.header {
	width: 100%;
	display: flex;
	padding: var(--spacing-m);
	align-items: center;
}
.userCount {
	composes: font-sm-regular from global;
	margin-left: var(--spacing-xs);
	color: var(--color-light-grey-1);
}
.addButton {
	composes: font-sm-regular from global;
	margin-left: auto;
}
