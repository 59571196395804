.req-list {
	width: 40%;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
}

.req-list__infinite-list {
	width: initial !important;
	overflow-y: auto !important;
}

.req-list__counter {
	background: var(--color-light-grey-3);
	text-align: center;
	font-size: var(--font-size-xs);
	font-weight: bold;
	font-style: italic;
}

.req-list__empty-state {
	position: absolute;
	top: 30%;
	text-align: center;
	width: 100%;
}

.req-list__empty-state .req-list__empty-state-title {
	font-weight: bold;
}

.req-list__empty-state .req-list__empty-state-sub-title {
	font-style: italic;
	font-size: var(--font-size-xs);
}
