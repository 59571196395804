.margin-bottom {
	margin-bottom: 16px !important;
}
.padding-top {
	padding-top: 8px;
}

.dialog__name-field {
	width: 100%;
}
.tag__row {
	margin-left: 16px;
	display: flex;
	align-items: center;
}
