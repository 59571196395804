.client-sublayer {
	margin: 6px 8px;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 8px;
	padding: 0px 6px 0px 6px;
	text-transform: uppercase;
	max-width: 12vw;
	overflow: hidden;
	text-overflow: ellipsis;
}
