.profileMenu__icon {
	font-weight: 400;
	line-height: 30px;
	height: 30px;
	width: 30px;
	font-size: var(--font-size-sm);
}

.profileMenu:hover {
	background-color: transparent !important;
}
