.createNorm {
	padding: 0 50px;
	margin-bottom: 48px;
	margin-top: 16px;
}
.createNorm__fields {
	display: flex;
	margin: 16px 0;
}
.createNorm__fields > *:first-child {
	margin-left: 2px;
}
.createNorm__fields > *:last-child {
	margin-right: 2px;
}
.fields__name {
	width: 98%;
}
.fields__date {
	width: 25%;
}
