.companyMenu {
	width: 100%;
	margin-bottom: var(--spacing-xs);
	padding: var(--spacing-s);
	display: flex;
	justify-content: space-between;
}
.mainContainer {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 60vh;
	margin: var(--spacing-xs);
}
.container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: var(--spacing-2xs) auto;
	width: 80%;
}
.userMenu {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.adminSelector {
	width: 100%;
	margin: var(--spacing-xs) 0;
	display: flex;
	justify-content: space-between;
}
.titleName {
	composes: font-md-bold from global;
	padding: var(--spacing-s) 0;
}
.dividerSpace {
	margin: var(--spacing-xs);
}
