.header {
	margin-top: var(--spacing-xs);
	padding: 0 var(--spacing-xs);
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	width: 100%;
}
.header__info {
	display: flex;
	gap: var(--spacing-xs);
	align-items: center;
	width: calc(100% - 32px);
}
.header__info[data-hovered="true"] {
	width: calc(100% - 170px);
}
.header--rowId {
	composes: font-sm-regular from global;
	color: var(--color-dark-grey-1);
	text-transform: uppercase;
	display: flex;
	align-items: center;
}
.header__mainActions,
.header__actions {
	display: flex;
	align-items: center;
	gap: var(--spacing-xs);
}
.row:not(:hover) > .header > .header__mainActions {
	display: none;
}
.commentBtn__container[data-has-comments="true"] {
	display: block !important;
}
.content {
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	text-align: justify;
	margin: var(--spacing-xs);
}
.readMore {
	color: var(--color-blue);
	cursor: pointer;
	margin-left: var(--spacing-xs);
}
.flaggedBtn__container {
	width: 32px;
}
.flagged--icon {
	color: var(--color-white);
}
.flagged--button {
	background-color: var(--color-red);
}
.verticalDivider {
	height: 18px;
}
.breadcrumbContainer {
	width: 100%;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
}
.confirmDelete {
	border: 1px solid var(--color-orange) !important;
	margin-right: var(--spacing-xs);
	color: var(--color-orange) !important;
}
.deletedAt {
	composes: font-sm-regular from global;
	white-space: nowrap;
	color: var(--shades-grey-600);
}
.requiredIcon,
.infoIcon {
	font-size: var(--font-size-xs) !important;
	padding-right: 7px;
}
