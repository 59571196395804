.dialog__title {
	display: flex;
}
.dialog__title .dialog__title-text {
	width: 100%;
	align-self: center;
}
.dialog__content {
	-ms-overflow-style: none;
	display: flex;
	flex-direction: column;
}
.dialog__content::-webkit-scrollbar {
	display: none;
}
.dialog__btn-container {
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
}
.dialog__field {
	margin: 8px 0 !important;
}
.dialog__partners {
	display: flex;
	padding-top: 16px;
	margin: auto;
	text-align: center;
}
.autoCompleteSearch__userDisplayName {
	width: 50%;
	max-width: 50%;
}

.helper__text {
	composes: font-sm-regular from global;
	display: flex;
	height: 10px;
	line-height: 20px;
	margin-top: 25px;
	margin-bottom: 20px;
	font-weight: bold;
	color: var(--color-black);
}
