.container {
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.rowsContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 16px;
}
.categoryRow {
	white-space: nowrap;
}
.searchContainer__textfield {
	width: 300px;
	margin: 16px var(--spacing-xs);
}
