.layout {
	position: relative;
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	padding: 0 var(--spacing-xs);
}
.layout__drawer {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 8px 4px 0 0;
	gap: var(--spacing-s);
	justify-content: flex-start;
	align-items: flex-start;
}
.layout__drawer[data-open="true"] {
	min-width: 20%;
	max-width: 30%;
	width: calc(20% - 32px);
}
.layout__drawer[data-open="false"] {
	min-width: fit-content;
	margin-right: 8px;
}
.resizer {
	border-right: 1px solid var(--color-light-grey-3);
	width: 4px;
}
.drawer__header {
	width: 100%;
	display: inline-flex;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	gap: var(--spacing-s);
}
.drawer__iconBtn {
	align-self: flex-start;
}
.layout__divider {
	background-color: var(--color-light-grey-2);
}
.layout__main {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	margin-left: 32px;
	gap: var(--spacing-s);
	width: 70%;
	height: 100%;
}
