.main {
	display: flex;
	width: 100%;
	align-items: center;
	max-height: 26px;
	padding: 0;
}
.main > svg {
	margin-right: var(--spacing-xs);
	color: var(--color-light-grey-1);
}
.main > div:nth-of-type(1) {
	margin-right: auto;
}
.name {
	max-width: calc(100% - 160px);
}
