.table--title {
	color: var(--color-black);
	font-size: var(--font-size-lg);
}

.root {
	width: 100%;
	margin-top: 24px;
	color: var(--color-black);
}
.table {
	min-width: 500px;
	color: var(--color-black);
}
.tableWrapper {
	overflow-x: auto;
	color: var(--color-black);
}
.table__cell {
	color: var(--color-black);
	font-size: var(--font-size-xl);
}
