.advancedSearch {
	background-color: var(--color-white) !important;
	composes: font-sm-medium from global;
}
.button {
	background-color: var(--color-white) !important;
	border-radius: var(--spacing-s);
}
.filtersContainer {
	transition: width 0.1s;
	width: calc(100% - 64px);
	background-color: var(--color-light-grey-4) !important;
	position: fixed;
	top: 162px;
	z-index: 1;
	left: 0;
}
.filtersContainer[data-open="false"] {
	height: 0;
}
.filtersContainer[data-right-panel="true"] {
	width: calc(100% - 35vw - 64px);
	color: var(--shades-grey-v2-70);
}
.searchActions {
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin-right: 8px;
}
.filtersContainer[data-role-banner-disabled="false"] {
	top: 203px;
}
.container,
.searchNavigation {
	display: flex;
	align-items: center;
}
.searchIndex {
	display: inline-block;
	line-height: 28px;
	margin-right: var(--spacing-s);
	text-transform: lowercase;
	composes: font-xs-medium from global;
}
.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.closeButton {
	margin-left: 16px;
}
.searchIndexContainer__searchIndexInput {
	composes: font-md-medium from global;
	border-radius: 4px;
	margin-right: 4px;
	margin-left: 8px;
	background-color: var(--color-white);
}
.searchIndexContainer__searchIndexInput--padding {
	width: 64px;
	height: 28px;
	padding: 0;
	text-align: center;
}
