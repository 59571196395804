.sideDrawer__main {
	margin: 0 !important;
	padding: 0 var(--spacing-xs);
	height: calc(100% - 150px);
}
.sideDrawer__content {
	margin: 0 !important;
	gap: 0 !important;
}
.content {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	height: calc(100% - 50px);
}
.divider {
	width: 5px;
}
.wildcard {
	position: absolute;
	right: 0;
}
.wildcard--label {
	composes: font-xs-medium from global;
	color: var(--color-blue) !important;
}
.sideDrawer__loader {
	display: flex;
	margin: auto;
	align-items: center;
}
