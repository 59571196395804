.optionHeader {
	display: flex;
	width: calc(100% - 64px);
	flex-direction: row;
	margin: 32px 64px 0 64px;
	flex-wrap: wrap;
}
.optionHeader__selector {
	width: 250px;
}
.item__icon {
	float: right;
}
.item__me {
	color: var(--color-light-grey-1);
	margin-left: 8px;
}
.input__select {
	padding: 10.5px 12px;
}
.myAccountLogo {
	margin-right: 8px;
}
.selector__infiniteList {
	max-height: 200px;
}
