.form-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
}
.form-container > *:first-child {
	margin: 0 0 50px 0;
}
.form > * {
	margin: 8px 0 !important;
}
.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 40%;
	max-width: 384px;
}
.inline-text {
	font-size: var(--font-size-md);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}
.title {
	font-size: var(--font-size-3xl);
	font-weight: bold;
	margin: 0;
}
.textfield__helper {
	font-size: var(--font-size-sm);
}
