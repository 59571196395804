.row {
	margin-bottom: var(--spacing-2xs);
	align-self: stretch;
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
	padding: 8px;
	border-radius: 4px;
	cursor: pointer;
}
.row[data-selected="true"] {
	color: var(--color-blue);
}
.row[data-clickable="true"] {
	background-color: #f5f5f5;
}
.row[data-clickable="false"] > .label {
	padding-left: var(--spacing-xs);
}
.square {
	width: 16px;
	height: 16px;
	border-radius: 4px;
	font-size: 12px;
	background-color: var(--color-light-grey-4);
}
.label {
	display: flex;
	align-items: center;
	padding-right: 12px;
	max-width: calc(100% - 92px);
	composes: font-xs-medium from global;
}
.label--icon {
	margin-right: var(--spacing-2xs);
}
.row > div:nth-of-type(2) {
	margin-left: auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.details {
	display: inline-block;
	padding-right: 4px;
	width: 10%;
	min-width: 36px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	composes: font-xs-regular from global;
}
.row > div:nth-of-type(2) > :last-child {
	text-align: center;
}
