.mainContainer {
	display: flex;
	justify-content: center;
	margin: var(--spacing-s) 0;
	height: calc(100% - 119px);
}
.innerContainer {
	width: 70%;
	height: 100%;
}
