.cell {
	padding: 10px;
}
.tableRow--selected {
	background-color: var(--color-light-grey-4);
}
.parentName {
	padding-left: var(--spacing-xs);
}
.childName {
	padding-left: var(--spacing-s);
}
