.container {
	background-color: var(--shades-grey-100);
	padding: 8px;
	height: calc(100% + 16px);
}
.filter {
	background-color: var(--color-white);
	margin-bottom: 8px;
}
.filterTextField {
	composes: font-sm-regular from global !important;
	line-height: 24px;
	height: 32px;
}
.filterLabel {
	composes: font-sm-regular from global !important;
}
.listContainer {
	height: calc(100% - 32px);
}
.listScrollContainer {
	height: calc(100% - 8px) !important;
}
