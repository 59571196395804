.main {
	background-color: var(--shades-grey-100);
	height: 100%;
	display: flex;
	flex-direction: column;
}
.filters__container {
	display: flex;
	justify-content: center;
	padding: var(--spacing-xs);
}
.filters__search {
	width: 100%;
	background-color: var(--color-white);
}
.totalResult {
	display: flex;
	justify-content: flex-end;
	color: var(--color-light-grey-1);
	font-size: var(--font-size-xs);
	margin: var(--spacing-xs);
}
.result__container {
	height: calc(100% - 104px);
	overflow: auto;
}
.emptyStateContainer {
	width: 100%;
	height: 5%;
	gap: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.sorter__container {
	display: flex;
	align-items: center;
	margin: 0 var(--spacing-xs) 0 auto;
}
