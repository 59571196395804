.margin-bottom {
	margin-bottom: 16px !important;
}

.padding-top {
	padding-top: 16px;
}

.dialog__title {
	display: flex;
}

.dialog__title .dialog__title-text {
	width: 100%;
	align-self: center;
}

.dialog__content {
	-ms-overflow-style: none;
}

.dialog__content::-webkit-scrollbar {
	display: none;
}

.dialog__content .dialog__name-field {
	width: 100%;
}

.dialog__content .dialog__select-field {
	margin-left: 15px !important;
	width: 40%;
}

.dialog__content .dialog__content-title {
	margin: 16px;
	font-weight: bold;
}

.dialog__content .dialog__checkbox {
	margin-right: 8;
}
.item {
	display: flex;
	align-items: center;
	margin: 0 var(--spacing-s);
}
