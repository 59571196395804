.mainContainer {
	margin: 0 auto;
	padding-top: 7%;
	text-align: center;
	width: 540px;
}
.mainContainer > div:first-of-type {
	position: relative;
}
.downloadLink {
	padding-left: 5px;
	color: var(--color-black);
	text-decoration: underline;
}
.textContainer {
	padding-top: 20px;
}
.primaryText {
	font-size: var(--font-size-2xl);
	width: 80%;
	margin: 0 auto;
	font-weight: bold;
	padding-top: 20px;
}
.secondaryText {
	padding-top: 24px;
	font-size: var(--font-size-lg);
}
.browsersList {
	padding: 24px 0 0 185px;
	text-align: left;
}
.operaLogo__img {
	padding: 7px;
}
.versioning-btn {
	margin-left: auto;
	cursor: default;
	color: var(--color-blue);
	text-decoration: none;
	position: absolute;
	bottom: 0;
}
