.projectReqIssues {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0 8px;
	width: 100%;
	height: 100%;
}
.header {
	composes: font-sm-regular from global;
	padding: 8px;
}
.projectReqIssues__badges {
	width: calc(100% - 15px);
	margin: 24px 0;
	display: flex;
	justify-content: space-between;
}
.tooltip {
	box-shadow: 0px 5px 20px 0.3px var(--shadow-5);
	font-size: var(--font-size-sm);
}
.manageRightsBtn {
	margin: 0 auto 8px auto;
}
.accepted {
	color: var(--color-green) !important;
}
.pending {
	color: var(--color-orange) !important;
}
.denied {
	color: var(--color-red) !important;
}
.notme {
	color: var(--color-black) !important;
}
.unvalidated {
	color: var(--color-light-grey-2) !important;
}
