.modal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: var(--shadow-5);
}
.modalContent {
	background-color: var(--color-white);
	margin: 20% auto;
	padding: 20px 30px 30px 30px;
	border: 1px solid #888;
	border-radius: 4px;
	width: 100%;
	max-width: 700px;
	position: relative;
}
.close {
	float: right;
}
.buttonsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.buttonsContainer > * {
	margin: 4px;
	margin-left: 8px;
	margin-right: 8px;
}
.table {
	margin-left: 4px;
}
.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}
.limitWidth {
	text-align: center;
	width: 90px;
}
.circularProgress {
	margin-top: 16px;
}
