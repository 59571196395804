.row {
	min-height: 48px;
	border: 1px solid var(--color-light-grey-3);
	width: auto;
	margin: 0 var(--spacing-s);
	padding: var(--spacing-s);
	display: flex;
	align-items: center;
}
.row > div {
	flex: 1;
	align-items: center;
	display: flex;
}
