.bloc {
	display: flex;
	width: 100%;
	margin-bottom: 8px;
	flex-direction: column;
}
.buttons > * {
	margin: 4px 8px 4px 0;
}
.title-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	background-color: var(--shades-grey-500);
}
.no-content {
	display: inline-grid;
	justify-content: center;
	justify-items: center;
	align-items: stretch;
	font-size: var(--font-size-md);
	width: 100%;
	top: 10%;
	position: relative;
	color: var(--color-light-grey-4);
}
.title-container__row {
	background-color: var(--shades-grey-500);
	color: var(--color-white);
	display: flex;
	flex-direction: row;
	min-height: 40px;
}
.title-container__row > *:nth-child(n + 2) {
	margin-left: 8px;
}
.title-container__row:first-child {
	margin-bottom: 8px;
}
.title-container__row:first-child > *:first-child {
	white-space: nowrap;
}
.details-header {
	display: flex;
	flex-direction: row;
}
.asker {
	background-color: var(--shades-grey-500);
	font-size: var(--font-size-xs);
	font-weight: normal;
	color: var(--color-white);
	align-items: center;
	display: flex;
}
.dates {
	background-color: var(--shades-grey-100);
	border-bottom: solid 2px var(--shades-grey-300);
	padding: 1.25rem;
	font-size: var(--font-size-xs);
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.content {
	grid-area: content;
	padding: 1.25rem;
	background-color: var(--color-white);
	width: 100%;
	position: relative;
	font-size: var(--font-size-sm);
	overflow-wrap: break-word;
}
.edit {
	font-size: var(--font-size-2xl);
}
.title__id {
	font-size: var(--font-size-xl);
	font-weight: 500;
	display: inline-block;
	margin-right: 0.5rem;
}
.title__tag {
	font-weight: normal;
	display: inline-block;
	margin-right: 0.5rem;
}
.qa-navigation {
	font-size: var(--font-size-xs);
	font-weight: normal;
	display: inline-block;
	margin-right: 0.5rem;
}
.related-container {
	border-top: solid 2px var(--shades-grey-300);
	border-bottom: solid 3px var(--shades-grey-300);
	padding: 10px;
	background-color: var(--color-white);
}
.answer-head {
	grid-area: date-a;
	background-color: var(--shades-grey-100);
	border-bottom: solid 2px var(--shades-grey-300);
	padding: 1.25rem;
	font-size: var(--font-size-xl);
}
.edit-button {
	margin: 16px 0 8px 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 50px;
}
.tag {
	margin: 3px;
}
.breadcrumb {
	margin-left: 16px;
}
.commentLine {
	margin: 2px 0;
}
