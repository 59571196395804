.accordionContent {
	width: 100%;
}
.line {
	height: var(--spacing-l);
	line-height: var(--spacing-l);
	padding: 0;
	cursor: pointer;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	composes: font-sm-medium from global;
}
.line > * {
	padding: 0;
}
