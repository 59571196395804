.container {
	padding: 16px;
	border-radius: 4px;
}
.container[data-selected="true"] {
	background-color: var(--color-blue-10);
}
.label {
	composes: font-md-regular from global;
}
.label[data-disabled="true"] {
	color: var(--shadow-5);
}
.matchAll__container {
	display: flex;
	overflow: hidden;
	padding: 8px 4px;
	gap: 8px;
	align-items: center;
}
.matchAll__helperText {
	composes: font-md-medium from global;
	text-transform: uppercase;
	color: var(--color-light-grey-1);
}
