.container--analyzing {
	background-color: var(--color-blue-10) !important;
}
.container {
	background-color: var(--color-orange-20);
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.textContainer {
	padding: var(--spacing-s) var(--spacing-m);
}
.boldText {
	composes: font-md-bold from global;
	padding-bottom: var(--spacing-xs);
}
