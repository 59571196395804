.comment > div:first-of-type {
	display: flex;
}
.comment > div > div {
	flex: 1;
}
.comment > div:first-of-type > span:nth-child(2) {
	margin-left: var(--spacing-2xs);
}
.comment > div:first-of-type > span:first-child {
	font-weight: bold;
}
.comment > div:first-of-type > span:first-child,
.comment > div:nth-child(2) {
	color: var(--color-black);
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	text-align: justify;
}
.comment > div:nth-child(2) {
	margin-top: var(--spacing-xs);
}
