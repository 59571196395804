.rowContainer {
	display: flex;
	align-items: center;
}
.rowName {
	composes: font-sm-regular from global;
	width: calc(100% - 32px);
	display: inline-block;
	max-height: -moz-fit-content !important;
	max-height: fit-content !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.checkBox {
	padding: 6px;
}
