.navbar {
	position: -webkit-sticky;
	position: sticky;
	top: 0px;
	padding: 0 16px;
	width: 100%;
	min-height: 45px;
	height: 45px;
	line-height: 45px;
	border-bottom: 3px solid;
	z-index: 1000;
	background-color: var(--color-white);
	border-color: var(--color-light-grey-3);
}
.navbar__versionButton {
	margin-top: 18px;
	text-decoration: underline !important;
	font-weight: normal;
	font-size: var(--font-size-xs) !important;
}
.navbar__versionButton:hover {
	background-color: transparent !important;
}
.navbar__homeIconButton {
	padding-right: 5px;
}
.navbar__homeIconButton--hidden {
	visibility: hidden;
}
.navbar__projectMenus {
	padding-left: 2.6vw;
	white-space: nowrap;
	width: -moz-fit-content;
	width: fit-content;
}
.navbar__mainMenus {
	white-space: nowrap;
	width: -moz-fit-content;
	width: fit-content;
}
.navbar__userMenus {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}
