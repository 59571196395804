.main {
	height: var(--spacing-2xl);
	width: auto;
	margin: 0 var(--spacing-s);
	padding: var(--spacing-s);
	display: flex;
	border-bottom: 1px solid var(--color-light-grey-3);
}
.name {
	width: calc(100% - 132px);
}
.projects {
	width: 100px;
}
.actions {
	width: 32px;
}
