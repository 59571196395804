.container {
	width: 100%;
}
.title {
	composes: font-md-medium from global;
	text-align: center;
	margin-bottom: var(--spacing-xs);
}
.propositions {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-xs);
	margin-bottom: var(--spacing-xs);
}
.proposition__container {
	flex-basis: calc(20% - var(--spacing-xs));
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-xs);
	border: 1px solid var(--color-light-grey-3);
	padding: var(--spacing-xs);
	min-width: 100px;
}
.proposition_title {
	text-align: center;
	margin-bottom: auto;
}
