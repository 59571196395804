.root {
	width: 28px;
	height: 16px;
	padding: 0;
	display: flex;
}
.switchBase {
	padding: 2px;
	color: var(--color-blue);
}
.checked {
	transform: translateX(12px) !important;
	color: var(--color-white) !important;
}
.checked + .track {
	opacity: 1 !important;
	background-color: var(--color-blue) !important;
	border-color: var(--color-blue) !important;
}
.thumb {
	width: 12px;
	height: 12px;
	box-shadow: "none";
}
.track {
	border: 1px solid var(--color-light-grey-3);
	border-radius: 8px;
	opacity: 1;
	background-color: var(--color-white);
}
