.table {
	position: sticky;
}
.detail {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 8px 0;
	color: inherit;
	overflow-x: auto;
}
.sidePanel {
	display: flex;
	flex-direction: column;
	padding: 50px;
	width: 100%;
}
.title {
	font-weight: 700;
	font-style: normal;
	font-size: var(--font-size-xl);
	letter-spacing: 0.43px;
	color: var(--color-black);
}
.error {
	margin-top: 16px;
	margin-left: 48px;
	color: var(--shades-grey-600);
}
