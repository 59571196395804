.qa-row {
	text-align: left;
	display: flex;
	flex-direction: row;
	border-right: 0;
	border-left: 0;
	border-top: 0;
	border-bottom: solid 2px var(--shades-grey-300);
	background-color: var(--color-white);
}

.qa-row:hover {
	background-color: var(--shades-grey-100);
}

.qa-row__checkbox {
	place-self: center;
	padding: 0 8px 0 0;
}

.qa-row--selected {
	background-color: var(--shades-grey-300);
}

.checkbox-container {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}
.chips-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.row-container__chips > * {
	margin: 4px;
}
.row-container {
	display: flex;
	justify-content: space-around;
	height: 90px;
	align-items: center;
	width: 100%;
	cursor: pointer;
}

.row-container > *:nth-child(n + 3) {
	width: auto;
	min-width: 100px;
}

.row-container > * {
	padding: 0 8px;
	width: 100%;
}

.date-container {
	display: flex;
	flex-direction: column;
}
.row-container__span {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.row-container__chips {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
}
.row-container__chips--empty {
	width: 0;
}
.comment {
	margin: 2px 0;
}
