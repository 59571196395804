.container {
	width: fit-content;
	background-color: var(--color-white);
}
.dropdown {
	z-index: 1000;
}
.dropdown__container {
	max-width: 30vw;
	padding: 8px;
}
.multiSelect__row {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
}
