.main {
	width: auto;
	padding: 4px 4px 4px;
}
.clauseCounter {
	float: right;
	width: 24px;
	font-size: var(--font-size-xs);
	border-radius: 16px;
	text-align: center;
	color: var(--color-white);
}
.badge {
	background-color: var(--color-dark-blue);
	color: var(--color-white);
	font-size: var(--font-size-xs);
}
.badgeCounter {
	padding-left: 8px;
	padding-right: 8px;
}

.icon__yes {
	color: var(--color-green);
}
.icon__no {
	color: var(--color-red);
}
.icon__pending {
	color: var(--color-orange);
}
.icon__new {
	color: var(--color-dark-blue);
}
.clause__yes {
	background-color: var(--color-green);
}
.clause__no {
	background-color: var(--color-red);
}
.clause__pending {
	background-color: var(--color-orange);
}
.clause__new {
	background-color: var(--color-dark-blue);
}
