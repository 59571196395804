.container {
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	color: var(--color-dark-grey-2);
	padding: 0 var(--spacing-xs);
	background-color: var(--color-orange-20);
}
.usersText {
	font-weight: bold;
	padding-left: 16px;
}
.configureButton {
	margin-left: var(--spacing-2xs);
}
.container > div > span {
	margin-left: var(--spacing-xs);
}
