.container {
	display: flex;
	flex-direction: column;
	background-color: var(--color-light-grey-3);
	height: calc(100vh - var(--viewbanner-height) - var(--navbar-height));
}
.panels-container {
	display: flex;
	flex-direction: row;
	height: calc(100% - 50px);
}
.right-panels-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 80%;
}
.bottom-panels-container {
	display: flex;
	flex-direction: row;
	height: calc(100% - 55px);
}
.top-panel {
	background-color: var(--color-light-grey-3);
	margin-left: 1rem;
}
.search-panel {
	background-color: var(--color-white);
	padding: 0 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: solid 2px var(--shades-grey-300);
}
.search-panel__qa-import {
	display: flex;
}
.left-panel {
	border-right: 2px solid var(--shades-grey-300);
	background-color: var(--color-white);
	width: 20%;
}
.actions-panel {
	border-bottom: 2px solid var(--shades-grey-300);
	padding-left: 1rem;
	padding-top: 0.5rem;
	background-color: var(--shades-grey-300);
	padding-bottom: 5px;
	display: flex;
}
.actions-panel > * {
	margin-right: 4px;
}
.qa-panel {
	background-color: var(--color-white);
	width: 100%;
	height: 100%;
}
.details-panel {
	background-color: var(--shades-grey-500);
	width: 100%;
	max-width: 500px;
	padding: 8px 8px 0 8px;
	overflow-y: auto;
}
.title {
	font-size: var(--font-size-4xl);
	font-weight: 400;
}
