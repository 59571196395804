.importContainer {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding-top: var(--spacing-xs);
}
.file {
	width: calc(100% - 220px);
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--color-dark-grey-1);
}
.fileError {
	composes: font-xs-regular from global;
	color: var(--color-red);
	margin-top: -4px;
	display: block;
}
