.item__title {
	composes: font-md-regular from global;
	color: var(--color-black);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 75%;
}
.item__subTitle {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-2);
	display: inline-flex;
	gap: 4px;
}
.editRow {
	display: flex;
	align-items: center;
	margin: 16px 0;
}
