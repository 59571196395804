.container {
	background-color: var(--shades-grey-100);
	height: calc(100% + 16px);
	padding-top: 8px;
}
.container__newComment {
	margin: 0 var(--spacing-xs) var(--spacing-xs);
	padding: var(--spacing-xs);
}
.list__fullHeight,
.list__emptyText,
.list__shortText,
.list__largeText {
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}
.list__fullHeight {
	height: 100%;
}
.list__emptyText {
	height: calc(100% - 110px);
}
.list__shortText {
	height: calc(100% - 130px);
}
.list__largeText {
	height: calc(100% - 180px);
}
.list__emptyText > *:first-child {
	margin-top: 0px;
}
.textField > div {
	margin-right: 0px;
}
.textField textarea {
	padding-left: var(--spacing-xs) !important;
}
.container__line {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: var(--spacing-xs) 0;
}
.name {
	composes: font-xs-medium from global;
	color: var(--color-black);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.line__rightItems {
	width: 100%;
	height: 50%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	white-space: nowrap;
	color: var(--color-light-grey-1);
}
.btnClassName {
	color: var(--color-dark-grey-2) !important;
	width: 24px !important;
	height: 24px !important;
}
.iconClassName {
	width: 12px !important;
	height: 12px !important;
}
.helperTextClassName {
	width: calc(100% - 38px);
	height: 16px;
	margin-bottom: -12px;
	padding-top: 10px;
	text-align: right;
	font-size: 12px;
}
.noContent {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
}
.noContent__infoIcon {
	margin-right: 8px;
}
.loading {
	text-align: center;
	margin-top: 16px;
}
