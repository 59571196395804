.dialog__title {
	display: flex;
}

.dialog__title .dialog__title-text {
	width: 100%;
	align-self: center;
}

.dialog__content {
	-ms-overflow-style: none;
	color: var(--color-red);
	display: flex;
	flex-direction: column;
}
