.container {
	display: flex;
	position: relative;
	height: 99%;
	overflow: hidden;
	width: calc(100% - 48px);
	margin-left: 48px;
}
.list-container {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	background-color: var(--color-white);
}
