.list {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	max-height: 230px;
	width: 400px;
}
.list__infinite {
	height: fit-content !important;
}
.list__emptyState {
	composes: font-md-regular from global;
	text-align: center;
}
