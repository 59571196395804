.versionGroupIcon {
	position: absolute;
	min-width: 32px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px !important;
	border-radius: 6px;
	cursor: pointer;
	left: 30px;
	z-index: 50;
}
.dropdownList {
	padding: 0px !important;
	width: 32px;
}
.dropdownItem {
	display: flex;
	justify-content: center;
}
.dropdownList :not(:last-child) {
	border-bottom: 1px solid var(--color-light-grey-4);
}
