.greyed {
	color: var(--shades-grey-400);
}
.bold {
	composes: font-sm-medium from global;
	line-height: 32px;
}
.line {
	height: 32px;
	display: flex;
	align-items: center;
	padding: 0;
	width: 100%;
}
.line[data-checkable="true"] {
	cursor: pointer;
}
.line[data-disabled="true"] {
	opacity: 0.4;
	cursor: default;
}
.line > * {
	padding: 0;
}
.checkbox {
	margin-right: 8px;
}
.nameContainer {
	width: 100%;
}
.name {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: calc(100% - var(--spacing-4xl));
}
.name[data-has-flag="true"] {
	max-width: calc(100% - 96px);
}
.lastLine {
	border: none;
}
.accordionContent {
	width: 100%;
}
.accordation {
	width: 100%;
}
.accordationDetails > div {
	width: 100%;
}
.flagButton > button {
	width: 24px !important;
	height: 24px !important;
}
.flagButton {
	margin-left: var(--spacing-2xs);
}
.flagged--icon {
	color: var(--color-red);
}
.flagged--button {
	background-color: var(--color-white);
}
