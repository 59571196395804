.expandable {
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
	padding: 0;
	background: inherit;
	border: inherit;
	cursor: pointer;
	/* Used by Tree to inject height in enclosing div */
	height: 32px;
}
.expandable:disabled,
.expandable[disabled] {
	cursor: default;
	pointer-events: none;
}

.expandable__chevron {
	color: var(--color-light-grey-2);
	border-color: var(--color-light-grey-2);
}
.expandable__btn {
	padding: 0;
}
.expandable__btn:hover {
	background-color: transparent !important;
}
.expandable__chevron:disabled {
	color: transparent !important;
}
