.menu {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	width: 100%;
}
.menu__loader {
	margin: auto;
}
.menu__tree {
	overflow: hidden;
	flex-grow: 1;
}
.tree {
	height: 100%;
}
.menu__cost {
	width: 100%;
	height: 80px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 2px;
	padding: 16px 24px;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 4px;
	background-color: var(--color-white);
	margin: var(--spacing-l) 0;
}
.cost__title {
	composes: font-xs-bold from global;
	text-transform: uppercase;
	color: var(--color-light-grey-2);
}
.cost__content {
	composes: font-lg-medium from global;
	color: var(--color-dark-grey-3);
}
.cost__subText {
	composes: font-2xs-medium from global;
	color: var(--color-light-grey-2);
}
