.tableContainer {
	width: 100%;
	height: 80%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
}
.header__cell {
	font-size: var(--font-size-sm);
	box-shadow: var(--shades-grey-v2-25) 0px 1px 0px;
}
.row__cell {
	composes: font-sm-regular from global;
	min-height: 10% !important;
	max-height: 10%;
	white-space: wrap;
	color: var(--tints-blue);
}
.row__overFlowHandler {
	display: inline-block;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.row__cell--15 {
	max-width: calc(15vw * 0.5);
	min-width: calc(15vw * 0.5);
}
.row__cell--35 {
	max-width: calc(35vw * 0.5);
	min-width: calc(35vw * 0.5);
}
.row__cell--30 {
	max-width: calc(30vw * 0.5);
	min-width: calc(30vw * 0.5);
}
.row__cell--25 {
	max-width: calc(25vw * 0.5);
	min-width: calc(25vw * 0.5);
}
.row__cell--status {
	overflow: hidden;
	white-space: nowrap;
	padding: 5px;
	text-align: right;
	min-width: 256px;
	max-width: 256px;
}
.row__cell--status > button {
	margin-right: 0;
}
.row__editBtn {
	display: inline-block;
}
.row__failed {
	max-width: calc(100% - 32px);
	display: inline-block;
	color: var(--color-red);
	white-space: nowrap;
	text-align: right;
}
.row__progress {
	max-width: 100%;
	color: var(--shades-grey-v2-40);
}
.row__confirmDeleteBtn {
	padding: 0px;
	font-size: var(--font-size-xs) !important;
	border: 1px solid var(--color-orange) !important;
	margin-right: 8px;
	color: var(--color-orange) !important;
}
