.row {
	padding: 8px 8px 8px 0;
	border-bottom: 1px solid var(--color-light-grey-3);
}
.reqId {
	composes: font-sm-regular from global;
	color: var(--color-light-grey-1);
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.row:hover .hoverContainer {
	display: flex !important;
}
.hoverContainer {
	display: none;
}
.infoIcon {
	padding-right: 7px;
}
.content {
	padding-left: 48px;
}
