.loader {
	height: 100%;
	text-align: center;
	padding: 16px;
	position: absolute;
	top: 50%;
	left: 12.5%;
}

.document__row {
	width: 100%;
	margin: 10%;
	padding: 15px;
}

.empty-state__container {
	margin: auto;
	display: inherit;
	flex-direction: column;
	align-items: center;
	text-transform: uppercase;
	justify-content: center;
	height: 100%;
}

.empty-state__container--text {
	padding-top: 100px;
	text-align: center;
	position: absolute;
	left: 8%;
	top: 30%;
	height: 100%;
}
