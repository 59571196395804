.main-container {
	display: flex;
	flex-direction: column;
	padding: 32px;
	overflow: auto;
	height: 100%;
}
.container__header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 24px;
}
.step-title {
	composes: font-md-bold from global;
	display: flex;
	gap: var(--spacing-xs);
}
.tooltip {
	line-height: 19px;
	margin-left: 5px;
	grid-column: 2;
}
.noAccess__container {
	background-color: var(--color-blue-10);
	border-radius: 4px;
	height: fit-content !important;
	padding: var(--spacing-s);
}
