.magicWand > button {
	color: var(--color-blue) !important;
}
.magicWandDisabled > button {
	background-color: transparent !important;
}
.faChevronRight {
	padding-left: var(--spacing-xs);
}
.choicesContainer {
	position: absolute;
	top: 0;
	left: 100%;
	background-color: var(--color-white);
	border-radius: 2px;
	z-index: 1000;
}
