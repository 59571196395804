.tooltip {
	font-size: var(--font-size-xs);
}
.iconContainer {
	width: 16px;
	height: 25px;
}
.button {
	background-color: var(--color-white);
	margin: 0 2px;
	border: solid 1px var(--color-light-grey-4) !important;
	border-radius: 4px !important;
	min-width: 0 !important;
}
.positionButton {
	padding: 4px !important;
}
.positionButton > span {
	width: 25px;
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.assignmentIcon {
	color: var(--color-blue);
	font-size: var(--font-size-xl);
}
.icon--margin-right-10 {
	margin-right: 10px;
}
.iconContainer--clickable {
	cursor: pointer;
}
.button__iconSize {
	font-size: var(--font-size-sm) !important;
}
.positionsCounter {
	height: 5px;
	width: 100%;
	margin-bottom: 4px;
}
.figure {
	margin-bottom: -2px;
}
@media screen and (width < 1440px) {
	.button {
		margin: var(--spacing-2xs) 2px;
		height: 24px !important;
		width: 24px !important;
	}
}
@media screen and (width >= 1440px) {
	.button {
		height: 32px !important;
		width: 32px !important;
	}
}
