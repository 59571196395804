.detections__spacer {
	flex: 1 1;
}
.detections__selection {
	font-weight: bold;
	font-size: var(--font-size-sm);
	border-bottom: 1px solid var(--color-light-grey-3);
}
.detections__selection > * {
	margin: 0 8px;
}
.selection__label {
	vertical-align: middle;
}
.detections__table {
	padding: 16px 0;
}
