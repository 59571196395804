.sidePanelContainer {
	overflow: hidden;
}
.container {
	margin: 16px 16px;
}
.subTitle {
	composes: font-2xl-medium from global;
	margin-bottom: 32px;
}
.filters {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.filters__left {
	display: flex;
	align-items: center;
}
.createNewGroup__btn {
	height: fit-content !important;
	min-height: 32px;
}
.documentList {
	max-height: calc(100vh - 271px);
	margin-top: 32px;
	width: 100%;
	overflow: auto;
}
.precedence {
	left: 36px !important;
}
.menuItem__text {
	composes: font-sm-regular from global;
}
.progress {
	display: flex;
	justify-content: center;
}
