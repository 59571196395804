.container {
	display: flex;
	align-items: center;
	color: var(--color-light-grey-1);
	justify-content: center;
}
.file__loader > div {
	align-items: center;
	display: flex;
}
.container:hover > .file__loader--visible {
	display: none;
}
.container:not(:hover) > .file__loader--hidden {
	display: none;
}
.file__iconBtn {
	width: 20px !important;
	height: 20px !important;
}
.menuList {
	padding: 0px;
}
.menuItem {
	padding: var(--spacing-2xs) var(--spacing-xs);
}
.menuIcon {
	margin-right: var(--spacing-2xs);
}
