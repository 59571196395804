.banner {
	display: flex;
	height: var(--viewbanner-height);
	padding: 8px 16px;
	letter-spacing: 0.72px;
	font-style: normal;
	background-color: var(--shades-grey-100);
	align-items: center;
}
.banner__homeBtn {
	font-size: var(--font-size-sm) !important;
	color: var(--color-dark-grey-1) !important;
	font-weight: normal;
	padding: 0 16px !important;
}
.banner__title {
	font-size: var(--font-size-sm);
	color: var(--color-dark-grey-1);
	padding: 0 16px;
}
.banner__title--last {
	font-weight: bold;
	color: var(--color-dark-grey-4) !important;
}
.banner__spacer {
	flex: 1 1;
}
.banner__option {
	margin: 8px 8px 8px 0;
}
.banner__options > *:last-child {
	margin-right: 0;
}
.menuItem {
	composes: font-sm-regular from global;
}
.menuItem[data-selected="true"] {
	color: var(--color-blue) !important;
	opacity: 1;
}
.menuItem__expand {
	margin-left: 8px;
}
