.selectionOption {
	background-color: var(--shades-grey-200);
	border-radius: 4px;
	padding: 8px 16px;
}
.selectionOption > * {
	margin-right: var(--spacing-l);
}
.selectionOption__selectAllBtn {
	text-transform: none;
}
