.filters__information {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-2xs);
}
.filters__titleWrapper {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}
.titleWrapper__title {
	composes: font-md-bold from global;
	gap: var(--spacing-xs);
	color: var(--color-black);
	max-width: 75%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: 16px;
}
.titleWrapper__notSaved {
	composes: font-xs-regular from global;
	color: var(--color-blue);
	text-transform: lowercase;
}
.filters__descriptionWrapper {
	padding: 0 0 4px;
	flex: 1;
	min-width: 0;
}
.filters__description {
	composes: font-sm-regular from global;
	color: var(--color-light-grey-1);
	gap: 10px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
}
.filters__wrapperContainer {
	display: inline-flex;
	gap: var(--spacing-2xs);
	flex-wrap: wrap;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
}
.filters__wrapper {
	max-width: 100% !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
}
.filters__divider {
	height: 50%;
}
