.tileHeader {
	margin: 0 0 5px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.tileHeader__content {
	margin: 0 0 5px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.tileHeader__content--left {
	color: var(--color-black);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: left;
}
.tileHeader__icon {
	margin: 0 10px 0 0;
}
