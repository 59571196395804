.container {
	padding: 8px;
	border-radius: 2px;
	border: 1px solid var(--color-light-grey-3);
	margin-bottom: 8px;
	display: flex;
	align-items: center;
}
.handleContainer {
	width: 5%;
	padding-bottom: 8px;
}
.handle {
	width: 15px;
	height: 15px;
}
.name {
	width: 55%;
	padding-right: 20px;
}
.nameButton {
	height: fit-content !important;
	text-align: left;
	justify-content: start;
	word-break: break-word;
}
.toCmp {
	width: 10%;
}
.counterContainer {
	border: 1px solid var(--color-light-grey-3);
	height: 50px;
}
.counterContainer > * {
	display: flex;
	align-items: center;
	justify-content: center;
}
.counter {
	color: var(--color-dark-grey-1);
	width: 100%;
	height: 100%;
	font-size: var(--font-size-lg);
	background-color: var(--color-white);
}
.buttonCheckBox {
	margin-top: 1px;
}
