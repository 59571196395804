.tableContainer {
	height: calc(100% - 100px);
	overflow: auto;
}
.header {
	padding: 10px 16px;
}
.tableRow--selected {
	background-color: var(--color-light-grey-4);
}
.tableRow__button {
	padding: 0 4px;
}
.tableRow__actionsCell {
	width: 220px;
}
.tableRow__lockButton {
	margin: 0 8px;
}
