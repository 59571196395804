.container {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	background-color: var(--color-white);
	height: calc(100% - 210px);
	padding: 0 var(--spacing-s);
	flex: 1;
}
.conversationChat {
	height: 100%;
	overflow: auto;
}
.conversationChat[data-caution-banner-disabled="false"] {
	height: 100%;
	margin-top: var(--spacing-xs);
}
.infiniteList {
	height: 100%;
}
.infiniteList__scrollContainer {
	height: 100% !important;
	padding-top: 3px;
}
.conversation__icon {
	line-height: 30px !important;
	height: 30px;
	width: 30px;
	margin-right: var(--spacing-xs);
	composes: font-lg-medium from global;
	background-color: var(--color-dark-blue);
}
.userGuide {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.loading_answer {
	display: flex;
	align-items: center;
}
.skeleton {
	padding-right: var(--spacing-xs);
	width: 100%;
}
.conversation_proposition {
	padding-left: 40px;
	padding-right: 30px;
}
