.container {
	width: 90%;
	margin: 24px auto;
}
.description {
	composes: font-sm-regular from global;
}
.author {
	composes: font-sm-regular from global;
	display: inline-block;
	color: var(--color-light-grey-1);
	min-height: 32px;
	line-height: 32px;
}
.author--comment {
	composes: font-sm-regular from global;
	display: inline-block;
	color: var(--color-light-grey-1);
	min-height: 24px;
	line-height: 24px;
}
.container__footer {
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
}
.actionButton {
	white-space: nowrap !important;
}
.actionButton--comment {
	color: var(--color-light-grey-2) !important;
	height: 24px !important;
	width: 24px !important;
}
.questionLine {
	margin: 2px 0;
}
