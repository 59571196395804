.dialog__content {
	display: flex;
	flex-direction: column;
	height: auto;
}
.dialog__content > * {
	margin: 8px 0;
}
.link-button {
	margin-left: 16px;
}
.confirmation-icon {
	color: var(--color-red);
	margin: 16px 16px 0 0;
	font-size: var(--font-size-4xl);
}
