.row {
	min-height: 30px;
}
.container {
	max-height: 104px;
	max-width: 30vw;
	overflow-x: hidden;
}
.list__row {
	display: flex;
	align-items: center;
	gap: var(--spacing-xs);
	margin-bottom: 8px;
	max-width: 100%;
}
.list__rowLabel {
	composes: font-sm-regular from global;
	padding-right: var(--spacing-xs);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.list__checkbox {
	padding: 0;
	margin: 0;
}
