.menuList {
	padding: 0;
	max-width: 500px;
}
.menuItem {
	padding: 6px 8px !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.menuItem__icon {
	min-width: 16px;
	margin-right: 4px;
}
.menuItem__text {
	composes: font-sm-regular from global;
	color: var(--color-black);
	width: 100%;
	text-align: left;
	white-space: pre-wrap;
	overflow-wrap: break-word;
}
.menuItem:not(:last-of-type) {
	border-bottom: 1px solid var(--color-light-grey-3);
}
