.drawer__fullSize {
	overflow-x: hidden;
	width: 100%;
	height: 100%;
}
.drawer__menu {
	width: 100%;
	position: relative;
	padding-top: 0;
}
.sidePannel__button {
	padding: 0 8px;
}
.letterBtn {
	border-radius: 50%;
	height: 50px !important;
	min-width: 50px;
	width: 50px;
	padding: 0px !important;
}
.letterBtn__letterIcon--selected {
	background-color: var(--color-blue) !important;
}
.letterBtn__letterIcon--unselected {
	background-color: var(--color-dark-grey-1) !important;
}
.button__badge {
	padding: 2px !important;
}
.button__badge span:last-child {
	background-color: var(--color-white) !important;
	color: var(--color-black);
	padding: 4px;
	font-size: 10px;
}
.menuItem {
	text-align: center;
}
.mainContainer {
	width: 100%;
	height: calc(100% - 45px);
	overflow: hidden;
	position: relative;
}
.maxDrawerOpen {
	z-index: 2;
	height: 100%;
	width: 400px;
	transition: width 2.25s cubic-bezier(0.4, 0, 0.6, 1);
}
.maxDrawer {
	width: 350px;
	flex-shrink: 0;
	white-space: nowrap;
	z-index: 2;
	height: 100%;
	float: left;
}
.iconContainer {
	float: left;
	width: 50px;
	height: 100%;
	padding: 0;
	margin: 0;
	border-right: 1px solid var(--color-light-grey-3);
}
.subMenuSection {
	float: left;
	width: calc(100% - 50px);
	max-width: calc(100% - 50px);
	height: 100%;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
}
.closeButton {
	margin: 0;
	padding: 0 !important;
	position: absolute !important;
	right: 0;
	top: 0;
}
.closeButton__icon {
	color: var(--color-light-grey-2);
}
.contentContainer {
	width: 100% !important;
	height: 100%;
}
.expandButtonSection {
	border-radius: 0;
	height: 25px;
	width: 100%;
	background-color: var(--color-light-grey-3);
	padding: 0;
	margin: 0;
}
.expandButtonSection:hover {
	background-color: var(--color-blue);
}
