.container {
	display: flex;
	overflow-y: hidden;
}
.headerContainer {
	display: flex;
	justify-content: space-between;
}
.uploadButton {
	margin: 8px;
}
