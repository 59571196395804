.container {
	overflow: auto;
	width: 100%;
	height: calc(100% - 48px);
}
.loaderContainer {
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
}
