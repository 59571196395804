.leftContainer {
	width: 50%;
	display: flex;
	flex-direction: column;
	border-right: 1px solid var(--color-light-grey-3);
}
.companyTable {
	height: inherit;
	overflow-y: auto;
}
.rightContainer {
	width: 50%;
	border-left: 1px solid var(--color-light-grey-3);
}
.mainContainer {
	width: 90%;
	margin: var(--spacing-xs) auto;
}
.radioSelector {
	margin-bottom: var(--spacing-s);
}
.textTitle {
	composes: font-sm-bold from global;
}
.templating {
	margin-top: var(--spacing-l);
}
.templating > div {
	margin-bottom: var(--spacing-m);
}
.company__mainContainer {
	display: flex;
	flex-direction: column;
	margin: var(--spacing-s);
}
.company__container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: var(--spacing-s) auto;
	width: 80%;
}
.dividerSpace {
	margin: var(--spacing-xs);
}
