.filter {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.filter__options {
	font-size: var(--font-size-sm);
	display: inline-flex;
	width: 100%;
	justify-content: space-between;
}
.filter__search {
	width: 100%;
	margin: 4px 0 8px 0;
}
.filter__stateContainer {
	height: 60px;
	display: flex;
	margin: 8px auto;
	align-items: center;
}
.stateContainer__text {
	font-size: var(--font-size-sm);
	font-style: italic;
}
.options__clear {
	padding-left: 0px !important;
}
.options__results {
	font-weight: 300;
	display: inline-block;
	height: 32px;
	line-height: 32px;
}
