.headline--who {
	composes: font-xs-bold from global;
	color: var(--color-light-grey-1);
	text-transform: uppercase;
	margin-right: 8px;
}
.headline--name {
	composes: font-xs-regular from global;
	margin-right: 8px;
}
.headline--deadline {
	composes: font-xs-bold from global;
	color: var(--color-light-grey-1);
	text-transform: uppercase;
	margin-right: 8px;
}
.headline--date {
	composes: font-xs-regular from global;
}
.importantInformation {
	margin: 11px 0;
}
.importantInformation__priority {
	color: var(--color-white);
	margin-right: 8px;
	text-transform: capitalize;
}
.importantInformation__status {
	color: var(--color-white);
}
.importantInformation--green {
	background-color: var(--color-green);
}
.importantInformation--orange {
	background-color: var(--color-orange);
}
.importantInformation--red {
	background-color: var(--color-red);
}
