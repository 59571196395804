.main {
	height: 100%;
}
.container {
	height: calc(100% - 64px) !important;
	overflow-y: auto;
	background-color: var(--color-white);
	padding: var(--spacing-s);
}
.footer {
	height: 64px;
	border-top: 1px solid var(--color-light-grey-3);
	background-color: var(--color-white);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: var(--spacing-s) 100px var(--spacing-s) var(--spacing-s);
	gap: var(--spacing-xs);
}
