.panel {
	height: 100%;
	width: 100%;
	pointer-events: all;
}
.panel__header {
	display: flex;
	align-items: center;
	height: auto;
	min-height: 64px;
	max-width: 100%;
	background-color: var(--color-white);
	border-bottom: 1px solid var(--shades-grey-300);
	padding: 0 16px;
}
.header__icon {
	padding-right: 8px;
}
.header__icon--large {
	font-size: var(--font-size-4xl);
}
.header__text {
	composes: font-md-bold from global;
	letter-spacing: 0.43px;
	color: var(--color-black);
	text-align: left;
	width: 50%;
}
.header__secondaryActions {
	flex-grow: 1;
	text-align: right;
}
.panel__content {
	overflow-y: auto;
	height: calc(100% - 64px);
	width: 100%;
	background-color: var(--color-light-grey-4);
}
