.userDetailsContainer {
	width: 100%;
	border: 1px dashed var(--color-light-grey-3);
	min-height: 100px;
}
.userDetailContainer {
	border: 1px solid var(--color-light-grey-3);
	min-width: 200px;
	width: 50%;
}
@media (width >= 1536px) {
	.userDetailContainer {
		width: 33% !important;
	}
}
@media (width < 1536px) and (width >= 1200px) {
	.userDetailContainer {
		width: 50% !important;
	}
}
@media (width < 1200px) {
	.userDetailContainer {
		width: 100% !important;
	}
}
.wrapperRole {
	width: 100%;
	column-gap: 0px;
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
}
.subContainer {
	padding: 8px;
	border-radius: 2px;
	border: 1px solid var(--color-light-grey-3);
	display: flex;
	justify-content: space-between;
}
.title {
	padding: 8px;
	font-size: var(--font-size-sm);
	margin-bottom: 0px;
}
.buttonDelete {
	padding: 0px;
	border-radius: 2px;
}
.card {
	width: 100%;
	text-align: center;
	border: 1px solid var(--color-light-grey-3);
	height: 100%;
}
.cardHeader {
	display: flex;
	justify-content: center;
	align-items: center;
}
.card__content {
	width: 100%;
	min-height: 120px;
	padding: 4px 4px 4px 4px;
}
.userCard__checkbox {
	height: fit-content;
	padding: 1px;
	padding-bottom: 15px;
}
.card__archivedDoc {
	background-color: var(--color-light-grey-3) !important;
}
.buttonByUser {
	margin-bottom: 15px;
	font-size: var(--font-size-xs) !important;
	font-weight: bold;
	height: fit-content !important;
	min-height: 32px;
	text-align: left;
	justify-content: start;
	word-break: break-word;
}
.buttonRole {
	padding: 10px !important;
	font-weight: bold;
}
.canvasDocCard__container {
	margin: 0 10px;
}
.canvasDocCard__badge {
	color: var(--color-black);
	background-color: var(--shades-grey-200);
}
.canvasDocCard {
	height: 40px;
	line-height: 50px;
}
