.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 48px;
	background-color: var(--shades-grey-200);
	padding: 0;
	width: 100%;
}
.versioningButton {
	background-color: var(--color-white) !important;
	border-radius: var(--spacing-s);
	flex-direction: row-reverse;
}
.versioningButton__active {
	background-color: var(--color-blue) !important;
}
.versioningButton__icon {
	font-size: var(--font-size-xs) !important;
	margin: 0 -12px 0 8px;
}
.secondaryHeaderRow__name {
	display: inline-flex;
	align-items: center;
	background-color: var(--shades-grey-200);
	max-width: calc(100% - 40px);
	border-radius: 4px;
	padding: 0 8px;
	margin-left: 8px;
}
.secondaryHeaderRow__documentName {
	max-width: 300px;
	margin-left: var(--spacing-xs);
}
.verticalDivider {
	background-color: var(--shades-grey-400);
	height: 24px;
}

@media screen and (width <= 1150px) {
	.secondaryHeaderRow__name[data-right-panel="true"] > div {
		display: none !important;
	}
}
@media screen and (width <= 950px) {
	.secondaryHeaderRow__name > div {
		display: none;
	}
}
