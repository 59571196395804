.infiniteSelector {
	padding: 10.5px 12px;
}
.infiniteSelector__list {
	max-height: 400px;
}
.selectAllContainer {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 16px 0 -16px 17px;
}
.selectAllContainer__count {
	composes: font-md-bold from global;
}
.actionsRow {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.actionsRow__separator {
	margin: 0 8px;
	color: var(--shades-grey-500);
	font-size: 20px;
}
.actionsRow__actionText {
	color: var(--shades-grey-500);
	text-transform: uppercase;
	composes: font-sm-bold from global;
}
.actionsRow__addButton {
	margin-left: 16px;
}
