.popoverPaper {
	border: 1px solid var(--tints-blue-10);
	border-radius: 4px;
	padding: 8px;
	min-width: 27vw;
	max-width: 27vw;
	min-height: 50vh;
	max-height: 50vh;
	overflow: hidden;
}
.popoverRoot {
	z-index: 1000;
}
.popoverRootShowMore {
	z-index: 1;
}
.title_container {
	display: flex;
	justify-content: space-between;
	padding-bottom: 0.5em;
	border-bottom: 1px solid var(--color-light-grey-4);
}
.popover_title {
	font-size: var(--font-size-xl);
	font-weight: bold;
	margin-top: 0.25em;
	margin-left: 0.5em;
}
.dropDownMenu {
	padding: 9px 10px !important;
	font-weight: 400;
	height: 48px !important;
	border-radius: 0;
	text-transform: none;
	border: 3px solid transparent;
}
.dropDownMenu:checked {
	border: 0px !important;
}
.dropDownMenu:hover {
	color: var(--color-blue);
}
.dropDown--contained {
	background-color: var(--color-blue) !important;
	color: var(--color-white) !important;
	border-radius: 0px;
}
.dropDown__icon--open {
	background-color: var(--color-white) !important;
}
.dropDownMenu:hover .dropDownMenu__icon {
	color: var(--color-blue);
}

.mentions_badge .MuiBadge-badge {
	transform: scale(1) translate(100%, -100%);
}

.mentions_badge:hover .MuiBadge-badge {
	transform: scale(1) translate(100%, -100%);
}
.badgeStyle {
	transform: scale(1) translate(100%, -100%);
	transform-origin: 100% 0%;
}
.badgeStyleUnreadOpen {
	background-color: var(--color-white);
}
.badgeStyleUnreadClose {
	background-color: var(--color-blue);
}
.menu_mark_all_as_read_btn {
	border: 1px solid var(--shades-grey-400) !important;
	border-radius: 4px;
}
.tabs > * {
	background-color: var(--color-white) !important;
	padding: 0 16px !important;
}

.list_container {
	height: calc(52vh - 82px);
}
.list_container > div > div > div > *:last-child > div {
	margin-bottom: 0;
}
