.tabs > * {
	padding: 0 8px !important;
}
.panel {
	height: 100%;
	pointer-events: all;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: calc(100% - 64px);
	background-color: var(--color-white);
}
.panel__content {
	overflow-y: hidden;
	height: calc(100% - 64px);
	width: 100%;
}
.panel__subcontent {
	overflow-y: auto;
	height: calc(100% - 48px);
}
