.directory {
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
	padding: 0;
	background: inherit;
	border: inherit;
	cursor: pointer;
	overflow: hidden;
	height: 20px;
}
.directory:disabled,
.directory[disabled] {
	pointer-events: none;
	cursor: default;
}

.directory__label {
	composes: font-sm-regular from global;
	color: var(--color-black);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.directory__label--current {
	color: var(--color-blue);
}
.directory__label:disabled,
.directory__label[disabled] {
	color: var(--color-light-grey-1);
}
.directoryTree__container {
	width: 90% !important;
}
