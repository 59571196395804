.block {
	border: solid 4px var(--shades-grey-500);
}
.block > * {
	box-shadow: none;
}
.header {
	composes: font-sm-medium from global;
	display: flex;
	flex-direction: row;
	margin-bottom: 8px;
	align-items: center;
}
.accordion__summary {
	display: flex;
	flex-direction: row;
}
