.container {
	display: flex;
	align-items: center;
	padding: var(--spacing-xs) var(--spacing-m);
}
.container[data-display-roles="true"] > div:nth-child(2) {
	width: calc(100% - 400px);
}
.container[data-display-roles="false"] > div:nth-child(1) {
	width: calc(100% - 274px);
}
