.dialog__textField {
	width: 100%;
	margin-top: 17px;
}

.dialog__textField > :nth-child(2) {
	margin-left: 13px;
}
.chatNameDialog {
	padding-top: 16px !important;
}
