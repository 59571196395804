.list {
	display: flex;
	flex-direction: column;
}
.list__flat {
	overflow-y: auto;
	max-height: 230px;
}
.list__emptyState {
	composes: font-md-regular from global;
	text-align: center;
}
