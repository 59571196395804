.tabPanel {
	padding: 0 var(--view-padding-lateral);
	height: calc(100% - 16px);
}
.tabPanel--no-padding {
	padding: 0;
	height: calc(100% - 16px);
}
.tabPanel--is-comments {
	height: 100%;
}
