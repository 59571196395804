.related-container {
	border-color: var(--shades-grey-300);
	border-style: solid;
	padding: 10px;
	background-color: var(--color-white);
}
.document-line {
	overflow-wrap: break-word;
}
.related-list {
	margin-left: 15px;
}
.document-line__button-container {
	width: 100%;
	text-align: right;
	margin-bottom: 16px;
}
.open-document {
	white-space: nowrap;
}
