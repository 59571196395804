.helperText {
	composes: font-xs-regular from global;
	margin-top: 4px;
	color: var(--color-light-grey-1);
}
.wrapper--fullWidth {
	width: 100%;
}
.tf[data-size="sm"] {
	padding: 0;
}
.tf[data-size="sm"] label {
	top: -11px;
}
.tf[data-size="md"] {
	padding-top: 8px;
}
.inputProps {
	composes: font-sm-regular from global;
	padding-bottom: 7px;
	padding-top: 8px;
}
