.container {
	margin: var(--spacing-xs);
	padding: var(--spacing-xs);
}
.container__line {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
}
.container__line[data-is-closed-conversation="true"],
.container__inner[data-is-closed-conversation="true"] {
	color: var(--color-light-grey-1);
}
.container__line > *:first-child {
	margin-right: auto;
}
.container__inner:first-child {
	margin-top: var(--spacing-xs);
}
.container__inner--margin {
	margin-top: var(--spacing-xs);
}
.replyContainer {
	margin-top: var(--spacing-xs);
}
.displayer {
	padding: 0 !important;
	margin: var(--spacing-2xs) 0 0;
	composes: font-xs-regular from global;
	font-size: var(--font-size-xs) !important;
	color: var(--color-blue);
	height: 24px !important;
}
.line__leftItems {
	display: flex;
	justify-content: flex-start;
}
.comment {
	overflow-wrap: break-word;
	align-self: center;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	composes: font-xs-regular from global;
}
.comment:first-line {
	width: 10px;
}
.username {
	composes: font-xs-medium from global;
	margin-right: var(--spacing-2xs);
}
.text {
	composes: font-xs-regular from global;
	text-transform: lowercase;
	margin-right: var(--spacing-xs);
}
.grayed {
	display: contents;
	color: var(--color-light-grey-1);
}
.date {
	white-space: nowrap;
	text-transform: lowercase;
	composes: font-xs-regular from global;
}
.icon {
	font-size: var(--font-size-md) !important;
}
.commentLine {
	margin: 2px 0;
}
.textField {
	width: 100%;
}
.textField > div {
	margin-right: 0px;
}
.textField textarea {
	padding-left: var(--spacing-xs) !important;
}
.helperTextClassName {
	width: calc(100% - 38px);
	height: 16px;
	margin-bottom: -12px;
	padding-top: 10px;
	text-align: right;
	font-size: 12px;
}
.actionsContainer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: var(--spacing-xs);
}
.actionButton {
	height: var(--spacing-m) !important;
	width: var(--spacing-m) !important;
}
.actionButton__icon {
	height: var(--spacing-xs);
}
.line__rightItems {
	width: 100%;
	height: 50%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	white-space: nowrap;
	color: var(--color-light-grey-1);
}
.btnClassName {
	color: var(--color-dark-grey-2) !important;
	width: var(--spacing-m) !important;
	height: var(--spacing-m) !important;
}
.iconClassName {
	width: 12px !important;
	height: 12px !important;
}
.conversation__container > div {
	margin-left: 0 !important;
	width: 100% !important;
}
.menuItem__text {
	composes: font-sm-regular from global;
}
