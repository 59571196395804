.email-cell {
	composes: font-sm-regular from global;
}
.role-cell__dropdown {
	font-size: var(--font-size-sm);
	width: 80%;
}
.inputRole {
	composes: font-sm-regular from global;
	line-height: 1.5rem;
}
.menu {
	composes: font-sm-regular from global;
}
.description-cell {
	composes: font-xs-regular from global;
	max-width: 400px;
}
.row > * {
	height: 64px;
}
