.reviews__empty-state {
	text-align: center;
	font-style: italic;
}
.positions__status {
	word-break: break-word;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border-bottom: 0;
}
.status {
	white-space: nowrap;
}
.table-cell--bold {
	font-weight: bold;
	border-bottom: 0;
}
.mention_name {
	color: inherit !important;
	text-decoration: none !important;
}
.mention_paragraph {
	margin-block: 0;
	text-align: left;
}
.position_splitter {
	margin-left: 0.4em;
	margin-right: 0.4em;
}
.table_row {
	border-bottom: 1px solid rgba(224, 224, 224, 1);
}
