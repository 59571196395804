.pdfHeader {
	transition: width 0.3s;
}
.pdfContainer {
	transition: width 0.3s;
}
.verticalNavbar {
	top: 135px !important;
	height: calc(100% - 135px) !important;
	z-index: 10;
}
.verticalNavbar[data-caution-banner-hidden="false"] {
	top: 175px !important;
	height: calc(100% - 175px) !important;
}
.menuContentClassName {
	width: 30vw !important;
}
