.panelContainer {
	overflow: auto;
}
.panelContainer > * {
	box-shadow: none;
	border-radius: 4px;
}
.panelContainer > * :before {
	background-color: var(--color-white);
}
.rightIcon {
	color: var(--color-blue);
	transition: none;
	font-size: var(--font-size-2xl) !important;
}
.rightIcon--translucent {
	opacity: 0;
	transition: opacity 1s;
}
.emptyState {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.emptyStateIcon {
	margin-right: 8px;
	font-size: var(--font-size-2xl) !important;
}
