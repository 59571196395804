.opportunity {
	width: 100%;
	height: 100%;
}
.main__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--color-white-secondary);
	height: calc(100vh - var(--viewbanner-height) - var(--navbar-height));
}
