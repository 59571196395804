.StyledAccordionDetails__root {
	padding: 0 !important;
}

.title {
	display: flex;
	flex-direction: column;
	margin: 16px 0;
	align-items: center;
	justify-items: center;
}

.name {
	font-weight: bold;
}
.date {
	font-weight: italic;
}

.none {
	display: flex;
	text-transform: uppercase;
}

.none > span {
	margin: 50% auto;
}
.reqDetect {
	display: flex;
	flex-direction: row;
	margin: 16px 0px 0px 16px;
	align-items: center;
	justify-items: center;
}
.reqDetect__text {
	margin-right: 1rem;
}
.customAccordionClass {
	box-sizing: border-box !important;
	box-shadow: 0px 1px 2px 0px var(--color-light-grey-3) !important;
}
.customAccordionDetailsClass {
	padding: 10px 0px !important;
}
