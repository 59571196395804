.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: var(--spacing-2xs) var(--spacing-xs);
	padding: var(--spacing-xs);
}
.container__line {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	composes: font-sm-regular from global;
}
.result {
	overflow-wrap: break-word;
	margin-bottom: 8px;
	composes: font-sm-regular from global;
}
.question {
	overflow-wrap: break-word;
	margin-bottom: 8px;
	composes: font-sm-regular from global;
}
.container__references {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: center;
}
.line__rightItems {
	overflow: auto;
	min-width: 24px;
}
.container__line > * {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.container__line:first-child {
	margin-bottom: 8px;
}
.container__line:first-child > *:first-child {
	max-width: 88%;
	display: flex;
	flex-direction: row;
}
.buttonsContainer {
	min-width: 70px;
	min-height: 35px;
	text-align: right;
	margin-top: 4px;
}
.line__leftItems {
	display: flex;
	justify-content: flex-start;
}
.comment {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	align-self: center;
	composes: font-sm-regular from global;
}
.comment:first-line {
	width: 10px;
}
.username {
	composes: font-sm-bold from global;
	text-transform: capitalize;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: 4px;
}
.clientId {
	composes: font-xs-regular from global;
	color: var(--shades-grey-400);
	margin-right: 8px;
	font-weight: bold;
	white-space: nowrap;
}
.date {
	white-space: nowrap;
	color: var(--shades-grey-400);
	composes: font-xs-regular from global;
	margin-right: 8px;
}
.divider {
	margin: 0 8px;
	height: 25px;
}
.icon {
	font-size: var(--font-size-md) !important;
	margin: 0 8px;
}
.showMoreButton {
	color: var(--color-blue);
	margin: 0 24px;
	font-weight: 400;
}
.showMoreButton:hover {
	color: var(--color-dark-blue);
}
.showMoreContainer {
	position: absolute;
	right: 85px;
	bottom: 13px;
	width: -moz-fit-content;
	width: fit-content;
	background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 15%);
	padding: 0;
	font-size: var(--font-size-sm);
}
.showMoreContainer[data-has-references="true"] {
	bottom: 45px;
}
.chip {
	overflow: auto;
}
.chip[data-status="DRAFT"] {
	background-color: var(--color-white);
	color: var(--shades-grey-400);
	border: 1px solid var(--shades-grey-400);
}
.chip[data-status="ANSWERED"] {
	color: var(--color-white);
	background-color: var(--color-green);
}
.chip[data-status="SENT"] {
	color: var(--color-white);
	background-color: var(--color-orange);
}
.chip[data-status="FROM_COMPETITION"] {
	color: var(--color-white);
	background-color: var(--shades-grey-800);
}
.questionLine {
	margin: 2px 0;
}
