.area {
	z-index: 2;
	position: absolute;
	mix-blend-mode: multiply;
}
.area[data-clickable="true"] {
	cursor: pointer;
}
.area[data-req-content="false"]:hover,
.area[data-hovered="true"][data-req-content="false"],
.area[data-req-content="false"][data-is-in-selection="true"],
.area[data-req-content="false"][data-is-search-result="true"] {
	background-color: var(--color-light-grey-4) !important;
	z-index: 10;
}
.area[data-req-content="true"] {
	background-color: var(--exigence);
}
.area[data-req-content="true"]:hover,
.area[data-req-content="true"][data-is-in-selection="true"],
.area[data-req-content="true"][data-hovered="true"],
.area[data-req-content="true"][data-is-search-result="true"] {
	background-color: var(--exigence-focus) !important;
	z-index: 10;
}
.area[data-redr-content="true"] {
	background-color: var(--color-orange-40);
}
.area[data-info-content="true"] {
	border: transparent;
}
.area[data-redressing="true"] {
	background-color: var(--color-blue-10) !important;
}
.search-indicator {
	z-index: 2;
	width: var(--spacing-2xs);
	background-color: var(--search);
}
.search-indicator[data-is-selected-result="true"],
.search-indicator[data-hovered="true"] {
	z-index: 3;
	background-color: var(--search-focus);
}
