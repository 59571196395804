.cell {
	padding: 10px;
}
.tableRow {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: stretch;
	flex-grow: 1;
}
.tableRow td {
	width: 100%;
}
.tableRow--selected {
	background-color: var(--color-light-grey-4);
}
.parentName {
	padding-left: var(--spacing-xs);
}
.childName {
	padding-left: var(--spacing-s);
}
.companyRow {
	width: 100%;
	height: 100%;
}
