.offerButton__icon--active {
	color: var(--color-blue);
}

.qAActionsButton__icon--disabled {
	color: var(--color-light-grey-1);
}

.statusInput {
	background-color: var(--color-white);
	border-radius: 4px;
	width: 100px;
	font-size: var(--font-size-sm);
}
