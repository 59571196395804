.row {
	display: flex;
	height: 28px;
	min-height: 28px;
	align-items: center;
	gap: var(--spacing-2xs);
	width: 100%;
}
.row > *:first-child {
	padding-left: 0;
	padding-right: 0;
}
.row__content {
	width: calc(100% - 28px);
}
.row__documentName {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	composes: font-sm-regular from global;
}

.row__documentRef {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	composes: font-xs-regular from global;
	color: var(--color-light-grey-1);
}
