.empty-state {
	display: flex;
	height: 50%;
	align-items: center;
	justify-content: center;
}
.empty-state__container {
	margin: auto;
	display: inherit;
	flex-direction: column;
	text-transform: uppercase;
	align-items: center;
	justify-content: center;
}

.empty-state__container--text {
	padding: 16px;
	text-align: center;
	position: absolute;
	left: 57%;
	top: 40%;
}
.root {
	width: 100%;
}
.container {
	border-bottom: solid var(--color-light-grey-3) 1px;
	height: 80%;
}
