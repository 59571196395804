.wrapper-canvas {
	height: 100%;
	overflow: hidden;
	width: 100%;
}
.precedence {
	width: 35%;
	height: calc(100% - 42px);
	float: left;
	white-space: normal;
	text-align: justify;
	overflow-y: auto;
	border: 1px solid var(--color-light-grey-3);
}
.users {
	width: 15%;
	height: calc(100% - 42px);
	float: left;
	white-space: normal;
	text-align: justify;
	overflow-y: auto;
	border: 1px solid var(--color-light-grey-3);
	background-color: var(--color-white);
}
.details {
	width: 50%;
	height: calc(100% - 42px);
	float: left;
	white-space: normal;
	text-align: justify;
	overflow-y: auto;
	border: 1px solid var(--color-light-grey-3);
	background-color: var(--color-white);
}
.input-props {
	margin: 6px;
	padding: 6px;
}
.banner {
	background-color: var(--shades-grey-400);
	color: var(--color-white);
	text-align: center;
	padding: 6px;
}
.filter-selector {
	position: sticky;
	top: 0px;
	z-index: 1;
	border: solid var(--color-light-grey-3);
	background-color: var(--color-white);
	border-width: 1px 0px 1px 0px;
	padding-bottom: 6px;
}
.filter {
	width: 100%;
}
.filter__input {
	width: calc(100% - 110px);
	float: left;
}
.filter__btn {
	float: left;
	padding: 20px 4px 0px 4px;
}
.selector {
	display: flex;
	height: 44px;
	align-items: center;
	width: 100%;
}
.btn-select-all {
	margin-left: 16px;
	margin-right: 8px;
}
.selector__divider {
	height: 30px;
	margin: 0 8px;
}
.selector__divider-text {
	color: var(--color-light-grey-2);
}
.selector__btn-add-role {
	display: inline-block;
	margin-left: 16px;
}
.container {
	width: 100%;
}
.main-container {
	height: 100%;
	overflow: hidden;
	width: 100%;
}
.user-title {
	height: 90%;
	width: 100%;
}
.error-network {
	font-size: var(--font-size-4xl);
	color: var(--color-light-grey-3);
	position: relative;
	float: left;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding-left: 20px;
	padding-right: 20px;
}
.error-text {
	margin-top: 0 px;
	height: calc(100% - 50px);
	width: 100%;
}
.loaderContainer {
	width: 100%;
	text-align: center;
	margin-top: 50px;
}
.roleContainer {
	width: 29%;
	margin-left: auto;
	padding-right: 16px;
	white-space: nowrap;
}
.role {
	composes: font-lg-medium from global;
	display: inline-block;
	width: 33%;
	text-align: center;
	color: var(--color-dark-grey-1);
}
.addRoleButton {
	white-space: nowrap;
}
.resultsCount {
	white-space: nowrap;
}
