.container {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: var(--spacing-xs);
	background-color: var(--shades-grey-100);
	padding: 8px;
	height: calc(100% + 16px);
	overflow: auto;
}
.tabs {
	top: -1px;
	padding: 0px 8px;
	width: calc(100% - 8px);
	box-shadow: none;
	background-color: var(--shades-grey-100);
	margin: -8px 0px -8px 4px;
}
.tabs > div {
	padding: 0;
}
.tabs span {
	position: absolute;
	top: 35px;
}
.tabs > * {
	background-color: var(--shades-grey-100);
	padding-left: 8px;
}
.tabs button {
	font-size: 12px;
	font-weight: 500;
}
.filter {
	margin: 0;
}
.filterContainer {
	width: 100%;
}
.emptyState {
	width: 100%;
	margin-top: 0px;
}
