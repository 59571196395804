.StyledAccordionDetails__root {
	padding: 0 !important;
}
.text {
	display: flex;
	width: 100%;
	align-items: center;
	padding: 0 var(--spacing-s);
	margin-bottom: var(--spacing-s);
}
.text > div:first-of-type {
	width: 60%;
	margin-right: var(--spacing-s);
}
