.dialog__title {
	display: flex;
}

.dialog__title .dialog__title-text {
	width: 100%;
	align-self: center;
}

.dialog__content {
	-ms-overflow-style: none;
}

.dialog__content::-webkit-scrollbar {
	display: none;
}

.dialog__content .dialog__field {
	width: 100%;
	text-transform: capitalize;
}
