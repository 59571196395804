.menu__item {
	color: var(--color-black) !important;
	display: flex;
	justify-content: space-between;
	gap: 16px;
}
.item__labelLeft {
	grid-gap: 4px;
	gap: 8px;
	display: inline-flex;
	overflow: hidden;
	align-items: center;
}
.item__title {
	composes: font-sm-regular from global;
	color: var(--color-black);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.item__title--disabled {
	font-weight: bold;
	color: var(--color-blue);
}
.item__results {
	composes: font-sm-regular from global;
	color: var(--color-light-grey-1);
}
