/* 
	* ViewBanner => 48px
	* ReviewHeader => 42px
*/
.myReview {
	width: 100%;
	height: calc(100% - 46px) !important;
	overflow-y: hidden;
}
.myReview[data-caution-banner-disabled="false"] {
	height: calc(100% - 87px) !important;
}
.mainDisplay {
	width: 100%;
	display: flex;
	height: calc(100% - 90px);
}
.mainDisplay[data-caution-banner-disabled="false"] {
	height: calc(100% - 49px);
}
.sideDrawer__main {
	width: 20vw;
	margin: 0 !important;
	padding: 0 var(--spacing-2xs) 0 var(--spacing-xs);
	overflow: auto;
}
.sideDrawer__main[data-open="false"] {
	width: 50px;
	min-width: 50px;
}
.sideDrawer__main[data-open="true"] {
	min-width: 15vw;
	max-width: 25vw;
}
.resizer {
	border-right: 1px solid var(--color-light-grey-3);
	width: 4px;
}
.siderDrawer__content {
	background-color: var(--shades-grey-50);
	margin-left: 0px !important;
}
.siderDrawer__content[data-open="false"] {
	width: calc(100% - 50px) !important;
}
.siderDrawer__content[data-open="true"] {
	width: 80vw;
}
.banner {
	background-color: transparent !important;
}
.html {
	scroll-behavior: smooth;
}
