.table {
	width: 100%;
	table-layout: fixed;
}
.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 50px;
}
.row > * {
	height: 64px;
	background-color: var(--color-white);
}
.empty-row {
	border: none;
	height: 200px;
	width: 100%;
}
.empty-row__text {
	display: block;
	padding-bottom: 16px;
}
.empty-state {
	margin: 0 auto;
	width: 100%;
}
.check-cell {
	width: 50px;
	padding: var(--spacing-s) var(--spacing-xs);
}
.name-cell {
	width: 30%;
	padding: var(--spacing-s) var(--spacing-xs);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.email-cell {
	width: 40%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
