:root {
	--font-size-2xs: 0.625rem; /* 10px */
	--font-size-xs: 0.75rem; /* 12px */
	--font-size-sm: 0.875rem; /* 14px */
	--font-size-md: 1rem; /* 16px */
	--font-size-lg: 1.125rem; /* 18px */
	--font-size-xl: 1.25rem; /* 20px */
	--font-size-2xl: 1.5rem; /* 24px */
	--font-size-3xl: 1.75rem; /* 28px */
	--font-size-4xl: 2rem; /* 32px */
	--font-size-5xl: 2.5rem; /* 40px */
	--font-size-8xl: 4rem; /* 64px */
	--font-size-10xl: 5rem; /* 80px */
}

.font-5xl-bold {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-5xl);
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-5xl-medium {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-5xl);
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-5xl-regular {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-5xl);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-4xl-bold {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-4xl);
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-4xl-medium {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-4xl);
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-4xl-regular {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-4xl);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-3xl-bold {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-3xl);
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-3xl-medium {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-3xl);
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-3xl-regular {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-3xl);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-2xl-bold {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-2xl);
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-2xl-medium {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-2xl);
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-2xl-regular {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-2xl);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-xl-bold {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-xl);
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-xl-medium {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-xl);
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-xl-regular {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-xl);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-lg-bold {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-lg);
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-lg-medium {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-lg);
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-lg-regular {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-lg);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-md-bold {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-md);
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-md-medium {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-md);
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-md-regular {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-md);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-sm-bold {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-sm);
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-sm-medium {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-sm);
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-sm-regular {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-sm);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-xs-bold {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-xs);
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-xs-medium {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-xs);
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-xs-regular {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-xs);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-2xs-bold {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-2xs);
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-2xs-medium {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-2xs);
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.font-2xs-regular {
	font-family: Roboto, sans-serif;
	font-size: var(--font-size-2xs);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
