.dialog__radioGroup {
	margin: 32px 0 0 0;
}
.dialog__radioGroup > * {
	text-align: left;
}
.dialog__text {
	font-size: var(--font-size-md);
}
.dialog__text--action {
	padding-top: 16px;
	display: block;
}
