@media screen and (width >= 1200px) {
	.container {
		margin-left: 20%;
		margin-right: 20%;
	}
}
@media screen and (width >= 800px) and (width <1200px) {
	.container {
		margin-left: 10%;
		margin-right: 10%;
	}
}
@media screen and (width < 800px) {
	.container {
		margin-left: 5%;
		margin-right: 5%;
	}
}

.pageTitle {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 0;
}
.pageSubtitle {
	font-size: 14px;
	font-weight: 400;
	color: var(--color-light-grey-1);
	margin-top: 0;
	margin-bottom: 16px;
}

.section {
	border: 1px solid var(--color-light-grey-4);
	border-radius: 4px;
	padding-left: 16px;
	padding-right: 16px;
}

.sectionTitle {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 16px;
}
.subsectionTitle {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
}
.subsectionContent {
	font-size: 12px;
	font-weight: 400;
	color: var(--color-light-grey-1);
	margin-top: 0;
	margin-bottom: 16px;
}
.subsectionContainer {
	padding-left: 32px;
	display: flex;
}
.subsectionContainerLeft {
	flex: 10;
}
.subsectionContainerRight {
	flex: 2;
}
.subsectionContainerTitle {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
	margin-top: 0;
}
.subsectionContainerContent {
	font-size: 12px;
	font-weight: 400;
	color: var(--color-light-grey-1);
	margin-top: 0;
	margin-bottom: 16px;
}
.selector > div {
	font-size: 14px;
}
.selector > div > div {
	padding-top: 4.5px;
	padding-bottom: 4.5px;
}
