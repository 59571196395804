.import__Container {
	width: 60%;
	display: flex;
	gap: 16px;
	border: 1px dashed #3880e8;
	border-radius: 4px;
	box-sizing: border-box;
	margin: 17px auto 11px;
	padding: 16px;
}
.import__icon {
	width: 32px !important;
	height: 32px;
}
.import__content {
	composes: font-xs-regular from global;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.import__title {
	composes: font-sm-medium from global !important;
}
.import__text {
	margin: var(--spacing-s) 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-s);
}
.import__text--inline {
	margin-top: var(--spacing-s);
}
.import__btn {
	color: var(--color-blue);
	cursor: pointer;
	margin-left: 4px;
}
.emptyState__Container {
	height: 80%;
	width: 60%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	border: 1px dashed #3880e8;
	border-radius: 4px;
	box-sizing: border-box;
	margin: auto;
}
.emptyState__import__icon {
	width: 32px;
	height: 32px;
	padding-bottom: 16px;
}
.emptyState__import__btn {
	composes: font-sm-regular from global;
	line-height: 1rem;
	background-color: var(--color-blue);
	margin-left: 8px;
	padding: 8px;
	border-radius: 4px;
	color: var(--color-white);
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
	cursor: pointer;
	white-space: nowrap;
	height: 32px;
	width: fit-content;
}
.checkboxLabel {
	margin-top: 12px;
}
.checkboxLabel__title {
	composes: font-sm-regular from global;
}
.checkboxLabel__subtitle {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-1);
}
