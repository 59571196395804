.file {
	width: 100%;
	height: 38px;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	align-items: center;
	gap: var(--spacing-xs);
	padding: var(--spacing-xs);
}
.file > div:first-of-type {
	width: 16px;
	margin-right: 2px;
}
.file:hover {
	background-color: var(--shades-grey-100);
}
.file:not(:last-child) {
	border-bottom: 1px solid var(--color-light-grey-2);
}
.file__label {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	flex-grow: 1;
	align-content: flex-start;
	align-items: flex-start;
	justify-content: center;
	overflow: hidden;
}
.file__name {
	composes: font-sm-regular from global;
	color: var(--color-black);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
}
.file__message {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-2);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
}
.file:hover > .file__loader--visible {
	display: none;
}
.file:not(:hover) > .file__loader--hidden {
	display: none;
}
.file__loader {
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
}
.file__loader > * {
	margin-left: var(--spacing-xs);
}
.file__iconBtn {
	width: 20px !important;
	height: 20px !important;
}
