.iconsContainer {
	display: flex;
	justify-content: flex-end;
	gap: 2px;
}
.iconsContainer__container {
	width: 24px;
	height: 24px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
.iconsContainer__placeholder {
	display: inline-block;
	height: 24px;
	width: 24px;
}
