.dialogTitle {
	text-align: center;
}
.dialogPaper {
	min-height: 80vh;
	max-height: 80vh;
}
.frontbackhashes {
	width: 100%;
	height: 50px;
	background-color: var(--color-light-grey-3);
	text-align: center;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 12px;
}
.content {
	padding-top: 18px;
	width: 100%;
	overflow-y: auto;
	text-align: justify;
}
.versionItem {
	padding: 8px;
	text-align: justify;
	width: 100%;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 6px;
}
.versionNumber {
	font-size: var(--font-size-xl);
	font-weight: bold;
	padding: 2px;
}
.versionDate {
	font-size: var(--font-size-sm);
	padding: 2px 2px 2px 16px;
}
.versionContent {
	padding: 2px 2px 2px 32px;
}
.changeItem {
	padding: 2px 2px 2px 48px;
}
