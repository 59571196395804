.header {
	display: flex;
	background-color: var(--color-white);
}
.header__filters {
	align-items: center;
	display: flex;
}
.header__filters > button:last-of-type {
	margin-left: var(--spacing-xs);
}
.toggleBtns__btn {
	height: 32px;
	text-transform: none;
}
.toggleBtns__btn[aria-pressed="true"] {
	background-color: var(--color-blue-40) !important;
}
.header > div:first-child > div:nth-child(2) {
	margin-left: var(--spacing-xs);
}
.header > div:nth-child(2) {
	flex: 1;
}
.labelIcon {
	text-transform: none;
}
