.gonogoDetails {
	padding: 16px;
	overflow-y: auto;
	width: 43vh;
}
.header {
	composes: font-md-medium from global !important;
}
.subHeader {
	composes: font-sm-medium from global !important;
	display: block;
	margin: var(--spacing-s) 0;
}
.description {
	composes: font-sm-regular from global !important;
	display: block;
	overflow: hidden;
}
.description[data-visible="false"] {
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	display: -webkit-box;
}
.noDescription {
	composes: font-sm-regular from global !important;
	display: block;
	color: var(--color-light-grey-3);
}
.showMore__btn {
	padding: 0 !important;
	height: fit-content !important;
}
.divider {
	margin-top: var(--spacing-s);
}
.gonogoDetails > div {
	padding-bottom: var(--spacing-s);
}

.gonogoDetails > div:first-of-type {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.gonogoDetails > div:first-of-type > div:first-of-type {
	flex: 1;
}
.gonogoDetails > div:first-of-type > div:nth-child(2) {
	flex: 1;
	color: var(--color-light-grey-2);
	font-size: 12px;
}

.flagSection > div {
	margin-top: var(--spacing-xs);
}

.historyIcon {
	color: var(--color-light-grey-2);
	margin: 0 var(--spacing-2xs);
}
.rating {
	margin: 6px 0 0 4px;
}
.icon {
	margin-right: var(--spacing-2xs);
}
.empty {
	color: var(--color-light-grey-2);
}
.icon-1 {
	color: var(--color-green);
}
.icon-2 {
	color: var(--color-yellow);
}
.icon-3 {
	color: var(--color-orange);
}
.icon-4 {
	color: var(--color-red);
}
.icon-remove {
	color: var(--color-light-grey-2);
}

.flagSection:not(:hover) .flagged > svg {
	display: none;
}
.history {
	cursor: pointer;
}
