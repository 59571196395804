.listContent {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.listContent__search {
	width: 100%;
	margin-bottom: var(--spacing-xs);
}
.listContent__clearBtn {
	composes: font-md-regular from global;
	width: fit-content;
	margin-bottom: var(--spacing-xs);
}
.listContent__stateContainer {
	height: 60px;
	display: flex;
	margin: 8px auto;
	align-items: center;
}
