.partnership__row {
	width: 100%;
	display: table;
	height: auto;
	border: 1px solid var(--color-light-grey-3);
}

.company-name__container {
	display: table-cell;
	width: 25%;
	height: 60px;
	float: left;
	border-radius: 2px;
	padding: 4px;
	position: relative;
	border: 1px solid var(--color-light-grey-3);
}

.company-name {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 90%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.issue__content--container {
	display: table-cell;
	height: 60px;
	width: 75%;
	float: left;
	border: 1px solid var(--color-light-grey-3);
	padding: 4px;
	overflow-y: auto;
}

.issue__comment {
	width: 100%;
	height: auto;
	float: left;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.issue__empty {
	color: var(--color-light-grey-3);
	font-style: italic;
}
.issue__space {
	width: 5%;
}
.issue {
	width: 100%;
	height: 100%;
	text-align: start;
}
.status__new {
	background-color: var(--color-white);
	color: var(--color-black);
}

.status__notme {
	background-color: var(--color-black);
	color: var(--color-white);
}

.status__yes {
	background-color: var(--color-green);
	color: var(--color-white);
}

.status__no {
	background-color: var(--color-red);
	color: var(--color-white);
}

.status__pending {
	background-color: var(--color-orange);
	color: var(--color-white);
}

.status__default {
	background-color: var(--color-white);
	color: var(--color-black);
}
.issue-btn {
	height: fit-content !important;
}
