.menuButton {
	text-transform: none;
	font-weight: bold;
	padding: 9px 10px !important;
	height: 48px !important;
	font-size: var(--font-size-sm) !important;
	border-radius: 0;
	border: 3px solid transparent;
}
.menuButton__text--crop {
	max-width: 15vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
@media screen and (width < 1100px) {
	.menuButton__text--crop {
		max-width: 3vw !important;
	}
	.menuButton {
		padding: 9px 5px !important;
	}
}
.menuButton--selected {
	border-bottom-color: var(--color-blue);
	cursor: default;
}
.menuButton:hover {
	color: var(--color-blue) !important;
}
.menuButton--selected:hover {
	background-color: var(--color-white);
}
