.container {
	padding: 16px 16px 0px 16px;
	display: flex;
	flex-direction: column;
}
.textLine__container {
	display: flex;
	gap: var(--spacing-xs);
	padding: 0 40px;
}
.textField input {
	background-color: var(--color-white);
	padding: 6px 8px;
	font-size: var(--font-size-sm);
	line-height: 14px;
	overflow: auto;
}
.textField p {
	font-size: var(--font-size-xs);
	margin-left: 0;
	margin-top: 8px;
}
.textField > div {
	padding: 4.5px 10px;
}
.textLine__container > div {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}
.filters_wrapper {
	padding-left: 32px;
}
.text_filter {
	padding-left: 40px;
	font-size: 12px;
	padding-top: 5px;
	color: var(--color-light-grey-1);
}
