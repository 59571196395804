.members-row {
	text-align: left;
	align-items: center;
	display: flex;
	flex-direction: row;
	border-right: 0;
	border-left: 0;
	border-top: 0;
	border-bottom: solid 2px var(--shades-grey-300);
	background-color: var(--color-white);
	width: 100%;
	height: 80px;
}
.members-row > * {
	padding: 16px 8px;
	min-height: 48px;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: left;
	justify-content: center;
}
.members-row__button-cell {
	min-width: 40px;
	width: 10%;
}
.members-row__select-cell {
	min-width: 132px;
	width: 25%;
	justify-content: flex-start;
}
.members-row__name-cell {
	flex-direction: column;
	width: 30%;
	min-width: 100px;
	align-items: flex-start;
}
.members-row__rights-on-documents {
	justify-content: flex-start;
	min-width: 200px;
}
.members-row__name-cell > * {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}
.members-row__name-cell--cut {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.members-row__name-cell--bold {
	font-weight: bold;
}
.members-row__name-cell--small {
	font-size: var(--font-size-sm);
}
.select-cell__selector {
	min-width: 150px;
	max-width: 150px;
	width: 100%;
}
.select-cell__info-icon {
	margin-left: 8px;
}
.rights-on-documents-cell__btn {
	height: fit-content !important;
	text-align: left;
}
.btn__label {
	padding-right: 16px;
}
.btn__label--number {
	font-weight: bold;
	color: var(--color-black);
}
