.table-headers--width-25 {
	width: 25% !important;
}
.table-headers--width-10 {
	width: 10% !important;
}
.table-headers--width-30 {
	width: 30% !important;
}
.header {
	padding: 10px;
	background-color: var(--color-white);
}
.headerIcon {
	display: "flex";
	place-items: "center";
}
