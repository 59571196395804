.secondaryActions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: var(--spacing-xs);
}
.secondaryActions__input {
	cursor: pointer;
	display: inline-flex;
}
.menu {
	gap: var(--spacing-xs);
	display: flex;
	flex-direction: column;
}
.menu__item {
	display: inline-flex;
	gap: var(--spacing-2xs);
	align-items: center;
	flex-wrap: nowrap;
	flex-direction: row;
}
.menu__item > div:first-of-type {
	width: 20px;
	margin-right: 2px;
}
.menu__text {
	composes: font-sm-regular from global;
	color: var(--color-black);
}
.tabPanel {
	height: calc(100% - 64px);
}
.tabPanel[data-is-unsupported="true"] {
	height: calc(100% - 56px) !important;
}
