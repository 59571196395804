.versionIcon {
	position: absolute;
	height: 40px;
	width: 40px;
	padding: 0px;
	text-align: center;
	border-radius: 6px;
	left: 0px;
	z-index: 50;
}
.versionIcon[type="pointer"] {
	cursor: pointer;
}
.versionIcon[type="default"] {
	cursor: default;
}
.content {
	max-width: 500px;
	max-height: 500px;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	overflow-y: auto;
	text-align: justify;
}
.modal__actions {
	display: flex;
	justify-content: space-between;
}
.modal__actions > div {
	display: flex;
	align-items: center;
}
.icon {
	padding: 0;
	font-size: var(--font-size-2xl);
	color: var(--color-blue);
}
.deletedContents {
	composes: font-sm-regular from global;
}
.deletedContent {
	background-color: var(--color-red-40);
	margin: 10px 0px;
	display: inline-block;
}
