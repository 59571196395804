.container {
	width: 100%;
	padding-left: 28px;
	height: 60px;
	line-height: 60px;
	border-bottom: 1px solid var(--color-light-grey-3);
}
.divider {
	height: 24px;
	margin: 0 8px;
	display: inline-block;
}
.searchResult {
	composes: font-md-bold from global;
	display: inline-block;
	height: 40;
	color: var(--color-black);
}
.btn--margin {
	margin: 0 8px;
}
.btn--leftMargin {
	margin-left: 8px;
}
