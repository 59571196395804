.root {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
.count {
	position: absolute;
	composes: font-xs-bold from global;
}
