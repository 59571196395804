.close-button {
	position: absolute;
	top: 5px;
	right: 5px;
}

.requirement-title {
	height: 50px;
	font-weight: bold;
	text-align: center;
	margin: auto;
	width: 100px;
}

.parameters-block {
	height: auto;
	width: 100%;
	text-align: center;
	padding: 8px;
}

.client-parameter {
	width: 80%;
	margin: 8px auto 20px auto;
}

.additional-parameters {
	width: 95%;
	margin: 20px auto;
}

.client__icon {
	padding: 0px !important;
	color: var(--color-white);
}

.client__button {
	font-size: var(--font-size-4xl);
}

.client__button--client {
	color: var(--color-green);
}

.client__button--non-client {
	color: var(--color-light-grey-3);
}
