.panel {
	width: 40%;
	border-right: 1px solid var(--color-light-grey-3);
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.panel__company-list {
	overflow-y: auto;
}
.loader {
	text-align: center;
	padding: 16px;
}
.panel__list {
	height: 100%;
	overflow-y: auto;
}
