.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	padding: 0 8px;
	height: 212px;
}
.container__half {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 50%;
	height: 100%;
}
.container__half--left {
	padding-right: 4px;
}
.container__half--right {
	padding-left: 4px;
}
.tableContainer {
	max-height: 212px !important;
	overflow: auto;
}
.figure {
	width: 100%;
	padding-top: 12px;
	background-color: var(--color-white);
	color: var(--color-dark-blue);
	text-align: center;
	display: flex;
	flex-direction: column;
}
.header {
	composes: font-sm-regular from global;
	padding: 8px;
}
.figure__number {
	color: var(--color-dark-blue);
	font-size: var(--font-size-xl);
}
.figure__label {
	color: var(--color-dark-grey-1);
	composes: font-xs-regular from global;
}
.manageTeamBtn {
	max-width: 120px;
	margin: 19px auto 8px auto;
}
.userCount {
	margin-left: 4px;
	color: var(--color-light-grey-1);
}
.partnerCell {
	max-width: 72px;
	border: 0px !important;
	padding: 4px 0 !important;
}
.partnerName {
	composes: font-xs-regular from global;
	width: 100%;
	padding-right: 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
}
.partnerCount {
	width: fit-content;
	display: block;
	white-space: nowrap;
}
.manageTeamBtn__text {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
