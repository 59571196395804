.container {
	display: flex;
	justify-content: flex-end;
	margin: 12px 0;
	gap: 8px;
}
.container__header {
	display: flex;
	gap: 8px;
}
.lowerCase {
	text-transform: lowercase;
}
.container__btns {
	display: flex;
	place-content: center;
	gap: 2px;
}
.checkbox__root {
	height: 24px;
	width: 24px;
	color: var(--color-light-grey-1);
}
.UnreviewedCheckBox__root[data-is-checked="true"] {
	color: var(--color-light-grey-2) !important;
}
.YesCheckbox__root[data-is-checked="true"] {
	color: var(--color-green) !important;
}
.PendingCheckbox__root[data-is-checked="true"] {
	color: var(--color-orange) !important;
}
.NoCheckbox__root[data-is-checked="true"] {
	color: var(--color-red) !important;
}
.NotMeCheckbox__root[data-is-checked="true"] {
	color: var(--color-black) !important;
}
.trashButton {
	padding: 0px !important;
	height: 24px !important;
	width: 24px !important;
}
.tashPlaceHolder {
	width: 24px;
}
