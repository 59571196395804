.dialog-content__section {
	display: flex;
	flex-direction: column;
}
.dialog-content__section-title {
	font-weight: bold;
	font-size: var(--font-size-sm);
	text-transform: uppercase;
	margin-bottom: 12px;
}
.dialog-content__password {
	text-align: center;
}
