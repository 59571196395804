.panelContent {
	padding: 16px;
	height: 100%;
}
.panelContent__gridContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.panelContent__description {
	composes: font-sm-regular from global;
	font-style: italic;
}
.panelContent__gridDescription {
	composes: font-sm-regular from global;
	margin-top: 16px;
	padding: 10px 18px;
	border-radius: 4px;
	border: 1px solid var(--shades-blue-100);
	color: var(--shadow-6);
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.gridDescription__rightSide {
	display: flex;
	justify-content: center;
	align-items: center;
}

.gridDescription__icon {
	margin-right: 10px;
}
.gridDescription__title {
	composes: font-sm-regular from global;
	color: var(--color-black);
	padding-bottom: 2px;
}
.gridDescription__text {
	composes: font-sm-regular from global;
}

.gridDescription__closeBtn {
	color: var(--color-blue);
	position: relative !important;
	top: 0;
	right: 0;
	padding: 0;
}
