.progressbar__backbar {
	border-radius: 4px;
	width: 100%;
	display: flex;
	align-items: center;
	background-color: var(--shades-grey-200);
}
.progressbar__frontbar {
	border-radius: 4px;
	height: 100%;
	display: flex;
	align-items: center;
}

.progressbar__label {
	font-weight: bold;
	font-size: var(--font-size-sm);
}

.progressbar__label--inside {
	color: var(--color-white);
}
.progressbar__label--outside {
	color: var(--color-dark-grey-1);
}
