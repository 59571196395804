.main {
	height: 42px;
	display: flex;
	align-items: center;
}
.main > div:first-of-type {
	display: flex;
	flex: 3;
	margin-right: var(--spacing-m);
	padding-left: var(--spacing-xs);
	align-items: center;
	max-width: 72%;
}
.main > div:nth-child(2) {
	display: flex;
	padding: var(--spacing-xs);
	flex: 1;
}
.riskName {
	composes: font-xl-bold from global;
	margin-left: var(--spacing-xs);
	max-width: 60%;
}
.riskName + div {
	flex: 1;
}
.square {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	font-size: 16px;
	background-color: var(--color-light-grey-4);
}
.bubble__label {
	color: var(--color-white) !important;
}
