.main {
	margin-top: var(--spacing-s);
}
.title {
	font-size: var(--font-size-l);
	font-weight: bold;
	display: block;
}
.mandatory {
	color: var(--color-red);
}
.addDocument__button {
	width: auto;
	cursor: pointer;
	border: 1px solid var(--color-dark-grey-1);
	border-radius: 4px;
	padding: var(--spacing-xs);
	display: inline-block;
}
.addDocument__button > * {
	cursor: pointer;
}
.main > label {
	margin-top: var(--spacing-s);
}
.addDocumentIcon {
	margin-right: var(--spacing-2xs);
}
.main > span:nth-of-type(2) {
	margin-top: var(--spacing-xs);
	color: var(--color-dark-grey-1);
}
.limit__warning {
	composes: font-xs-regular from global;
	color: var(--color-red);
	margin-top: 4px;
	margin-bottom: 8px;
}
