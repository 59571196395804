.main {
	background-color: var(--shades-grey-100);
	padding: 8px;
	height: calc(100% + 16px);
}
.container {
	border-radius: 4px;
	border: 1px solid var(--color-light-grey-4);
	background-color: #fff;
	height: 100%;
	padding: 8px;
}
.container_filter {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	box-shadow: none;
	composes: font-sm-regular from global;
}
.tabs span {
	position: absolute;
	top: 35px;
}
.tabs > * {
	padding-left: 4px !important;
}
.container_search {
	display: flex;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
}
.search {
	flex: 2;
}
.container_list--fullHeight {
	composes: font-sm-regular from global;
	line-height: 32px;
	margin-top: 8px;
	height: 100%;
	overflow: auto;
}
.container_list {
	composes: font-sm-regular from global;
	line-height: 32px;
	margin-top: 8px;
	height: calc(100% - 90px);
	overflow-y: auto;
	overflow-x: hidden;
}
.rightIcon {
	color: var(--color-blue);
	transition: none;
	font-size: 16px !important;
	margin-left: 12px;
}
.rightIcon--translucent {
	opacity: 0;
	transition: opacity 1s;
}
.showSelected {
	composes: font-sm-regular from global;
}
.textField {
	composes: font-sm-regular from global;
	height: 32px;
}
.emptyState {
	composes: font-sm-regular from global;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.emptyStateIcon {
	margin-right: 8px;
	font-size: var(--font-size-2xl) !important;
}
