.status {
	display: inline-block;
	color: var(--color-white);
	text-align: center;
	height: 36px;
	line-height: 36px;
	font-size: var(--font-size-sm);
	-webkit-transition: width 3s ease;
	-moz-transition: width 3s ease;
	-o-transition: width 3s ease;
	transition: width 3s ease;
}

.status__innerWrapper {
	padding: 0 2px;
}
.status[data-size="sm"] {
	height: 32px;
	line-height: 32px;
	font-size: var(--font-size-sm);
}
.status[data-size="md"] {
	height: 36px;
	line-height: 36px;
	font-size: var(--font-size-sm);
}
.status[data-size="lg"] {
	height: 40px;
	line-height: 40px;
	font-size: var(--font-size-md);
}
.status[data-size="xl"] {
	height: 48px;
	line-height: 48px;
	font-size: var(--font-size-md);
}
