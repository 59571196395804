.row__label {
	width: 100%;
	margin: 4px 0;
}
.labels {
	display: flex;
	flex-direction: column;
	width: 100%;
	white-space: pre-wrap;
}
.label__main {
	font-size: var(--font-size-sm);
	width: fit-content;
	word-break: break-all;
}
.label__main--bold {
	composes: font-sm-medium from global;
}
.row__checkbox {
	padding: 4px 8px 8px 4px;
}
.labeledCheckBox {
	align-items: flex-start;
}
