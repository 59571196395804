.sortLabel {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.sortLabel:hover {
	color: var(--shades-grey-900);
}
.icon {
	margin-left: 5px;
	color: var(--color-black);
}
.icon--inactive {
	color: var(--color-light-grey-3);
}
