.newPassword {
	padding-top: 8px;
}
.passwordConfirmation {
	padding-top: 16px;
}
.conditionsList {
	composes: font-sm-regular from global;

	color: var(--color-light-grey-1);
}
.conditionList__item {
	padding: 2px 0;
}
.conditionList__item--green {
	color: var(--color-green);
}
