.tab {
	min-height: 500px;
}
.tab__header {
	display: flex;
	margin-bottom: var(--spacing-m);
	align-content: center;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}
.tab__title {
	composes: font-xl-medium from global;
	color: var(--color-black);
}
.tab__content {
	width: 100%;
	flex-grow: 0;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	flex-wrap: nowrap;
	flex-direction: row;
}
.timeline {
	width: 100px;
}
.timeline_date {
	composes: font-sm-regular from global;
	color: var(--color-black);
}
.timeline_date--current {
	font-weight: bold;
}
.timeline__dot,
.timeline__connector {
	background-color: var(--color-black);
}
.content__main {
	display: inline-flex;
	gap: var(--spacing-xs);
	width: 250%;
}
.content__name {
	composes: font-sm-regular from global;
	color: var(--color-black);
}
.content__name--current {
	font-weight: bold;
}
.content__filename {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-2);
	display: inline-flex;
	padding-left: var(--spacing-s);
	width: max-content;
}
.button {
	width: max-content;
	margin: 10px 0px 20px;
}
.noContent {
	composes: font-sm-regular from global;
	padding: var(--spacing-s);
	border-radius: 4px;
	background-color: var(--shades-blue-50);
	display: flex;
	margin-bottom: var(--spacing-xs);
}
.noContent__infoIcon {
	margin-right: var(--spacing-xs);
	padding-top: var(--spacing-2xs);
}
.noContent__desc {
	display: flex;
	flex-direction: column;
}
.noContent__btn {
	margin-top: var(--spacing-s);
}
