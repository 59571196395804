.timeline__item {
	margin: 0 !important;
	padding: 0 !important;
	width: 100%;
}

.timeline__date {
	composes: font-sm-bold from global;
	flex: 3;
	padding-left: 0 !important;
}
.timeline__distance {
	position: relative;
}
.timeline__distance--label {
	position: absolute;
	bottom: var(--spacing-s);
	right: calc(var(--spacing-s) + var(--spacing-2xs));
	composes: font-sm-bold from global;
	text-transform: uppercase;
	color: var(--color-light-grey-2);
}
.timeline__content {
	flex: 7;
	padding-right: 0 !important;
}
.timeline__content > div > :nth-child(2) {
	padding-left: calc(var(--spacing-s) + var(--spacing-2xs));
}

.timeline__dot,
.timeline__connector {
	background-color: var(--color-black);
	color: var(--color-black);
}
.timeline__connector {
	width: 1px;
}
.timeline__connector--sole {
	width: 1px;
	margin-left: 3px;
}
.timeline__dot--filled {
	width: 9px !important;
	height: 9px !important;
}
.content__name {
	display: flex;
	align-items: center;
	composes: font-sm-bold from global;
	padding-left: calc(var(--spacing-m) - 2px);
}
