.actionDial__searchInput {
	margin-bottom: var(--spacing-s);
}

.actionDial__informationApply {
	color: var(--color-dark-grey-1);
	margin: var(--spacing-s) var(--spacing-2xl);
}

.actionDial__loader {
	width: 100%;
	text-align: center;
}
