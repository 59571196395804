.container {
	color: var(--color-dark-grey-2);
	padding: 0 var(--spacing-s) var(--spacing-2xs);
	background-color: var(--color-orange-20);
	border-left: dashed 4px var(--shades-grey-500);
	border-right: dashed 4px var(--shades-grey-500);
	composes: font-sm-regular from global;
}
.container > div:first-of-type {
	margin-top: 8px;
	display: flex;
	align-items: center;
}
.container > div:nth-of-type(2) {
	padding-left: 32px;
}
.unsupportedText {
	font-weight: bold;
	padding-left: 16px;
}
.link {
	text-decoration: underline !important;
	color: var(--color-dark-grey-2);
}
