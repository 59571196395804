.menuListTitle {
	height: 32px;
	display: flex;
	align-items: flex-end;
	color: var(--color-light-grey-3);
	font-weight: bold;
	font-size: var(--font-size-xs);
	padding: 0 16px;
	cursor: default;
	text-transform: uppercase;
}
