.detailBlock {
	position: absolute;
	width: 100%;
	border: 1px solid var(--color-light-grey-3);
	z-index: 100;
	height: 28px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 0 20px;
}
.detailBlock__isNew {
	background-color: var(--color-blue);
}
.detailBlock__notNew {
	background-color: var(--color-orange);
}
.statusIcon {
	margin-right: 8px;
}
