.form {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
.form > * {
	margin: 16px 0;
}
.title {
	composes: font-sm-medium from global;
	margin-bottom: 0;
	grid-row: 1;
	display: flex;
	align-items: center;
}
.title--flex > div:last-of-type {
	margin-left: 8px;
}
.form > *:nth-child(1) {
	margin-top: 0;
}
.form > *:nth-child(4) {
	margin-bottom: 8px;
}
.form > *:nth-child(5) {
	margin-top: 0;
}
.form > *:last-child {
	font-size: var(--font-size-sm);
	margin: 0;
}
.package {
	display: grid;
	grid-template-columns: auto 1fr;
}
.package__errorMessage {
	composes: font-xs-regular from global;
	color: var(--color-red);
}
.tooltipPack {
	margin-left: 5px;
	grid-column: 2;
}
.tooltipReq {
	margin-left: -10px;
	grid-column: 2;
}
.inputLabelProps {
	composes: font-sm-regular from global;
	line-height: 1rem;
}
.inputProps {
	composes: font-sm-regular from global;
	height: 32px;
	line-height: 2rem;
}
.description {
	font-size: var(--font-size-xs);
	font-weight: 400;
}
.divider {
	margin: var(--spacing-m) 0 var(--spacing-s) !important;
}
.implicit {
	font-size: var(--font-size-xs);
	font-weight: 400;
}
.radio {
	composes: font-sm-regular from global;
	grid-row: 1;
	margin-right: 0;
}
.radio--disabled {
	color: var(--color-light-grey-1);
}
.requiredIcon {
	margin-bottom: 5px;
	margin-left: 5px;
	margin-right: 6px;
}
