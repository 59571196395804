.categoryRating__rating {
	display: flex;
	align-items: center;
	align-content: center;
}
.ownerIcon {
	height: 24px;
	width: 24px;
	font-size: 12px;
}
.categoryRating__time {
	color: var(--color-light-grey-1);
	composes: font-2xs-regular from global;
	padding: 0 var(--spacing-2xs);
	margin-left: auto;
}
.categoryRating {
	display: flex;
	flex-direction: column;
	margin-bottom: var(--spacing-xs);
}
