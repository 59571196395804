.container {
	margin: var(--spacing-xs) var(--spacing-xs);
	padding: 0 var(--spacing-xs);
	display: flex;
	position: relative;
	flex-direction: column;
}
.container__line {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
}
.container__line[data-is-closed-conversation="true"] {
	color: var(--color-light-grey-1);
}
.container__line:first-child {
	margin: var(--spacing-xs) 0;
}
.container__line[data-has-replies="false"] {
	margin-bottom: var(--spacing-2xs);
}
.name {
	composes: font-sm-bold from global;
	text-transform: capitalize;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	min-width: calc(30% - 8px);
	max-width: calc(30% - 8px);
	margin-right: 8px;
}
.divider {
	margin: 0 2px;
	height: 12px;
}
.line__rightItems {
	width: calc(70% - 8px);
	display: flex;
	justify-content: flex-end;
	align-items: center;
	white-space: nowrap;
	color: var(--color-light-grey-1);
	composes: font-xs-regular from global;
	margin: 0 var(--spacing-xs);
}
.documentTime__container {
	max-width: calc(100% - 50px);
}
.documentName {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.comment {
	display: -webkit-box;
	width: 100%;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	align-self: center;
	composes: font-sm-regular from global;
	margin-bottom: var(--spacing-2xs);
}
.commentLine {
	margin: 0;
}
.button {
	margin-top: -(var(--spacing-m));
}
.button:hover {
	background-color: var(--color-light-grey-3);
}
.showMoreButton {
	color: var(--color-blue);
	margin: 0 var(--spacing-m);
	font-weight: 400;
}
.showMoreButton:hover {
	color: var(--color-dark-blue);
}
.showMoreContainer {
	position: absolute;
	right: var(--spacing-xs);
	bottom: 17px;
	width: fit-content;
	background-color: var(--color-white);
	padding: 0;
	font-size: var(--font-size-sm);
}
.childrenCount {
	margin: var(--spacing-2xs) 0 var(--spacing-xs);
	color: var(--color-light-grey-1);
	composes: font-sm-regular from global;
}
