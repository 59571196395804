/* textarea styling */
.mentions--multiLine {
	margin-right: 8px;
}
.mentions--multiLine .mentions__highlighter {
	font-size: var(--font-size-sm) !important;
	box-sizing: border-box;
	border: 1px solid transparent;
	padding: 8px;
	line-height: 1.15;
}
.mentions__input {
	font-size: var(--font-size-sm) !important;
	border: 1px solid var(--shades-grey-300);
	border-radius: 4px;
	outline: none;
	padding: 8px;
	line-height: 1.15;
}
.mentions__input:hover {
	outline-color: rgba(0, 0, 0, 0.87);
}
.mentions__input:focus {
	box-sizing: border-box;
	outline: 2px solid rgb(56, 128, 232);
}

.mentions__suggestions__list {
	padding: 8px 0 !important;
	border-radius: 4px;
	box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%);
}

.mentions__suggestions__item {
	composes: font-sm-regular from global;
	padding: 8px 16px !important;
}
.mentions__suggestions__item--focused {
	background-color: var(--shadow-04);
}

/* mentionned text */
.mentions__mention {
	position: relative;
	z-index: 1;
	color: var(--color-blue);
	text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
	text-decoration: underline;
	pointer-events: none;
}
