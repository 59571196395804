.dialog__text {
	margin-top: 10px;
	text-transform: uppercase;
}

.template__table {
	border: 1px solid var(--color-light-grey-3);
	background: var(--color-light-grey-3);
	width: 100%;
	margin: 2rem;
}

.template__cell {
	border-right: 1px solid;
	text-align: center;
	background: var(--color-light-grey-3);
}
