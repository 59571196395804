.container {
	display: flex;
	flex-direction: column;
	background-color: var(--shades-grey-100);
	padding: 9px;
	border-radius: 5px;
	margin: auto;
}
.title {
	composes: font-l-medium from global;
}
.subtitle {
	composes: font-sm-regular from global;
}
.propositions_points {
	composes: font-sm-regular from global;
}
