.row {
	display: flex;
	align-items: center;
	width: 100%;
	height: 26px;
	overflow: hidden;
}
.row[data-read-more="true"] > * {
	margin-bottom: var(--spacing-2xs);
}
.chips {
	column-gap: var(--spacing-2xs);
	height: 26px;
	line-height: 26px;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	overflow-y: hidden;
}
