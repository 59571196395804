.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: hidden;
}
.StyledTabs__root {
	border-bottom: 1px solid var(--color-light-grey-3);
}
.StyledTabs__indicator {
	background-color: var(--color-blue) !important;
}
