.main {
	align-items: center;
	align-content: center;
	align-self: center;
	display: flex;
	flex-direction: row;
}
.icon {
	margin: 0 2px !important;
	padding: 0 !important;
	width: auto !important;
	height: auto !important;
}
.icon--inactive {
	cursor: none;
}
