.option {
	cursor: pointer;
	max-width: 100% !important;
	padding: 0 16px;
}
.option:hover {
	background-color: var(--shades-grey-100);
}
.labelProps {
	composes: font-sm-regular from global;
	line-height: 1.5rem;
}
.Props {
	composes: font-sm-regular from global;
}
