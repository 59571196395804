@import-normalize;
@import "./common/styles/font-faces.css";
@import "./common/styles/fonts.css";

:root {
	--view-padding-lateral: 64px;
	--navbar-height: 45px;
	--viewbanner-height: 42px;
	--viewtabs-height: 36px;

	--spacing-2xs: 4px;
	--spacing-xs: 8px;
	--spacing-s: 16px;
	--spacing-m: 24px;
	--spacing-l: 32px;
	--spacing-xl: 40px;
	--spacing-2xl: 48px;
	--spacing-3xl: 56px;
	--spacing-4xl: 64px;

	--shadow-04: rgba(0, 0, 0, 0.04);
	--shadow-1: rgba(0, 0, 0, 0.1);
	--shadow-2: rgba(0, 0, 0, 0.2);
	--shadow-3: rgba(0, 0, 0, 0.3);
	--shadow-5: rgba(0, 0, 0, 0.5);
	--shadow-6: rgba(0, 0, 0, 0.6);
	--shadow-8: rgba(0, 0, 0, 0.8);
	--shadow-inset: rgba(255, 255, 255, 0.5);

	--color-white: rgb(255, 255, 255);
	--color-white-secondary: rgb(245, 245, 245);
	--color-black: rgba(36, 36, 38, 1);
	--color-black-30: rgba(36, 36, 38, 0.3);
	--color-black-10: rgba(36, 36, 38, 0.1);

	--color-grey-black: rgba(14, 14, 15);

	--color-red: rgb(209, 46, 46);
	--color-red-70: rgba(209, 46, 46, 0.7);
	--color-red-50: rgba(209, 46, 46, 0.5);
	--color-red-40: rgba(209, 46, 46, 0.4);
	--color-red-30: rgba(209, 46, 46, 0.3);
	--color-red-20: rgba(209, 46, 46, 0.2);
	--color-red-10: rgba(209, 46, 46, 0.1);

	--color-dark-blue: rgb(0, 41, 114);
	--color-blue: rgb(56, 128, 232);
	--color-blue-70: rgba(56, 128, 232, 0.7);
	--color-blue-50: rgba(0, 125, 255, 0.5);
	--color-blue-40: rgba(56, 128, 232, 0.4);
	--color-blue-20: rgba(56, 128, 232, 0.2);
	--color-blue-10: rgba(56, 128, 232, 0.1);
	--color-light-blue: rgb(120, 175, 255);

	--color-orange: rgb(239, 175, 50);
	--color-orange-70: rgba(239, 175, 50, 0.7);
	--color-orange-40: rgba(239, 175, 50, 0.4);
	--color-orange-30: rgba(239, 175, 50, 0.3);
	--color-orange-20: rgba(239, 175, 50, 0.2);
	--color-orange-10: rgba(239, 175, 50, 0.1);

	--color-yellow: rgb(253, 225, 45);
	--color-yellow-70: rgba(253, 225, 45, 0.7);
	--color-yellow-40: rgba(253, 225, 45, 0.4);
	--color-yellow-10: rgba(253, 225, 45, 0.1);
	--color-yellow-20: rgba(253, 225, 45, 0.2);

	--color-green: rgb(136, 191, 64);
	--color-green-70: rgba(136, 191, 64, 0.7);
	--color-green-40: rgba(136, 191, 64, 0.4);
	--color-green-30: rgba(136, 191, 64, 0.3);
	--color-green-20: rgba(136, 191, 64, 0.2);
	--color-green-10: rgba(136, 191, 64, 0.1);
	--color-light-green: rgb(183, 236, 114);
	--color-light-green-70: rgba(183, 236, 114, 0.7);
	--color-light-green-40: rgba(183, 236, 114, 0.4);

	--color-dark-grey-4: rgb(54, 54, 56);
	--color-dark-grey-3: rgb(68, 68, 70);
	--color-dark-grey-2: rgb(84, 84, 86);
	--color-dark-grey-1: rgb(124, 124, 128);
	--color-light-grey-1: rgb(174, 174, 178);
	--color-light-grey-2: rgb(188, 188, 192);
	--color-light-grey-3: rgb(216, 216, 220);
	--color-light-grey-4: rgb(235, 235, 240);
	--color-light-grey-4-40: rgba(235, 235, 240, 0.4);

	--color-keppel: rgb(18, 139, 115);

	--color-primary-hover: rgb(0, 84, 181);

	--shades-blue-50: rgb(227, 241, 254);
	--shades-blue-100: rgb(189, 220, 253);
	--shades-blue-200: rgb(147, 199, 252);
	--shades-blue-300: rgb(105, 176, 250);
	--shades-blue-400: rgb(77, 160, 249);
	--shades-blue-500: rgb(57, 143, 247);
	--shades-blue-600: rgb(56, 129, 232);
	--shades-blue-700: rgb(53, 111, 212);
	--shades-blue-800: rgb(50, 94, 194);
	--shades-blue-900: rgb(44, 63, 162);

	--shades-grey-50: rgb(250, 250, 253);
	--shades-grey-100: rgb(246, 245, 248);
	--shades-grey-200: rgb(240, 240, 243);
	--shades-grey-300: rgb(227, 227, 230);
	--shades-grey-400: rgb(193, 193, 195);
	--shades-grey-500: rgb(162, 162, 165);
	--shades-grey-600: rgb(121, 121, 123);
	--shades-grey-800: rgb(69, 69, 72);
	--shades-grey-700: rgb(101, 101, 103);
	--shades-grey-900: rgb(36, 36, 38);

	--shades-grey-v2-5: rgba(243, 244, 246);
	--shades-grey-v2-10: rgba(195, 217, 248, 0.1);
	--shades-grey-v2-25: rgba(195, 217, 248, 0.25);
	--shades-grey-v2-30: rgba(207, 212, 218);
	--shades-grey-v2-40: rgb(160, 168, 181);
	--shades-grey-v2-70: rgb(88, 103, 125);

	--status-not-me: var(--color-black);
	--status-not-me-10: var(--color-light-grey-4);
	--status-not-me-20: var(--color-light-grey-3);
	--status-yes: var(--color-green);
	--status-yes-10: var(--color-green-10);
	--status-yes-20: var(--color-green-20);
	--status-no: var(--color-red);
	--status-no-10: var(--color-red-10);
	--status-no-20: var(--color-red-20);
	--status-pending: var(--color-orange);
	--status-pending-10: var(--color-orange-10);
	--status-pending-20: var(--color-orange-20);
	--status-unreviewed: var(--color-light-grey-2);
	--status-unreviewed-20: var(--color-light-grey-4-40);

	--tints-blue: rgb(11, 26, 46);
	--tints-blue-50: rgb(155, 191, 244);
	--tints-blue-10: rgb(235, 242, 253);
	--tints-blue-10-30: rgb(235, 242, 253, 0.3);
	--tints-blue-30: rgb(195, 217, 248);
	--tints-blue-5: rgb(245, 249, 254);

	--tints-grey: rgb(225, 227, 229);

	--tints-white: rgb(251, 252, 255);

	--exigence-focus: #cff1a2;
	--exigence-selected: #a8d66c;
	--exigence: #eefadd;
	--search-focus: #fde12d;
	--search: #fef1a1;
}
/* Extend normalize
 * ========================================================================== */
*,
::before,
::after {
	box-sizing: border-box;
}
::before,
::after {
	text-decoration: inherit;
	vertical-align: inherit;
}
@media (prefers-reduced-motion: reduce) {
	*,
	::before,
	::after {
		animation-delay: -1ms !important;
		animation-duration: 1ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		scroll-behavior: auto !important;
		transition-delay: 0s !important;
		transition-duration: 0s !important;
	}
}
/**
 * Add the correct display in IE 10-.
 */

[hidden] {
	display: none !important;
}
/* Embedded content
 * ========================================================================== */

/* 
* CSS to hide hubspot default chat button
*/
#hubspot-messages-iframe-container:not(:has(div.active)) {
	display: none !important;
}

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

/**
  * Add the correct display in IE 9-.
  */

audio,
video {
	display: inline-block;
}

/**
  * Add the correct display in iOS 4-7.
  */

audio:not([controls]) {
	display: none;
	height: 0;
}

/**
  * Remove the border on iframes in all browsers (opinionated).
  */

iframe {
	border-style: none;
}

/**
  * Remove the border on images within links in IE 10-.
  */

img {
	border-style: none;
}

/**
  * Change the fill color to match the text color in all browsers (opinionated).
  */

svg:not([fill]) {
	fill: currentColor;
}

/**
  * Hide the overflow in IE.
  */

svg:not(:root) {
	overflow: hidden;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

[aria-busy="true"] {
	cursor: progress;
}

/*
   * Change the cursor on control elements in all browsers (opinionated).
   */

[aria-controls] {
	cursor: pointer;
}

/*
   * Change the cursor on disabled, not-editable, or otherwise
   * inoperable elements in all browsers (opinionated).
   */

[aria-disabled="true"],
[disabled] {
	cursor: not-allowed;
}

/*
   * Change the display on visually hidden accessible elements
   * in all browsers (opinionated).
   */

[aria-hidden="false"][hidden] {
	display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
	clip: rect(0, 0, 0, 0);
	position: absolute;
}

/* Extend styles
 * ========================================================================== */
a {
	color: var(--color-blue);
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: var(--color-dark-blue);
	text-decoration: underline;
}
a:not([href]):not([class]) {
	color: inherit;
	text-decoration: none;
}
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.5rem;
	font-weight: 500;
}
.h1,
h1 {
	font-size: 2.5rem;
}
.h2,
h2 {
	font-size: 2rem;
}
.h3,
h3 {
	font-size: 1.75rem;
}
.h4,
h4 {
	font-size: 1.5rem;
}
.h5,
h5 {
	font-size: 1.25rem;
}
.h6,
h6 {
	font-size: 1rem;
}
html {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
}
body {
	font-family: Roboto, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	padding: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
}
.highlight-word {
	background-color: var(--color-yellow);
	color: var(--color-black);
}
.highlight-word--green {
	background-color: var(--color-light-green);
}
.highlight-word--red {
	background-color: var(--color-red-40);
}
#root {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100vh;
	width: 100%;
	margin: 0;
	padding: 0;
	overflow-y: auto;
}
.parameter {
	background-color: var(--color-light-grey-3);
	color: var(--color-black);
}
.parameter[data-type="criticality"][data-value="critical"] {
	background-color: var(--color-red);
	color: var(--color-white);
}
.parameter[data-type="criticality"][data-value="major"] {
	background-color: var(--color-orange);
	color: var(--color-black);
}
.parameter[data-type="criticality"][data-value="minor"] {
	background-color: var(--color-yellow);
	color: var(--color-black);
}
.parameter[data-type="negotiability"][data-value="must"] {
	background-color: var(--color-red);
	color: var(--color-white);
}
.parameter[data-type="negotiability"][data-value="should"] {
	background-color: var(--color-orange);
	color: var(--color-black);
}
.parameter[data-type="negotiability"][data-value="could"] {
	background-color: var(--color-yellow);
	color: var(--color-black);
}
.parameter[data-type="negotiability"][data-value="would"] {
	background-color: var(--color-green);
	color: var(--color-white);
}
.risk-level[data-risk-level="NONE"] {
	background-color: var(--color-green);
}
.risk-level[data-risk-level="LOW"] {
	background-color: var(--color-yellow);
}
.risk-level[data-risk-level="MEDIUM"] {
	background-color: var(--color-orange);
}
.risk-level[data-risk-level="HIGH"] {
	background-color: var(--color-red);
}
.message {
	overflow-wrap: break-word;
	word-wrap: break-word;
}
