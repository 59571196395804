.nonActive {
	composes: font-sm-regular from global;
	color: var(--color-black) !important;
}
.nonActive > svg {
	width: 16px;
	height: 14px;
	color: var(--color-light-grey-1);
}
.buttonGroup__open {
	border-radius: 4px 0px 0px 4px;
}
.buttonGroup__remove {
	min-width: 32px !important;
	border-radius: 0px 4px 4px 0px;
}
.buttonGroup__remove:disabled {
	background-color: var(--color-light-grey-4) !important;
	border: 1px solid var(--color-blue-50) !important;
}
.popup {
	background: var(--color-white);
	z-index: 1100;
	max-width: 550px;
	min-width: 311px;
	max-height: 500px;
	display: flex;
	flex-direction: column;
	box-shadow: 10px 10px 30px var(--shadow-2);
	overflow: hidden;
	border: 1px solid #d8d8dc;
	border-radius: 4px;
}
.popup__header {
	display: flex;
	align-items: center;
	padding: var(--spacing-xs);
	box-shadow: 0px 1px 0px 0px #d8d8dc;
}
.popup--resizable {
	resize: both;
	max-height: calc(100vh - 138px) !important;
	max-width: calc(100vw - 200px) !important;
}
.popup--resizable[data-placement="bottom-end"] {
	direction: rtl;
}
.popup--resizable[data-placement="bottom-end"] > div {
	direction: ltr;
}
.header__title {
	composes: font-sm-medium from global;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin: 0;
}
.header__title > span:first-of-type {
	display: flex;
	align-items: center;
	gap: 8px;
}
.header__title_description {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-1);
}
.popup__content {
	position: relative;
	padding: var(--spacing-xs);
	overflow: auto;
	height: 100%;
	box-shadow: 0px 1px 0px 0px #d8d8dc;
}
.popup__content--header {
	padding: var(--spacing-xs) var(--spacing-xs) 0;
}
.popup__footer {
	padding: var(--spacing-xs);
}
.notAppliable[data-disabled="true"] {
	opacity: 0.5;
	pointer-events: none;
}
.separator__box {
	padding: 0;
}
.selection__row {
	margin: var(--spacing-xs) 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	line-height: 24px !important;
	height: 24px !important;
}
.clearSelection__row {
	margin: var(--spacing-xs) 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	line-height: 24px !important;
	height: 24px !important;
}
.selector__label {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
