.norms__headerCellBtn {
	width: 80px;
}
.header {
	padding: 10px 16px;
	background-color: var(--color-white);
}
.emptyState {
	text-align: center;
}

.newVersion__textBlock {
	margin-left: 4px;
	display: inline-block;
}
.newVersion__btn {
	margin-top: -28px;
}
.status {
	composes: font-xs-regular from global;
	display: block;
	text-align: center;
	width: 90px;
	border-radius: 4px;
	padding: 1.5px 0;
}
.status--canceled {
	background-color: var(--color-red-50);
}
.status--active {
	background-color: var(--color-blue-50);
}
.status--custom {
	background-color: var(--shades-grey-200);
}
.norms__name {
	display: block;
	max-width: 276px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.tablePagination {
	margin-right: 20px;
}
