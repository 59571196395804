.dragAndDropHelper__container {
	composes: font-md-regular from global;
	background-color: var(--color-blue);
	display: flex;
	align-items: center;
	color: var(--color-white);
	position: absolute;
	margin-left: 8px;
	margin-bottom: 76px;
	padding: 16px 8px;
	border-radius: 4px;
}
.uploadIcon {
	display: flex;
	height: 40px;
	width: 40px;
	align-items: center;
	justify-content: center;
}
.info {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-left: 8px;
}
.infoMainText {
	composes: font-sm-medium from global;
	line-height: 14px;
	margin-right: 4px;
}
.folderIcon {
	margin-right: 4px;
}
.locationName {
	composes: font-sm-regular from global;
}
.supportedFiles {
	composes: font-xs-regular from global;
	margin-top: 8px;
	font-style: italic;
}
