.file__container {
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	grid-gap: var(--spacing-xs);
	gap: var(--spacing-xs);
	justify-content: space-evenly;
	align-content: center;
	overflow: auto;
}
.file__information {
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: var(--spacing-xs);
	width: 50%;
}
.file__row {
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	align-items: center;
	grid-gap: var(--spacing-s);
	gap: var(--spacing-s);
	justify-content: flex-start;
	align-content: center;
	flex-wrap: nowrap;
	flex-direction: row;
}
.row__label {
	composes: font-xs-bold from global;
	color: var(--color-light-grey-2);
	text-transform: uppercase;
	text-align: right;
	min-width: 140px;
}
.row__value {
	composes: font-sm-regular from global;
	color: var(--color-black);
	width: -webkit-fill-available;
	overflow-wrap: break-word;
	flex: 1;
}
.file__iconContainer {
	align-self: center;
	border: 1px solid var(--color-light-grey-1);
}
.file__icon {
	width: 180px !important;
	height: 120px !important;
}
.row_value_column {
	width: calc(100% - 158px);
	display: flex;
}
