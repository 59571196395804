.accordion__header > * {
	align-items: center;
}
.expand-button {
	margin-right: 0;
}
.content {
	margin: 0 !important;
}
.root {
	margin: 0;
	padding: 0;
	min-height: 0 !important;
}
.accordion__root {
	width: 100%;
	box-shadow: none !important;
	position: inherit;
	margin: 0 !important;
}
.accordion-details__root {
	flex-direction: column;
}
