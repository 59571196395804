.option {
	cursor: pointer;
	max-width: 100% !important;
	padding: 0 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
}
.option > div {
	margin-right: 8px;
}
.option:hover {
	background-color: var(--shades-grey-100);
}
.labelProps {
	composes: font-sm-regular from global;
	line-height: 1.5rem;
}
.Props {
	composes: font-sm-regular from global;
}
.hierarchy {
	width: 100%;
	display: block;
}
.hierarchy > div:last-of-type {
	font-weight: bold;
}
