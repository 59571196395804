.documentList__container {
	display: flex;
	height: calc(100% - 42px);
}
.document__selector {
	margin: auto;
	width: 20%;
	min-width: 150px;
	text-align: center;
}
.filters {
	width: 100%;
}
