.subContainer {
	padding: 8px;
	border-radius: 2px;
	border: 1px solid var(--color-light-grey-3);
	display: flex;
	height: 90px;
}
.title {
	padding: 8px;
	font-size: var(--font-size-sm);
	margin-bottom: 0px;
}
.roleTitleContainer {
	grid-row: 1;
	border: 1px solid var(--color-light-grey-3);
	font-size: var(--font-size-sm);
	height: 70px;
	text-align: center;
}
.infoDoc {
	width: 40%;
	text-transform: capitalize;
}
.wrapperRole {
	width: 100%;
	column-gap: 0px;
	overflow-y: auto;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
.roleContainer {
	grid-row: 2;
	height: 100%;
	border: 1px solid var(--color-light-grey-3);
	grid-auto-rows: 1fr;
}
.selectContainer {
	width: 60%;
	background-color: var(--color-light-grey-3);
	border: 1px solid var(--color-light-grey-3);
	border-radius: 8px;
	padding: 4px;
	text-align: left;
}
.canvasDocCard {
	height: 40px;
	line-height: 50px;
}
.card {
	width: 100%;
	text-align: center;
	border: 1px var(--color-light-grey-3) solid;
	height: 100px;
}
.cardContent {
	width: 100%;
	min-height: 120px;
	min-width: 200px;
	padding: 4px 4px 4px 4px;
}
.badge {
	padding: 0;
	margin: 0;
	font-size: var(--font-size-xs);
	margin-left: 7px;
	margin-right: 7px;
	margin-bottom: 10px;
}
.rootSelect {
	float: right;
	width: 200px;
}
.cardButton > * {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-word;
	overflow: hidden;
}
