.newVersion__emptyState {
	display: flex;
	flex-direction: column;
	margin: auto;
	align-items: center;
}
.newVersion__emptyState > * {
	margin: 8px 0;
}
.emptyState__icon {
	font-size: var(--font-size-5xl);
}
.emptyState__text {
	font-size: var(--font-size-sm);
	display: flex;
	flex-direction: column;
	align-items: center;
}
.normVersion__spacer {
	flex: 1 1;
}
.normVersion__table {
	padding: 16px 0;
}
