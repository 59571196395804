.header {
	padding: 10px 16px;
	background-color: var(--color-white);
	text-align: center;
}
.automaticDetections__selected {
	border: 1px solid var(--color-light-grey-2);
	border-radius: 16px;
	padding: 6px;
}
.automaticDetections__icon {
	margin: 0 16px;
}
.norms__name {
	display: block;
	max-width: 276px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
