.wrapper {
	margin: 4px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.wrapper [data-qaopen="true"] {
	width: calc(100% - 352px);
}
.innerWrapper {
	padding: 0;
	width: 100%;
	display: flex;
	align-items: center;
	white-space: nowrap;
	flex-shrink: 1;
}
.innerWrapper > * {
	margin: 4px;
	align-items: center;
	display: flex;
	flex-shrink: 0;
}
.innerWrapperLargeSearch {
	margin: 8px 4px;
}
.largeSearchWrapper {
	padding: 0;
	margin: 8px 4px;
	display: flex;
	align-items: stretch;
}
.largeSearchWrapper > * {
	margin: 4px 9px 4px 4px;
}
.largeSearchStartSearch {
	display: flex;
	align-items: center;
}
.largeSearchWrapper > *:first-child {
	flex: 1 1;
}
.carouselContainer {
	display: flex;
	gap: 8px;
	margin-right: 9px;
	width: auto;
	overflow: auto;
	white-space: nowrap;
	flex-shrink: 1;
}
.carouselContainer[data-multiline="true"] {
	flex-wrap: wrap;
}
.carouselContainer::-webkit-scrollbar {
	display: none;
}
.divider {
	height: 16px;
}
.separator__item {
	padding: 0px !important;
}
.separator__item--disabled {
	opacity: 0.38;
	pointer-events: none !important;
	cursor: not-allowed;
}
.separator__container {
	padding: 8px;
	width: 100%;
}
.separator__title {
	composes: font-md-regular from global;
}
.separator__helper {
	composes: font-xs-regular from global;
	color: var(--color-dark-grey-1);
}
.feedback__btn {
	composes: font-md-regular from global !important;
	padding-left: 4px !important;
}
.tooltip {
	background-color: white;
	color: black;
	border: 3px solid var(--color-light-grey-3);
	border-radius: 0;
	max-width: 560px;
}
.results--text {
	composes: font-xs-regular from global;
	text-transform: uppercase;
	color: var(--color-light-grey-2);
}
.horizontalDivider {
	margin-left: 8px;
	width: calc(100% - 16px);
}
