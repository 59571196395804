.move__header {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	gap: var(--spacing-s);
}
.moveDialog_breadcrumb {
	composes: font-sm-regular from global;
}
.moveDialog_breadcrumb > div {
	color: var(--color-dark-grey-1);
}
.moveDialog_breadcrumb > div:last-of-type {
	font-weight: bold;
	color: var(--color-black);
}
.move__content {
	height: 50vh;
}
