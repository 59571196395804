.menu__header {
	display: flex;
	justify-content: space-between;
	padding-bottom: 4px;
}
.menu__btn {
	text-transform: uppercase;
	text-align: left;
}
.menu__editBtn {
	composes: font-sm-regular from global;
}
.menu__items {
	display: flex;
	flex-direction: column;
	padding: 0 0 8px 8px;
	max-height: 350px;
}
.infiniteList__scrollContainer {
	height: 100% !important;
}
.infiniteListContainer {
	composes: font-sm-regular from global;
	height: auto;
	min-height: 64px;
}
.loaderContainer {
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
}
