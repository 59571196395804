.main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	margin-bottom: 50px;
}

.main-container > img {
	width: 25%;
	margin: 16px 0px;
}

.register-container {
	display: flex;
	justify-content: center;
	width: 100%;
}
.loader {
	margin: 50px;
}
