.folder__container {
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: var(--spacing-xs);
	justify-content: space-between;
}
.folder__information {
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: var(--spacing-xs);
	width: 50%;
}
.folder__row {
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	align-items: center;
	grid-gap: var(--spacing-s);
	gap: var(--spacing-s);
	justify-content: flex-start;
	align-content: center;
	flex-wrap: nowrap;
	flex-direction: row;
}
.row__label {
	composes: font-xs-bold from global;
	color: var(--color-light-grey-2);
	text-transform: uppercase;
	text-align: right;
	width: 140px;
}
.row__value {
	composes: font-sm-regular from global;
	color: var(--color-black);
	word-break: break-word;
	width: -webkit-fill-available;
}
.folder__iconContainer {
	align-self: center;
}
.folder__icon {
	width: 180px !important;
	height: 120px !important;
}
