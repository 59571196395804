.dropDownButton__icon {
	margin-left: 5px;
}
.dropDownButton__menuItem {
	font-size: var(--font-size-sm);
	max-width: 216px;
	white-space: pre-wrap;
	overflow-wrap: break-word;
}
.dropDownButton__menuItem--selected {
	background-color: var(--color-light-grey-3);
}
