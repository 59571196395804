.container {
	composes: font-sm-regular from global;
	width: 100%;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 4px;
	padding: 16px;
}
.header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--spacing-xs);
}
.header_leftSideInfo {
	color: var(--color-dark-grey-1);
	width: calc(100% - 86px);
	display: flex;
	align-items: center;
	gap: var(--spacing-xs);
}
.projectName {
	composes: font-sm-medium from global;
	max-width: 20%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.divider {
	height: 20px;
}
.heararchyContainer {
	display: flex;
	align-items: center;
	width: calc(100% - 20% - 200px);
}
.breadcrumbContainer {
	width: 100%;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
}
.breadcrumb {
	composes: font-sm-regular from global;
}
.header_rightSideInfo {
	display: flex;
	align-items: center;
	gap: var(--spacing-xs);
}
.divider--wide {
	width: 2px;
	height: 20px;
}
.button {
	color: var(--color-dark-grey-2) !important;
	width: 24px !important;
	height: 24px !important;
}
.iconClassName {
	width: 12px !important;
	height: 12px !important;
}
