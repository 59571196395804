.sidePanel {
	display: flex;
	flex-direction: column;
	padding: 50px;
	width: 100%;
}
.detail {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 8px 0;
}

.tileFooter {
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
}

.tileFooter > Button:first-child {
	margin-right: 10px;
}
.sidePannel__deleteButton {
	color: var(--color-red) !important;
}
