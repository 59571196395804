.panelHeader {
	display: flex;
	flex-direction: row;
	margin-top: 32px;
	margin-bottom: 16px;
}
.panelHeader > * {
	margin: 0 8px;
}
.panelHeader > *:first-child {
	margin: 0 8px 0 0;
}
.panelHeader > *:last-child {
	margin: 0 0 0 8px;
}
