.side-panel__content {
	display: flex;
	flex-direction: column;
	margin: 32px;
	padding: 32px;
	background-color: var(--color-white);
	height: calc(100% - 64px);
	justify-content: space-between;
}
.side-panel__header {
	margin-bottom: 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.search-input {
	width: 40%;
	margin: 16px 0 32px 0;
}
.title {
	font-weight: bold;
	font-size: var(--font-size-xl);
}
.header__buttons > * {
	margin: 0 8px;
}
.header__buttons :last-child {
	margin-right: 0;
}
.side-panel__pagination {
	min-height: 52px;
	padding-right: 36px !important;
}
.side-panel__users-list {
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}
