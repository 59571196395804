.tooltip {
	box-shadow: 0px 5px 20px 0.3px var(--shadow-5);
	font-size: var(--font-size-sm);
}
.noMilestone {
	font-size: var(--font-size-2xl);
	font-style: italic;
}
.tileHeader__buttons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
