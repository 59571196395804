.projectTitle {
	display: flex;
	width: 100%;
}
.projectTitle > button {
	margin-left: auto;
}
.projectTitle__text {
	composes: font-md-bold from global;
	display: inline-block;
	height: 36px;
	line-height: 36px;
	margin-right: 36px;
	margin-bottom: 16px;
	max-width: calc(100% - 154px);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-transform: uppercase;
}
