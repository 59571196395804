.column__header {
	display: flex;
	align-items: center;
	composes: font-lg-regular from global;
	background-color: var(--color-dark-grey-2);
	color: var(--color-white);
	padding: 8px;
	margin-top: 4px;
	border-radius: 4px 4px 0 0;
}
.precedenceInfo__container {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 24px);
}
.precedenceName {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.precedenceModify__btnContainer {
	display: flex;
	gap: 4px;
}
.precedenceModify__btn {
	color: var(--color-grey-100) !important;
	padding: 0px !important;
	width: 24px !important;
	min-width: 24px !important;
	height: 24px !important;
}
.precedenceModify__btn:hover {
	color: var(--color-light-grey-1) !important;
}
.documentsContainer {
	padding: 4px;
	border-radius: 0 0 4px 4px;
	height: fit-content;
	overflow: hidden;
}
.precedence__handle {
	display: inline-flex;
	align-items: center;
	margin-right: 8px;
}
