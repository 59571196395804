.textField {
	margin: 8px;
	margin-bottom: 8px;
	line-height: 32px;
	height: 32px;
	font-size: 14px;
}
.textField > * > *:nth-child(3) {
	border: 1px solid var(--shades-grey-300);
}
.resetButton > * {
	padding-right: 0;
	width: 24px !important;
}
.mentions--multiLine {
	min-width: 240px;
}
.mentions--multiLine > *:nth-child(2) {
	top: 1em !important;
	margin-left: 8px;
}
.helperText {
	composes: font-xs-regular from global;
	color: var(--shadow-5);
	margin-left: var(--spacing-xs);
	margin-top: var(--spacing-2xs);
}
