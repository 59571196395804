.header__container {
	display: flex;
	justify-content: space-between;
}
.closeButton {
	margin-right: 16px;
}
.container {
	width: 50vw;
}
