.listItem__companyName {
	display: inline-block;
	max-width: calc(100% - 142px);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.companyName__leaderIcon {
	margin-right: 6px;
}
.listItem__icons {
	display: inline-block;
	width: 142px;
}
