.main-container {
	margin: auto;
	text-align: center;
	width: 40%;
	padding-bottom: 50px;
	max-width: 500px;
}

.main-container > img {
	width: 100%;
	margin: 18px 0px;
}

.bottom-container {
	display: flex;
	flex-direction: row;
	padding-top: 4px;
}

.versioning-btn {
	margin-left: auto;
	cursor: pointer;
	color: var(--color-blue);
	text-decoration: none;
}

.versioning-btn:hover {
	color: var(--color-blue);
	text-decoration: underline;
}

.multi-companies__btns {
	display: flex;
}
.visibility-button__icon {
	font-size: var(--font-size-lg);
}
.forgotten-password:hover {
	text-decoration: underline !important;
	color: var(--color-blue);
}
