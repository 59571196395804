.parameter {
	border: 1px solid var(--color-light-grey-3);
	border-radius: 8px;
	margin: 0px 2px;
	padding: 0px 6px;
	color: var(--color-white);
}

.parameter__default {
	background-color: var(--color-light-grey-3);
	color: var(--color-black);
}

.parameter__crititicalty--critical {
	background-color: var(--color-red);
	color: var(--color-white);
}

.parameter__crititicalty--major {
	background-color: var(--color-orange);
	color: var(--color-black);
}

.parameter__crititicalty--minor {
	background-color: var(--color-yellow);
	color: var(--color-black);
}

.parameter__negotiability--must {
	background-color: var(--color-red);
	color: var(--color-white);
}

.parameter__negotiability--should {
	background-color: var(--color-orange);
	color: var(--color-black);
}

.parameter__negotiability--could {
	background-color: var(--color-yellow);
	color: var(--color-black);
}

.parameter__negotiability--would {
	background-color: var(--color-green);
	color: var(--color-white);
}
