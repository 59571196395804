.infiniteList {
	width: 100% !important;
	overflow-y: auto;
}
.listContainer {
	width: 100%;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;
}
.listContainer > * {
	overflow: auto !important;
}
.header-cell {
	padding: 4px 16px;
}
