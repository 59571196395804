.redressInfo__overlay {
	position: absolute;
	background-color: var(--color-white);
	padding-left: 5px;
	padding-right: 5px;
	z-index: 10;
	text-align: center;
	border: 1px solid var(--color-light-grey-3);
	border-radius: 6px;
	height: auto;
	font-size: var(--font-size-xs);
}
.iconButton {
	margin: 0;
	padding: 0;
}
.redressId {
	margin-right: 8px;
	margin-top: 8px;
}
