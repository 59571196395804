.members-list__empty-state {
	position: absolute;
	top: 30%;
	text-align: center;
	width: 100%;
}
.members-list__empty-state .members-list__empty-state-title {
	font-weight: bold;
}
.members-list__empty-state .members-list__empty-state-sub-title {
	font-style: italic;
	font-size: var(--font-size-xs);
}
