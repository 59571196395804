.norms__selector {
	width: 200px;
}
.norms__spacer {
	flex: 1 1;
}
.norms__searchResult {
	place-self: center;
	font-weight: lighter;
}
