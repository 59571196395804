.normList__headerCellBtn {
	width: 50%;
}
.normList__header {
	padding: 10px 16px;
	text-align: center;
	background-color: var(--color-white);
}
.normList__emptyState {
	display: flex;
	flex-direction: column;
	width: fit-content;
	margin: auto;
}
.norms__name {
	display: block;
	max-width: 276px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
