.container {
	height: 100%;
	flex-direction: column;
	overflow: hidden;
}
.btn__container {
	display: flex;
	justify-content: flex-end;
	padding: var(--spacing-xs) 0;
}
.conversationList {
	height: calc(100% - 34px);
	overflow: auto;
}
.conversationList[data-caution-banner-disabled="false"] {
	height: 100%;
}
.infiniteList {
	height: 100%;
	padding-top: 8px;
}
.infiniteList__scrollContainer {
	height: calc(100% - 48px) !important;
}
