.searchContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: var(--color-white);
	padding: var(--spacing-s) 0;
	margin: 0 var(--view-padding-lateral);
}
.mainContainer {
	height: calc(100% - 135px);
	overflow: auto;
}
.searchContainer__textfield {
	width: 300px;
	margin-right: var(--spacing-s);
}
.mainContainer__list {
	padding: 0 var(--view-padding-lateral);
	height: calc(100% - 90px);
}
.mainContainer__loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50%;
}
.mainContainer__list > *:first-child {
	border: none !important;
}
