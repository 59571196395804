.input__title {
	composes: font-sm-medium from global;
	display: flex;
	align-items: flex-start;
	gap: 4px;
	margin-bottom: 8px;
}
.input__title:not(:first-of-type) {
	margin-top: 16px;
}
.input__icon {
	height: 7px;
}
.questionIcon__tooltipLink {
	text-decoration: underline !important;
	color: var(--color-white) !important;
}
