.title {
	composes: font-sm-medium from global !important;
}
.block,
.block > div:first-of-type {
	margin-bottom: var(--spacing-s);
}
.infoIcon {
	margin: 0 0 var(--spacing-2xs) var(--spacing-2xs);
}
.divider {
	margin: var(--spacing-s) 0;
}
.linkIcon {
	margin-right: var(--spacing-xs);
}
.linkSelector_label {
	composes: font-sm-regular from global;
	line-height: 1.5rem;
}
