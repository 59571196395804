.main {
	width: 100%;
	overflow-y: auto;
}
.content {
	padding: 32px;
	margin: 0.5rem;
}
.button {
	margin-top: 8px;
	padding: 4px;
	border-radius: 16px;
	cursor: pointer;
	border: 2px solid var(--color-light-grey-3);
}
.button:hover {
	border: 4px solid var(--color-light-grey-3);
}
.card {
	width: 300px;
	display: inline-block;
	text-align: center;
	border: 1px solid var(--color-light-grey-3);
	margin-right: 10px;
	margin-bottom: 10px;
	vertical-align: middle;
}
.cardContent {
	width: 100%;
	height: 100%;
	padding: 4px 4px 4px 4px;
}
.cardContent__title {
	font-weight: 600;
}
