.dragover[data-dragover="true"] {
	background-color: var(--tints-blue-10);
	border: 2px solid var(--tints-blue-50);
	border-radius: 4px;
}
.dragover {
	display: inline-block;
	height: calc(100% - 7px);
	width: 100%;
}
.tableContainer[data-fullheight="true"][data-docStatusInfoBannerDisplayed="true"] {
	height: calc(100vh - 392px);
}
.tableContainer[data-docStatusInfoBannerDisplayed="true"] {
	height: calc(100vh - 432px);
}
.tableContainer[data-fullheight="true"] {
	height: calc(100vh - 302px);
}
.tableContainer {
	height: calc(100vh - 342px);
}
.table__cell--10 {
	width: 10%;
}
.table__cell--50 {
	width: calc(50% - 8px);
}
.table__cell--64px[data-dragover="true"] {
	background-color: var(--tints-blue-10);
	border-radius: 4px;
}
.table__cell--64px {
	min-width: 64px;
	width: 64px;
	text-align: right;
}
.cellActions {
	display: flex;
}
.table__headerCell[data-dragover="true"] {
	background-color: var(--tints-blue-10);
	border-radius: 4px;
}
.table__cell--center {
	padding-left: var(--spacing-s);
}
.table__headerCell {
	composes: font-sm-bold from global;
	color: var(--color-dark-grey-3);
	text-transform: none;
}
.table__cell {
	composes: font-sm-regular from global;
	line-height: 1.43;
	letter-spacing: 0.15px;
	color: var(--color-dark-grey-3);
}
.table__pagination {
	display: flex;
}
.table__pagination > div {
	padding-left: 0px;
}
.avatarGroup {
	flex-direction: row;
}
.avatar {
	composes: font-sm-medium from global;
	text-transform: uppercase;
	color: var(--color-white);
	border-radius: 64px;
	border: solid 2px var(--color-white);
	background-color: var(--color-light-grey-2);
	width: 32px;
	height: 32px;
}
.avatar__tooltip {
	margin: var(--spacing-2xs);
}
.table_empty {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: var(--spacing-m);
}
.tableCell_label {
	white-space: nowrap;
	overflow: hidden;
}
.label__icon {
	display: inline-block;
	margin-right: 4px;
	color: var(--color-light-grey-1);
}
.label__icon[data-type="folder"] {
	color: var(--color-dark-grey-2);
}
.label__textContainer {
	display: inline-block;
	overflow: hidden;
}
.label__chip {
	background-color: var(--color-blue);
	color: var(--color-white);
	margin-left: 8px;
}
.table__label {
	max-width: calc(80vw * 0.5 - 52px);
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	width: 100%;
	padding-right: var(--spacing-xs);
}
.table__label--fullSize {
	width: 100% !important;
}
.table__label--leftSide {
	display: flex;
	width: calc(100% - 87px);
}
.table__label--leftSide > div:first-of-type {
	width: 20px;
	margin-right: 2px;
}
.label__text {
	composes: font-sm-regular from global;
}
.label__subText {
	composes: font-xs-regular from global;
	color: var(--color-light-grey-2);
}
.table__loader {
	text-align: center;
	padding: var(--spacing-l);
}
.actions__selection {
	composes: font-sm-medium from global;
	color: var(--color-dark-grey-3);
}
.actions__divider {
	display: inline;
	margin: 0 8px;
}
.actions__label {
	composes: font-xs-bold from global;
	color: var(--color-light-grey-1);
	text-transform: uppercase;
}
.actions__icons {
	display: inline-flex;
	margin-left: 16px;
	gap: var(--spacing-xs);
}
.innerCell {
	display: flex;
	align-items: center;
}
.innerCell > div {
	padding-left: var(--spacing-2xs);
}
.information__content {
	composes: font-sm-regular from global;
	padding: 8px;
}
.information__content--footer {
	padding-top: 8px;
	text-align: right;
}
.qualityColumn > div {
	display: flex;
	justify-content: left;
}
.status {
	color: var(--color-light-grey-2);
}
.roleButton {
	border: 1px solid var(--color-light-grey-3) !important;
	border-radius: 4px;
}
.dragDropInfo {
	bottom: 0;
}
