.container {
	display: flex;
	position: relative;
	height: 100%;
	overflow: hidden;
}

.left-panel {
	width: 40%;
	border-right: 1px solid var(--color-light-grey-3);
	overflow-y: auto;
	overflow-x: hidden;
}

.right-panel {
	width: -webkit-fill-available;
	overflow-y: auto;
	overflow-x: hidden;
}

.fab-add {
	position: fixed !important;
	bottom: 16px;
	right: 16px;
}
