.firstColumnCell {
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: var(--view-padding-lateral) !important;
}
.header {
	position: sticky !important;
	top: 67px !important;
	padding: 10px 16px;
}
.cell__action {
	padding: 0 16px 0 0 !important;
	width: 15%;
}
.cell__action > * {
	margin: 0 4px;
}
