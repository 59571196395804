.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.listContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}
.listContainer[data-translucent="true"] {
	opacity: 0.4;
}
.textField {
	margin: 8px;
	margin-bottom: 8px;
	background-color: var(--color-white);
}
.textField > * > *:nth-child(3) {
	border: 1px solid var(--shades-grey-300);
}
.noRowsContainer {
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.noRowsContainer > *:first-child {
	margin-right: 8px;
}
.resetButton > * {
	padding-right: 0;
	width: 24px !important;
}
.rowContainer {
	composes: font-sm-regular from global;
	width: 100%;
}
.container__filters {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.container__filters > * {
	margin: 4px;
	min-width: 150px;
	flex-grow: 1;
	background-color: white;
}
.reverseScollList {
	display: flex;
	flex-direction: column-reverse;
}
