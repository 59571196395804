.header {
	padding: 10px 4px !important;
	text-align: start;
	min-width: 130px;
	max-width: 130px;
}
.header:nth-of-type(3) {
	width: 100px;
	min-width: 100px;
	max-width: 100px;
}
.header:nth-of-type(6) {
	min-width: 30px;
	max-width: 30px;
}
.header > div {
	display: flex;
}
.row {
	max-width: 100%;
}
.row--selected {
	background-color: var(--tints-blue-5);
}
.row--selected > .cell__action > div {
	display: default;
}
.row--hover:hover {
	background-color: var(--tints-blue-10);
}
.row--locked {
	background-color: var(--shades-grey-200);
	cursor: default;
}
.row__cell {
	composes: font-sm-regular from global;
	min-width: 130px;
	max-width: 130px;
	height: 74px !important;
	padding: 2px;
	white-space: wrap;
}
.row__cell:nth-of-type(1),
.row__cell:nth-of-type(3) {
	padding: 16px;
}
.row__cell:nth-of-type(3) {
	min-width: 100px;
	max-width: 100px;
}
.row__cell:nth-of-type(6) {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 30px;
	padding-right: 48px;
}
.cell__action {
	text-align: center;
	padding: 0;
}
.cell__action > div > button {
	margin: 2px;
}
.row:not(:hover) > .cell__action > div {
	display: none;
}
.headerCell {
	composes: font-sm-medium from global;
	flex: 1;
	max-width: 50%;
}
.headerCell_action {
	width: 100px;
}
.headerCell:nth-of-type(1),
.headerCell:nth-of-type(3) {
	padding-left: 16px !important;
}
.cell__selector {
	height: 32px;
	width: 90%;
	background-color: var(--color-white) !important;
}
.cell__selector * {
	line-height: 1rem;
	font-size: var(--font-size-sm);
	font-weight: 400;
	border: none !important;
}
.tableBody {
	overflow-y: auto;
}
.badgeStyle {
	background: transparent;
	color: var(--color-blue);
	font-weight: bold;
	border: 1px solid var(--color-blue);
	padding: 0 1em;
	transform: scale(1) translate(100%, -50%);
	transform-origin: 100% 0%;
}
@media screen and (min-width: 1100px) {
	.cell__action > div > button:nth-of-type(2) {
		display: none;
	}
}
@media screen and (max-width: 700px) {
	tr > th:nth-of-type(4),
	tr > td:nth-of-type(4),
	tr > th:first-of-type,
	tr > td:first-of-type {
		display: none;
	}
}
