.margin-bottom {
	display: flex;
	margin-bottom: 16px !important;
	padding-top: 8px;
}

.dialog__title {
	display: flex;
}

.dialog__title .dialog__title-text {
	width: 100%;
	align-self: center;
}

.dialog__content {
	-ms-overflow-style: none;
}

.dialog__content::-webkit-scrollbar {
	display: none;
}

.dialog__select-field {
	margin-left: 15px !important;
	width: 35%;
}

.dialog__content .dialog__content-title {
	margin: 16px;
	font-weight: bold;
}
