.container {
	display: flex;
	flex-direction: column;
	height: inherit;
	flex-grow: 1;
	width: 100%;
}
.loader {
	display: flex;
	height: 50%;
	padding: 16px;
	align-items: center;
	justify-content: center;
}
.empty-state {
	display: flex;
	height: 50%;
}
.empty-state__container {
	margin: auto;
	display: inherit;
	text-transform: uppercase;
	flex-direction: column;
}
.empty-state__container--text {
	padding: 16px;
}
.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 16px;
}
.header__buttons > * {
	margin-left: 8px;
}
