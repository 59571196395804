.emptyComponent {
	font-size: var(--font-size-xl);
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	transform: translate(-50%, -50%);
	background-color: var(--color-white);
	width: 100%;
	height: 100%;
}
